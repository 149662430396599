import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import { method } from "lodash";

export default function Footer() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };
    return (
        <>
            <div className="container-fluid p-0 mx-0 bg-color-6 color-wht">
                <div className="container p-5">
                    <div className="row pb-2">
                        <div className="col-12 col-lg-6">
                            <div className="row">
                                <div className="col-12 fs-5 fw-os-sb">International Moving Inquires</div>
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <div className="col-12 fs-2 mt-3"><div className="text-decoration color-wht">+65 6631 8537</div></div>
                                        <p className="col-12 fs-st">Toll-free from the United States and Canada</p>
                                    </div>
                                    <div className="col-12 mx-auto">
                                        <div className="col-12 fs-3 mt-3"><div className="text-decoration color-wht">info@moversly.com</div></div>
                                        <p className="fs-st"></p>
                                    </div>
                                </div>
                                <hr className="d-none d-lg-block" style={{ width: "100%", height: "2px" }} />
                                <div className="d-none d-lg-flex row col-12 fs-st text-end ">
                                    <img className="col-4 img-fluid" src="./assets/logo/moversly-logo-w.svg" alt="" />
                                    <p className="col-8 my-auto">© Copyright 2023 Moversly. All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-flex col-1 vl" />
                        <hr className="d-flex d-lg-none" />
                        <div className="col-lg-2">
                            <div className="row my-auto text-start pb-4">
                                <Link className="col-12 my-2 p-0 m-0 fs-5 letter-spacing text-decoration color-wht" to="/about" onClick={scrollToTop}>About Us</Link>
                                <Link className="col-12 my-2 p-0 m-0 fs-5 letter-spacing text-decoration color-wht" to="/contact_us" onClick={scrollToTop}>Contact Us</Link>
                                <Link className="col-12 my-2 p-0 m-0 fs-5 letter-spacing text-decoration color-wht" to="#">FAQs</Link>
                                <Link className="col-12 my-2 p-0 m-0 fs-5 letter-spacing text-decoration color-wht" to="/privacy" onClick={scrollToTop}>Privacy Policy</Link>
                                <Link className="col-12 my-2 p-0 m-0 fs-5 letter-spacing text-decoration color-wht" to="#" onClick={scrollToTop}>Sitemap</Link>
                            </div>
                        </div>
                        <div className="d-none d-lg-flex col-1 vl" />
                        <hr  className="d-flex d-lg-none" />
                        <div className="col-lg-2 mb-5">
                            <div className="row">
                                <div className="col-12 col-md-12 fw-os-sb fs-5 pb-2">Download App</div>
                              <a href="https://apps.apple.com/in/app/moversly/id1575502038" ><img onClick={appDownloads} className="col-12 col-md-4 col-lg-12 mt-3" src="./assets/icons/apple-store.svg" alt="" /></a>  
                              <a href="https://play.google.com/store/apps/details?id=com.moversly.app" ><img  onClick={appDownloads} className="col-12 col-md-4 col-lg-12 mt-3" src="./assets/icons/play-store.svg" alt="" /></a>
{/*                              
                                <img className="col-12 col-md-4 col-lg-12 mt-3" onClick={logevent} src="./assets/icons/whatsapp.png" alt="" /> */}
                         {/* <div className="mt-3" onClick={Like}>
                             <AiIcons.AiFillLike style={{fontSize:30,}}/>
                              <span style={{fontSize:12,}}>Like Us on Facebook</span>
                              </div>
                              <div onClick={Share} className="mt-3">
                                  <BiIcons.BiShareAlt style={{fontSize:30,}}/>
                                  <span style={{fontSize:12,}}>Share on Facebook</span>
                              </div> */}
                            </div>
                        </div>
                        <hr className="d-flex d-lg-none" style={{ width: "100%", height: "2px" }} />
                        <div className="d- d-lg-none row col-12 fs-st text-end ">
                            <img className="col-5 img-fluid" src="./assets/logo/moversly-logo-w.svg" alt="" />
                            <p className="col-7 my-auto">© Copyright 2023 Moversly. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

     function Share (){
        window.FB.ui(
            {
            display: "popup",
          method:"share",
          href:window.location.href //TO BE CHANGE TO window.location.href
        },
        function (response) {
          console.log(response);
        }
        )
    }

    function Like(){
        window.FB.ui(
            {
                method:"share_open_graph",
                action_type:"og.likes",
                action_properties:JSON.stringify({
                    object:"https://www.moversly.com" //TO BE CHANGE TO window.location.href
                })
            },
            function (response) {
                console.log(response);
              }
        )
    }

   function logevent(){
       Share()
  window.FB.AppEvents.logEvent("{my_app_event}");
   } 
   function appDownloads(){
       window.FB.AppEvents.logEvent("downloads");
   } 
   
 
    
}