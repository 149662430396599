import { Fragment, useEffect } from "react";
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from "react-redux";

import { handleAuthState } from "../../redux/actions/authActions";
import { handleUserMultiState } from "../../redux/actions/userActions";
import { storageShipment, storageToken } from "../consts/browserVariables";
import { API_ROUTES } from "../consts/config";
import { Detector } from "react-detect-offline";

import Helper, { HelperWithUrl } from "../consts/helper";
import messages from "../consts/messages";
// import { updateShipmentState } from "../../redux/actions/shipmentActions";
import { setConfig } from '../../redux/actions/configActions';

export default function ApiProvider(props) {

    const dispatch = useDispatch();
    const { addToast, removeToast } = useToasts();

    const isLoggedIn = useSelector(state => state?.auth?.isLoggedIn || false);
    const shipment = useSelector(state => state?.shipment);

    // already logged in check
    // if logged in update redux state
    useEffect(() => {
        if (localStorage.getItem(storageToken)) {
            dispatch(handleAuthState('isLoggedIn', true));
        }
    }, []);


    // fetching profile whenever user logs in
    useEffect(() => {
        if (isLoggedIn) {
            fetchProfile();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        fetchConfig();
    }, []);


    // before user unload the window saving
    // shipment reducer to his local storage
    // useEffect(() => {
    //     window.addEventListener("beforeunload", handleUnload);
    //     return () => window.removeEventListener("beforeunload", handleUnload)
    // }, [shipment]);


    // whenever user opens window
    // checking if previous shipment reducer exists or not
    // useEffect(() => {
    //     let localShipment = localStorage.getItem(storageShipment);
    //     localShipment = JSON.parse(localShipment);
    //     if (localShipment) {
    //         dispatch(updateShipmentState(localShipment));
    //     }
    // }, []);

    return (
        <Fragment>
            {props.children}
            <Detector
                onChange={(online) => {
                    if (online) {
                        removeToast('networkStop', () => {
                            addToast(messages.networkWorking, {
                                autoDismiss: true,
                                appearance: 'info',
                            });
                        });
                    }
                    else {
                        addToast(messages.networkStopped, {
                            autoDismiss: false,
                            appearance: 'error',
                            id: 'networkStop'
                        });
                    }
                }}
                render={({ online }) => {
                    return null
                }}
            >13</Detector>
        </Fragment>
    )

    async function fetchConfig() {
        try {
            let response = await HelperWithUrl('https://s3.ap-southeast-1.amazonaws.com/config.mobile.moversly.com/public/config.json');
            if (response) {
                dispatch(setConfig(response));
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    function handleUnload(ev) {
        localStorage.setItem(storageShipment, JSON.stringify(shipment));
        ev.preventDefault();
        return ev.returnValue = 'Are you sure you want to close?';
    }

    async function fetchProfile() {

        const route = API_ROUTES?.bookerInfo?.route || '';
        const method = API_ROUTES?.bookerInfo?.method || '';

        try {
            const response = await Helper(route, method);
            if (response) {
                dispatch(handleUserMultiState({ ...response }));
            }
        }
        catch (error) {
            // unable to fetch user profile
        }
    }
}