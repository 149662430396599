import {
    SHIPMENT_REDUCER
} from '../../common/consts/emptyStates';
import ActionTypes from './types';

export const handleShipmentState = (name, value) => ({
    type: ActionTypes.HANDLE_SHIPMENT,
    payload: {
        name,
        value
    }
});

export const setClientSecret = (value) => ({
    type: ActionTypes.SET_CLIENT_SECRET,
    payload: {
        name: 'clientId',
        value
    }
})

export const resetShipmentState = () => ({
    type: ActionTypes.RESET_SHIPMENT_REDUCER,
    payload: {
        ...SHIPMENT_REDUCER
    }
});

export const updateShipmentState = (payload) => ({
    type: ActionTypes.LOAD_PREVIOUS_SHIPMENT,
    payload
})