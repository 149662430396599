import { Link } from 'react-router-dom';

export default function BreadCrumb(props) {

    const {
        list,
        fullWidth
    } = props;

    return (
        <div className={`row  ${fullWidth ? '' : 'px-5'}`}>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-auto">
                    {
                        list?.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={`breadcrumb-item ${list?.length === (index + 1) ? 'active' : ''}`}
                                >
                                    {
                                        list?.length === (index + 1) ? (
                                            <Link to="#" className="text-center p-0 m-0 my-auto letter-spacing text-decoration breadcrumb-item active" aria-current="page">{item?.title}</Link>
                                        ) : (
                                            <Link to={item?.link || '/'} className="text-center p-0 m-0 my-auto letter-spacing text-decoration font-color">
                                                {item?.title || ''}
                                            </Link>
                                        )
                                    }
                                </li>
                            )
                        })
                    }
                </ol>
            </nav>
        </div>
    )
}