import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import apac from "../../assets/images/Logo_Big.jpg";
import Helper, { HelperWithUrl } from "../../common/consts/helper";
import { API_ROUTES, BASE_URL } from "../../common/consts/config";

const QuoteAccepted = (props) => {
  const { setShowHeaderFooter } = props;
  const [pdfUrl, setPdfUrl] = useState();
  const [acceptancePdfId, setAcceptancePdfId] = useState();

  const history = useHistory();
  const url = history.location.state;
  const orderId = url?.orderId;
  const quoteId = url?.quoteId;
  const home = url?.homepage;
  console.log("Homepage :", home);
  console.log(orderId, url);

  useEffect(() => {
    setShowHeaderFooter(false);
  }, []);

  const onDownload = async () => {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.download = `${pdfUrl}`;
    link.href = `${pdfUrl}`;
    link.click();
  };

  const getQuote = async () => {
    const response = await HelperWithUrl(
      `${BASE_URL}quote/order/${orderId}/quote/${quoteId}`
    );
    if (!response?.error_code) {
      getAcceptancePdfUrl(response?.acceptance_pdf_id);
    }
  };

  const getAcceptancePdfUrl = async (id) => {
    try {
      const response = await HelperWithUrl(
        `${BASE_URL}quote/get_quote_acceptance_url/${orderId}/${quoteId}/${id}`
      );
      if (response) {
        setPdfUrl(response?.url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuote();
  }, []);

  return (
    <Box
      sx={{
        textAlign: "center",
        marginTop: 15,
        marginX: "auto",
      }}
    >
      <img
        // src=""
        src={url?.logo}
        alt="LOGO"
        style={{
          width: 400,
          height: 200,
        }}
      />
      <Typography variant="h5">Thank You!!</Typography>
      <Typography variant="h6">
        Thank you for your acceptance. Our move coordination team will reach out
        to you.
      </Typography>

      <button
        style={{
          border: "none",
          background: "black",
          borderRadius: 20,
          padding: "10px 30px",
          color: "white",
          marginTop: 20,
        }}
      >
        <a
          // href="https://apacrelocation.com/"
          href={`http://${home}`}
          target="_blank"
          style={{
            textDecoration: "none",
            color: "white",
          }}
        >
          {" "}
          Back to home page
        </a>
      </button>
      <button
        style={{
          border: "none",
          background: "black",
          borderRadius: 20,
          padding: "10px 35px",
          marginLeft: 20,
          color: "white",
          marginTop: 20,
        }}
        onClick={onDownload}
      >
        Download Acceptance Form
      </button>
    </Box>
  );
};

export default QuoteAccepted;
