import { USER_REDUCER } from '../../common/consts/emptyStates';
import ActionTypes from '../actions/types';

const initialState = {
  ...USER_REDUCER
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.HANDLE_USER:
            return {
                ...state,
                [payload.name]: payload.value
            }
        case ActionTypes.HANDLE_USER_MULTI_STATE:
            return{
                ...state,
                ...payload,
            }
        default:
            return state;
    }
}