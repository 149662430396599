import { Link, NavLink, useHistory } from "react-router-dom";
import ProfilePic from "../../common/components/ProfilePic";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faSignOutAlt,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import { storageToken } from "../consts/browserVariables";
import { handleAuthState } from "../../redux/actions/authActions";
import { resetShipmentState } from "../../redux/actions/shipmentActions";
import { useRef } from "react";
import { fontSize } from "@mui/system";


export default function Header() {
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn || false);
  const given_name = useSelector((state) => state?.user?.given_name || "");
  const family_name = useSelector((state) => state?.user?.family_name || "");

  const mobileMenuRef = useRef();

  const history = useHistory();
  const dispatch = useDispatch();
  function fbtest(){
    window.FB.AppEvents.logPageView("signup button clicked");
  }

  function isActiveLink(route){
console.log(history?.location?.pathname)
if (route === history?.location?.pathname.toString()) {
  console.log("true")
  return {
  borderBottom:"3px solid blue",
  color:"blue"
}
}

  }

  return (
    <>
      {/* first-bar */}
      <div className="container-fluid bg-light d-none d-lg-block">
        <div className="container">
          <div className="row d-flex justify-content-between py-3">
            <div className="col-lg-7 fs-09 fw-os-sb p-0 my-auto Prussian-Blue-color">
              {/* <img className="pe-3" src="./Assets/Icons/Shape.png" />Give Missed Call For New International Movement: <Link
                            className="text-decoration font-color" to="tel:+65 7969 200 017">+65 7969 200 017</Link> */}
              {/*
                            Put signup/login here and redirect to login/signup page
                             */}
            </div>
         {!isLoggedIn &&   <div className="row mt-3 mt-md-0 d-flex justify-content-between my-auto">
              <div className="col-5 my-auto">
                <div className="row">
                  {isLoggedIn ? null : (
                    <>
                      <div className="col-3 my-auto hover-on-1">
                        <Link to="/signup" onClick={fbtest}>
                          <div className="col-12 mx-0 my-auto py-1 px-4 fs-09 fw-os-sb text-center btn OceanBlue-bg color-wht button-border">
                            Signup
                          </div>
                        </Link>
                      </div>
                      <div className="col-3 my-auto hover-on-1">
                        <Link to="/login">
                          <div className="col-12 mx-0 my-auto py-1 px-4 fs-09 fw-os-sb text-center btn OceanBlue-bg color-wht button-border">
                            Login
                          </div>
                        </Link>
                      </div>
                    </>
                  )}
                  <div
                    className="col-2 m-0 py-2 px-1 fw-os-rg fs-09 text-center subtext-color"
                    style={{ fontSize: 12, fontWeight: 500, width: 70 }}
                  >
                    <Link to="/about" className="text-decoration color-dark">
                      About Us
                    </Link>
                  </div>
                  <div
                    className="col-2 m-0 py-2 px-1 fw-os-rg fs-09 text-center subtext-color"
                    style={{ fontSize: 12, fontWeight: 500, width: 70 }}
                  >
                    <Link
                      to="/contact_us"
                      className="text-decoration color-dark"
                    >
                      Contact Us
                    </Link>
                  </div>
                  <div
                    className="col-2 m-0 py-2 px-1 fw-os-rg fs-09 text-center subtext-color"
                    style={{ fontSize: 12, fontWeight: 500, width: 90 }}
                  >
                    <Link to="/privacy" className="text-decoration color-dark">
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6 my-auto d-flex justify-content-end">
                <a
                  className="col-3 mx-2"
                  href="https://apps.apple.com/in/app/moversly/id1575502038"
                >
                  <img src="./assets/icons/apple-store.svg" alt="" />
                </a>
                <a
                  className="col-3 mx-2"
                  href="https://play.google.com/store/apps/details?id=com.moversly.app"
                >
                  <img src="./assets/icons/play-store.svg" alt="" />
                </a>
              </div>
            </div>}
          </div>
        </div>
      </div>
      {/* second-bar */}
      {/* <div className="container mt-4 mb-2 d-none d-lg-block"></div> */}
      {/* navbar */}
      <div
        className="navbar sticky-top navbar-light bg-white d-none d-lg-block py-4 top-navbar-moversly"
        // style={{ zIndex: 0 }}
      >
        <div className="container">
       {isLoggedIn ?   <div className="row fw-os-sb w-100">
            <div className="col-4">
              <Link to="/">
                <img src="./assets/logo/moversly-logo.svg" alt="" />
              </Link>
            </div>
            <div className="col-8 d-flex justify-content-end">
              <NavLink
                to="/orders"
                className="text-decoration color-dark col-2 p-0 center"
                activeStyle={{
                  fontWeight: "bold",
                  // fontSize:16,
                  color:"red",
                  borderBottom:"2px solid blue"
                }}
              >
                <div className="col-12 fs-09 text-center p-0 my-auto" >
                  My Orders
                </div>
              </NavLink>
              <NavLink
                to="/orders/track"
                className="text-decoration color-dark col-3 p-0 center"
                activeStyle={{
                  fontWeight: "bold",
                  // fontSize:16,
                  borderBottom:"2px solid blue"
                }}
              >
                <div className="col-12 fs-09 text-center p-0 my-auto"  >
                  Track Orders
                </div>
              </NavLink>
              <NavLink
                to="/ocean_freight"
                activeStyle={{
                  fontWeight: "bold",
                  // fontSize:16,
                  borderBottom:"2px solid blue"
                }}
                className="text-decoration color-dark col-3 p-0 center"
              >
                <div className="col-12 fs-09 text-center p-0 my-auto" >
                  Ocean Freight Agents
                </div>
              </NavLink>
              <NavLink
                to="/packer"
                className="text-decoration color-dark col-2 p-0 center"
                activeStyle={{
                  fontWeight: "bold",
                  // fontSize:16,
                  borderBottom:"2px solid blue"
                }}
              >
                <div className="col-12 fs-09 text-center p-0 my-auto" >
                  Packers
                </div>
              </NavLink>
              <NavLink
                to="/trucker"
               
                activeStyle={{
                  fontWeight: "bold",
                  // fontSize:16,
                  borderBottom:"2px solid blue"
                }}
                className="text-decoration color-dark col-2 p-0 center"
                
              >
                <div className="col-12 fs-09 text-center p-0 my-auto"

                // style={isActiveLink("/trucker")}
                >
                  Truckers
                </div>
              </NavLink>

              {isLoggedIn ? (
                <div className="d-block text-decoration mt-1 color-dark col-2 p-0 center">
                  {renderProfile()}
                </div>
              ) : null}
            </div>
          </div>
          :
          <div className="row fw-os-sb w-100">
            <div className="col-4">
              <Link to="/">
                <img src="./assets/logo/moversly-logo.svg" alt="" />
              </Link>
            </div>
            <div className="col-8 d-flex justify-content-end">
              <Link
                to="/customers"
                className="text-decoration color-dark col-2 p-0 center"
              >
                <div className="col-12 fs-09 text-center p-0 my-auto">
                  Customers
                </div>
              </Link>
              <Link
                to="/international_mover"
                className="text-decoration color-dark col-3 p-0 center"
              >
                <div className="col-12 fs-09 text-center p-0 my-auto">
                  International Movers
                </div>
              </Link>
              <Link
                to="/ocean_freight"
                className="text-decoration color-dark col-3 p-0 center"
              >
                <div className="col-12 fs-09 text-center p-0 my-auto">
                  Ocean Freight Agents
                </div>
              </Link>
              <Link
                to="/packer"
                className="text-decoration color-dark col-2 p-0 center"
              >
                <div className="col-12 fs-09 text-center p-0 my-auto">
                  Packers
                </div>
              </Link>
              <Link
                to="/trucker"
                className="text-decoration color-dark col-2 p-0 center"
              >
                <div className="col-12 fs-09 text-center p-0 my-auto">
                  Truckers
                </div>
              </Link>
              {isLoggedIn ? (
                <div className="d-block text-decoration mt-1 color-dark col-2 p-0 center">
                  {renderProfile()}
                </div>
              ) : null}
            </div>
          </div>
          }
        </div>
        <ul className="dropdown-menu" aria-labelledby="profileDropDownMenu">
          <li>
            <Link to="/profile" className="dropdown-item">
              <FontAwesomeIcon icon={faUserCircle} className="mx-2" />
              Profile
            </Link>
          </li>
          <li>
            <Link to="/orders" className="dropdown-item">
              <FontAwesomeIcon icon={faList} className="mx-2" />
              Orders
            </Link>
          </li>
          <li>
            <Link to="/orders/track" className="dropdown-item">
              <FontAwesomeIcon icon={faList} className="mx-2" />
              Track Order
            </Link>
          </li>
          <li onClick={logout}>
            <span className="dropdown-item fw-bold">
              <FontAwesomeIcon icon={faSignOutAlt} className="mx-2" />
              Logout
            </span>
          </li>
        </ul>
      </div>
      {/* mobile-navbar */}
      <nav className="d-block d-lg-none navbar navbar-expand-lg navbar-light p-0 m-0 py-3">
        <div className="container-fluid">
          <div className="d-flex justify-content-between w-100 align-items-center">
            <div className="navbar-brand">
              <Link to="/">
                <img src="./assets/logo/moversly-logo.svg" alt="" />
              </Link>
            </div>
            <div className="mobile-navbar-profile-container">
              <div className="">{isLoggedIn ? renderProfile(true) : null}</div>
              <button
                ref={mobileMenuRef}
                id="mobile-menu-button"
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
          <div
            className="collapse navbar-collapse pt-5 pb-2 mx-0"
            id="navbarNavAltMarkup"
          >
            <div className="navbar-nav fw-os-b">
              <div className="row">
                {isLoggedIn ? null : (
                  <>
                    <div className="col-12 hover-on-1">
                      <Link to="/pricepacks">
                        <div
                          onClick={closeMenu}
                          className="col-12 m-0 py-3 px-4 fs-10 fw-os-sb text-center btn OceanBlue-bg color-wht button-border"
                        >
                          Signup
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 mt-2 hover-on-1">
                      <Link to="/login">
                        <div
                          onClick={closeMenu}
                          className="col-12 mx-0 mt-1 py-3 px-4 fs-10 fw-os-sb text-center btn OceanBlue-bg color-wht button-border"
                        >
                          Login
                        </div>
                      </Link>
                    </div>
                  </>
                )}
                <div className="d-flex row " style={{justifyContent:"center"}}>
                  <div className="col-6 mt-3 fw-os-sb fs-4 text-center">
                    <Link
                      className="text-decoration subtext-color"
                      to="/about"
                      onClick={closeMenu}
                    >
                      About Us
                    </Link>
                  </div>
                  <div className="col-6 mt-3 fw-os-sb fs-4 text-center">
                    <Link
                      className="text-decoration subtext-color"
                      to="/contact_us"
                      onClick={closeMenu}
                    >
                      Contact Us
                    </Link>
                  </div>
                  <div className="col-6 mt-3 fw-os-sb fs-4 text-center">
                    <Link
                      className="text-decoration subtext-color"
                      to="/privacy"
                      onClick={closeMenu}
                    >
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>

              <hr style={{ width: "100%", height: "3px", opacity: "10%" }} />
              <Link
                to="/customers"
                className="col-12 text-center py-4 m-0 my-auto letter-spacing text-decoration color-dark fs-4"
                onClick={closeMenu}
              >
                Customers
              </Link>
              <Link
                to="/international_mover"
                className="col-12 text-center py-4 m-0 my-auto letter-spacing text-decoration color-dark fs-4"
                onClick={closeMenu}
              >
                International Movers
              </Link>
              <Link
                to="/ocean_freight"
                className="col-12 text-center py-4 m-0 my-auto letter-spacing text-decoration color-dark fs-4"
                onClick={closeMenu}
              >
                Ocean Freight Agents
              </Link>
              <Link
                to="/packer"
                className="col-12 text-center py-4 m-0 my-auto letter-spacing text-decoration color-dark fs-4"
                onClick={closeMenu}
              >
                Packers
              </Link>
              <Link
                to="/trucker"
                className="col-12 text-center py-4 m-0 my-auto letter-spacing text-decoration color-dark fs-4"
                onClick={closeMenu}
              >
                Truckers
              </Link>
            </div>
          </div>
          <div>
            {/* <hr style={{ width: "100%", height: "3px", opacity: "10%" }} /> */}
            {/* <Link to="#" className="col-12 text-center py-4 m-0 my-auto letter-spacing text-decoration color-dark fs-4"
            >
              Customers
            </Link>
            <Link to="#" className="col-12 text-center py-4 m-0 my-auto letter-spacing text-decoration color-dark fs-4"
            >
              International Movers
            </Link>
            <Link to="#" className="col-12 text-center py-4 m-0 my-auto letter-spacing text-decoration color-dark fs-4"
            >
              Ocean Freight Agents
            </Link>
            <Link to="#" className="col-12 text-center py-4 m-0 my-auto letter-spacing text-decoration color-dark fs-4"
            >
              Packers
            </Link>
            <Link to="#" className="col-12 text-center py-4 m-0 my-auto letter-spacing text-decoration color-dark fs-4"
            >
              Truckers
            </Link> */}
          </div>
          <hr style={{ width: "100%", height: "2px", opacity: "10%" }} />
          <div className="col-12 d-flex justify-content-around my-auto">
            <a
              className="col-6 center"
              href="https://apps.apple.com/in/app/moversly/id1575502038"
            >
              <img src="./assets/icons/apple-store.svg" alt="" />
            </a>
            <a
              className="col-6 center"
              href="https://play.google.com/store/apps/details?id=com.moversly.app"
            >
              <img src="./assets/icons/play-store.svg" alt="" />
            </a>
          </div>
          <hr style={{ width: "100%", height: "2px", opacity: "10%" }} />
          <hr style={{ width: "100%", height: "2px", opacity: "10%" }} />
        </div>
      </nav>
    </>
  );

  function renderProfile(fromNavbar) {
    return (
      <div className="dropdown">
        <div
          className="col-12 d-flex align-items-center dropdown-toggle"
          id="profileDropDownMenu"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <ProfilePic />
          {fromNavbar ? null : (
            <div className="fs-08 mx-2 fw-bold">Hey {given_name || ""}!!</div>
          )}
        </div>
        <ul className="dropdown-menu" aria-labelledby="profileDropDownMenu">
          {fromNavbar ? (
            <li className="dropdown-item mx-2 fw-bold">
              Hey {given_name || ""}!!
            </li>
          ) : null}
          <li>
            <Link to="/profile" className="dropdown-item">
              <FontAwesomeIcon icon={faUserCircle} className="mx-2" />
              Profile
            </Link>
          </li>
          <li>
            <Link to="/orders" className="dropdown-item">
              <FontAwesomeIcon icon={faList} className="mx-2" />
              Orders
            </Link>
          </li>
          <li>
            <Link to="/orders/track" className="dropdown-item">
              <FontAwesomeIcon icon={faList} className="mx-2" />
              Track Order
            </Link>
          </li>
          <li onClick={logout}>
            <span className="dropdown-item fw-bold">
              <FontAwesomeIcon icon={faSignOutAlt} className="mx-2" />
              Logout
            </span>
          </li>
        </ul>
      </div>
    );
  }

  function closeMenu() {
    mobileMenuRef.current?.click();
  }

  function logout() {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure you want to logout?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            localStorage.removeItem(storageToken);
            dispatch(handleAuthState("isLoggedIn", false));
            dispatch(resetShipmentState());
            history.push("/");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }
}
