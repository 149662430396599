import { BASE_URL } from "../consts/config";
import { refreshToken, storageToken } from "./browserVariables";
// import store from "../../redux/store";
import { handleAuthState } from "../../redux/actions/authActions";
import configureStore from "../../redux/store";

const store = configureStore();

export default async function Helper(
  url,
  method,
  body,
  isFormData = false,
  needToken = true,
  checkRefresh = true
) {
  const accessToken = localStorage.getItem(storageToken);
  const headers = getHeaders(isFormData, accessToken, method, needToken);
  return fetch(`${BASE_URL}${url}`, {
    method: method,
    body: !isFormData ? JSON.stringify(body) : body,
    headers: headers,
  })
    .then(async (response) => {
      if (response?.status === 403 && checkRefresh) {
        const currentRefreshToken = localStorage.getItem(refreshToken);
        if (currentRefreshToken) {
          const response = await Helper(
            "auth/refresh-token",
            "POST",
            {
              refreshToken: currentRefreshToken,
            },
            // JSON.stringify(currentRefreshToken),

            false,
            false,
            false
          );
          if (response?.access_token) {
            localStorage.setItem(storageToken, response?.access_token);
            const hitResponse = await Helper(
              url,
              method,
              body,
              isFormData,
              needToken,
              false
            );
            if (hitResponse) {
              return hitResponse;
            }
          } else {
            logoutFromApp();
          }
        } else {
          logoutFromApp();
        }
      } else {
        return response.json();
      }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}

function logoutFromApp(pushToLogin = false) {
  store.dispatch(handleAuthState("isLoggedIn", false));
  localStorage.removeItem(storageToken);
  localStorage.removeItem(refreshToken);
  if (pushToLogin) {
    window.location.href = "/login";
  }
}

function getHeaders(isFormData, accessToken, method, needToken) {
  let headers = needToken
    ? {
        Authorization: `Bearer ${accessToken}`,
      }
    : null;
  if (isFormData) {
    return {
      ...headers,
    };
  } else if (method === "GET") {
    return {
      ...headers,
      "Content-Type": "application/json",
    };
  }
  return {
    ...headers,
    "Content-Type": "application/json",
  };
}

export const HelperWithUrl = (url) => {
  return fetch(url)
    .then((response) => response.json())
    .catch((error) => error);
};
