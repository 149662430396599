import { Link } from "react-router-dom"
export default function PricePack() {
    return (
        <>
            {/* BreadCrubs */}
            <div className="container py-4 px-0 px-lg-5">
                <div className="row px-5">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb my-auto">
                            <li className="breadcrumb-item"><Link className="text-center p-0 m-0 my-auto letter-spacing text-decoration font-color" to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">International Movers</li>
                        </ol>
                    </nav>
                </div>
                <div className="row mt-3 px-5 px-lg-2">
                    {/* Platinum card */}
                    <div className="col-12 col-lg-4 py-5 px-2 mx-auto">
                        <div className="col-12 m-0 shadow-lg text-center fs-3 fw-os-sb platinum-bg-color pt-5 pb-5 card-border-radius-top">PLATINUM</div>
                        <div className="row shadow-lg m-0 px-4 d-flex justify-content-center card-border-radius-bottom">
                            <p className="pt-5 pb-2 fs-10">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                            <p className="pt-2 fs-09 m-0 ">As low as</p>
                            <div className="row my-1 d-flex justify-content-start mx-0">
                                <h4 className="col-12 row p-0 fs-3 fw-os-sb m-0 text-start">$80.00
                                    <div className="col-5 fs-5 fw-os-rg my-auto px-0 mx-1">/month</div>
                                </h4>
                            </div>
                            <div className="col-11 on-sale fw-os-sb text-center fs-6 py-1 mt-2">On sale - Save 15%</div>
                            <div className="col-12 fs-10 p-0 px-3 mx-0 my-3 text-start subtext-color">$ 49.99/month when you renew</div>
                            <Link to="/signup" className="hover-on-1"><div className="col-12 mx-0 mt-1 py-3 px-4 fs-4 fw-os-sb text-center btn arrow-bg color-wht button-radius-1">Sign Up</div></Link>
                            <ul className="py-4">
                                <li className="row my-2 text-decoration-none fs-10 fw-os-sb card-list-bolt"><i className="col-1 bi bi-check-lg pe-2 color-success"></i><p className="col-10">Best-in-class CRM Services</p></li>
                                <li className="row my-2 text-decoration-none fs-10 fw-os-sb card-list-bolt"><i className="col-1 bi bi-check-lg pe-2 color-success"></i><p className="col-10">Greater Sales</p></li>
                                <li className="row my-2 text-decoration-none fs-10 fw-os-sb card-list-bolt"><i className="col-1 bi bi-check-lg pe-2 color-success"></i><p className="col-10">Improved Company Profile</p></li>
                                <li className="row my-2 text-decoration-none fs-10 fw-os-sb card-list-bolt"><i className="col-1 bi bi-check-lg pe-2 color-success"></i><p className="col-10">Cost Savings</p></li>
                                <p className="fs-09 fw-os-light mt-1">Join now as an International Mover to expand your client database & keep your customers happy!</p>
                            </ul>
                        </div>
                    </div>
                    {/* Gold card */}
                    <div className="col-12 col-lg-4 py-5 px-2">
                        <div className="col-12 m-0 shadow-lg text-center fs-3 fw-os-sb gold-bg-color pt-5 pb-5 card-border-radius-top">GOLD</div>
                        <div className="row shadow-lg m-0 px-4 d-flex justify-content-center card-border-radius-bottom">
                            <p className="pt-5 pb-2 fs-10">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                            <p className="pt-2 fs-09 m-0 ">As low as</p>
                            <div className="row my-1 d-flex justify-content-start mx-0">
                                <h4 className="col-12 row p-0 fs-3 fw-os-sb m-0 text-start">$40.00
                                    <div className="col-5 fs-5 fw-os-rg my-auto px-0 mx-1">/month</div>
                                </h4>
                            </div>
                            <div className="col-11 on-sale fw-os-sb text-center fs-6 py-1 mt-2">On sale - Save 10%</div>
                            <div className="col-12 fs-10 p-0 px-3 mx-0 my-3 text-start subtext-color">$ 40.99/month when you renew</div>
                            <Link to="/signup" className="hover-on-1"><div className="col-12 mx-0 mt-1 py-3 px-4 fs-4 fw-os-sb text-center btn arrow-bg color-wht button-radius-1">Sign Up</div></Link>
                            <ul className="py-4">
                                <li className="row my-2 text-decoration-none fs-10 fw-os-sb card-list-bolt"><i className="col-1 bi bi-check-lg pe-2 color-success"></i><p className="col-10">Powerful & Reliable Software</p></li>
                                <li className="row my-2 text-decoration-none fs-10 fw-os-sb card-list-bolt"><i className="col-1 bi bi-check-lg pe-2 color-success"></i><p className="col-10">Aggregator for Movers</p></li>
                                <li className="row my-2 text-decoration-none fs-10 fw-os-sb card-list-bolt"><i className="col-1 bi bi-check-lg pe-2 color-success"></i><p className="col-10">Cost Savings</p></li>
                                <li className="row my-2 text-decoration-none fs-10 fw-os-sb card-list-bolt"><i className="col-1 bi bi-check-lg pe-2 color-success"></i><p className="col-10">Increased Sales</p></li>
                                <p className="fs-09 fw-os-light mt-1">Join now as an International Mover to expand your client database & keep your customers happy!</p>
                            </ul>
                        </div>
                    </div>
                    {/* Silver card */}
                    <div className="col-12 col-lg-4 py-5 px-2 mx-auto">
                        <div className="col-12 m-0 shadow-lg text-center fs-3 fw-os-sb silver-bg-color pt-5 pb-5 card-border-radius-top">SILVER</div>
                        <div className="row shadow-lg m-0 px-4 d-flex justify-content-center card-border-radius-bottom">
                            <p className="pt-5 pb-2 fs-10">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                            <p className="pt-2 fs-09 m-0 ">As low as</p>
                            <div className="row my-1 d-flex justify-content-start mx-0">
                                <h4 className="col-12 row p-0 fs-3 fw-os-sb m-0 text-start">$20.00
                                    <div className="col-5 fs-5 fw-os-rg my-auto px-0 mx-1">/month</div>
                                </h4>
                            </div>
                            <div className="col-11 on-sale fw-os-sb text-center fs-6 py-1 mt-2">On sale - Save 5%</div>
                            <div className="col-12 fs-10 p-0 px-3 mx-0 my-3 text-start subtext-color">$ 30.99/month when you renew</div>
                            <Link to="/signup" className="hover-on-1"><div className="col-12 mx-0 mt-1 py-3 px-4 fs-4 fw-os-sb text-center btn arrow-bg color-wht button-radius-1">Sign Up</div></Link>
                            <ul className="py-4">
                                <li className="row my-2 text-decoration-none fs-10 fw-os-sb card-list-bolt"><i className="col-1 bi bi-check-lg pe-2 color-success"></i><p className="col-10">Grow your Sales</p></li>
                                <li className="row my-2 text-decoration-none fs-10 fw-os-sb card-list-bolt"><i className="col-1 bi bi-check-lg pe-2 color-success"></i><p className="col-10">Get More Leads</p></li>
                                <li className="row my-2 text-decoration-none fs-10 fw-os-sb card-list-bolt"><i className="col-1 bi bi-check-lg pe-2 color-success"></i><p className="col-10">Increase Productivity</p></li>
                                <li className="row my-2 text-decoration-none fs-10 fw-os-sb card-list-bolt"><i className="col-1 bi bi-check-lg pe-2 color-success"></i><p className="col-10">Better Customer Experience</p></li>
                                <p className="fs-09 fw-os-light mt-1">Join now as an International Mover to expand your client database & keep your customers happy!</p>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}