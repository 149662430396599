
import { makeStyles } from "@material-ui/core/styles";
import {MdLocationOn} from "react-icons/md"

export default function LocationListItem(props) {
    const classes = useStyles();

    const {
        location,
        onClick
    } = props;

    return (
        <div
        className={`${classes.root} row d-flex align-items-center `}
            // className="location-item row d-flex align-items-center"
            onClick={() => {
                if(onClick){
                    onClick();
                }
            }}
        >
           <i className="col-1"><MdLocationOn size={30}/></i> 
            <span className="col-10">{location.description || ''}</span>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root:{
        padding: 10,
        borderRadius: 5,
        cursor: "pointer",
        '&:hover': {
            backgroundColor: "#aaaaaa22",
            transition: "all 0.25s ease-out",
            color: "#4E3883"
         },
    }
}))