import { combineReducers } from "redux";
import shipmentReducer from "./shipmentReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import configReducer from "./configReducer";
import partnerReducer from "./partnerReducer";
import ordersReducer from "./orderReducer";

const reducers = combineReducers({
  shipment: shipmentReducer,
  auth: authReducer,
  user: userReducer,
  config: configReducer,
  partner: partnerReducer,
  order: ordersReducer,
});

export default reducers;
