import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./track.css";
import AllOrders from "./components/AllOrders";
import TrackOrder from "./components/TrackOrder";
import DocsUpload from "./components/DocsUpload";
import Surveys from "./components/Surveys";
import UnauthorizedTrack from "./components/UnauthorizedTrack";

const useStyles = makeStyles((theme) => ({
  separatetabele: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: " 0 15px",
  },
  tablehead: {
    textAlign: "left",
    border: "none",
    padding: 20,
    fontWeight: "300",
    fontSize: "1em",
    position: "sticky",
    top: 0,
  },
  tableheadrow: {},
}));

const Tracking = (props) => {
  const { setShowHeaderFooter } = props;
  const classes = useStyles();
  const [page, setPage] = useState("Home");

  function renderPage() {
    switch (page) {
      case "Home":
        return <AllOrders setPage={setPage} />;
      case "Track":
        return <TrackOrder setPage={setPage} />;
      case "Docs":
        return <DocsUpload setPage={setPage} />;
      case "Survey":
        return <Surveys />;
      // case "unauthorized":
      //   return <UnauthorizedTrack setPage={setPage} />;

      default:
        return <AllOrders />;
    }
  }

  useEffect(() => {
    setShowHeaderFooter(false);
  }, []);
  return <Fragment>{renderPage()}</Fragment>;
};

export default Tracking;
