import { useSelector } from "react-redux";
import { useState } from 'react';

// components
import TextInput from "../../common/components/TextInput";
import BreadCrumb from "../../common/components/BreadCrumb";

export default function ProfilePage() {

    const user = useSelector(state => state?.user);
    const [error, setErrors] = useState({});

    return (
        <>
            <div className="container-fluid bg-color-2 color-2 py-4">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-6 my-auto">
                            <h1 className="col-12 fs-1">International Movers</h1>
                            <h4 className="col-12 fs-4">Join now to get one month free usage.</h4>
                        </div>
                        <div className="col-6 col-lg-4">
                            <img className="img-fluid" src="./assets/images/image-2.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-4 px-0 px-lg-5">
                <BreadCrumb
                    list={[
                        {
                            title: 'Home',
                            link: '/'
                        },
                        {
                            title: 'Profile',
                            link: '/'
                        }
                    ]}
                />
                <div className="row d-flex justify-content-between px-2">
                    <div className={`col-12 col-lg-8 offset-lg-2 px-5 mt-4 mb-5 pb-4`}>
                        <h1 className="col-12">Profile</h1>
                        <TextInput
                            label="Email:"
                            placeholder="e.g. john@gmail.com"
                            id="email"
                            value={user?.email}
                            error={error?.email}
                            type="email"
                            inputProps={{
                                disabled: true
                            }}
                        />
                        <TextInput
                            label="Phone:"
                            placeholder="e.g. XXXXXXXX"
                            id="phone"
                            value={user?.phone}
                            error={error?.phone}
                            type="phone"
                            inputProps={{
                                disabled: true
                            }}
                        />
                        <TextInput
                            label="address:"
                            placeholder="e.g. john@gmail.com"
                            id="address"
                            value={user?.address}
                            error={error?.address}
                            type="address"
                            inputProps={{
                                disabled: true
                            }}
                        />
                        <TextInput
                            label="Given Name:"
                            placeholder="e.g. john smith"
                            id="given_name"
                            value={user?.given_name}
                            error={error?.given_name}
                            type="given_name"
                            inputProps={{
                                disabled: true
                            }}
                        />
                        <TextInput
                            label="Family Name:"
                            placeholder="e.g. john smith"
                            id="family_name"
                            value={user?.family_name}
                            error={error?.family_name}
                            type="family_name"
                            inputProps={{
                                disabled: true
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}