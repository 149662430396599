import { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

// components
import BreadCrumb from "../../../common/components/BreadCrumb";
import Features from "../../../common/components/Features";
import Button from '../../../common/components/Button';
import TextInput from '../../../common/components/TextInput';

// constants
import Helper from '../../../common/consts/helper';
import { API_ROUTES, COMPONENT_DATA } from "../../../common/consts/config";
import { startCase } from "lodash";
import messages from "../../../common/consts/messages";
import { storageToken } from '../../../common/consts/browserVariables';

// redux
import { handleAuthState } from '../../../redux/actions/authActions';
import { useDispatch } from "react-redux";

const initialErrors = COMPONENT_DATA?.login?.initialErrors || {};

export default function ResetForm(props) {

    let history = useHistory();
    const email = history?.location?.state?.username

    const {
        isCustomerDetails,
        onLoginSuccess
    } = props;

    const { addToast } = useToasts();

    const [data, setData] = useState({
        code: "",
        email: email,
        password: "",
    });

    const [error, setError] = useState({
        ...initialErrors
    });

    const [isLoading, setIsLoading] = useState(false);

    const [apiError, setApiError] = useState('');

    const [validationFired, setValidationFired] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (validationFired) {
            validate();
        }
    }, [validationFired, data]);

    return (
        <>
            {/* BreadCrubs */}
            <BreadCrumb
                list={[
                    {
                        title: 'Home',
                        link: '/'
                    },
                    {
                        title: 'Login',
                        link: '/'
                    }
                ]}
            />
            {/* form */}
            <div className="row d-flex justify-content-between px-2">
                <div className={`col-12 col-lg-${isCustomerDetails ? '12' : '5'} px-5 mt-4 mb-5 pb-4`}>
                    <h1 className="col-12">Reset Password</h1>
                    <TextInput
                        label="Code:"
                        placeholder="e.g. 123456"
                        id="code"
                        value={data?.code}
                        onChange={handleChange}
                        error={error?.code}
                        type="text"
                        onEnterPress={submit}
                    />
                    {/* <TextInput
                        label="Username:"
                        placeholder="e.g. john@gmail.com"
                        id="email"
                        value={data?.email}
                        onChange={handleChange}
                        error={error?.email}
                        type="email"
                        onEnterPress={submit}
                    /> */}
                    <TextInput
                        label="Password:"
                        placeholder="*********"
                        id="password"
                        type="password"
                        value={data?.password}
                        onChange={handleChange}
                        error={error?.password}
                        onEnterPress={submit}
                    />
                    {
                        apiError ? (
                            <div className="error-feedback">
                                {apiError}
                            </div>
                        ) : null
                    }
                    <div className="col-12 mt-4 p-0 text-center gap-4">
                        <Button
                            title="Login"
                            type="dark"
                            isLoading={isLoading}
                            onClick={submit}
                        />
                    </div>
                </div>
                {
                    !isCustomerDetails ? (
                        <Features />
                    ) : null
                }
            </div>
        </>
    );

    function validate() {
        setValidationFired(true);
        let validated = true;
        const errors = {
            ...initialErrors
        }
        Object.keys(data)?.forEach((field) => {
            if (!data[field]) {
                errors[field] = `${startCase(field)} is required!`;
                validated = false;
            }
        });
        setError(errors);

        return validated;
    }

    async function submit() {
        const validated = validate();
        if (validated) {
            try {
                setIsLoading(true);
                setApiError('');
                const body = {
                    code: data.code,
                    username: data.email,
                    new_password: data.password
                }
                const route = API_ROUTES?.reset?.route || '';
                const method = API_ROUTES?.reset?.method || '';

                const response = await Helper(route, method, body, false, false);
                if (response?.error_code) {
                    setApiError(response?.message);
                }
                else {
                    history.push("/login")
                    addToast(response?.message, { appearance: 'success' });
                }
            }
            catch (error) {
                addToast(messages.loginError, { appearance: 'error' });
                // toast here with error
            }
            finally {
                setIsLoading(false);
            }
        }
    }

    function handleChange(text, type) {
        setData({
            ...data,
            [type]: text
        })
    }
}