import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
  Button,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  Divider,
  Slide,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import moment from "moment";
import "./card.css";
import { useTheme } from "@mui/material/styles";
import { RiFileList2Line } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import { Country } from "country-state-city";
import { useMediaQuery } from "@material-ui/core";
import Helper from "../../../common/consts/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const BookerCards = ({ leads, handleOpenAddLeads, ...rest }) => {
  const isLoading = false;
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [fromCountry, setFromCountry] = React.useState("");
  const [docs, setDocs] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const assigned = true;

  const shipment_mode = leads?.payload?.shipment_details?.shipment_mode;
  const fromAddress = leads?.payload?.schedule_shipment?.to_address;

  const handleopenRequired = () => {
    setOpen(true);
    const country_code =
      leads?.payload?.schedule_shipment?.to_address?.country_code;
    console.log(country_code);
    setFromCountry(Country?.getCountryByCode(`${country_code}`)?.name);
    getDocuments(country_code, shipment_mode);
  };

  const handleClose = () => {
    setOpen(false);
    setDocs({});
  };

  const getDocuments = async (from_country, shipment_mode) => {
    try {
      const response = await Helper(
        `documents/required-documents/${from_country}/${shipment_mode}`
      );
      if (response.error_code) {
      } else {
        setDocs(response);
      }
      // console.log(response);
    } catch (error) {}
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      {...rest}
    >
      <CardContent>
        <div className="profiles mb-3">
          <div className="profile_names">
            <h4>
              {leads?.payload?.user_info?.given_name}{" "}
              {leads?.payload?.user_info?.family_name}
            </h4>
            <p>{leads?.payload?.user_info?.email} </p>
          </div>
        </div>

        <div className="details mb-3">
          <div className="requestId">
            {isLoading ? (
              <Skeleton variant="text" height={30} />
            ) : (
              <h6>{leads?.id}</h6>
            )}

            <p>Request ID</p>
          </div>

          <div className="date">
            {isLoading ? (
              <Skeleton variant="text" height={30} />
            ) : (
              <h6>
                {" "}
                {moment(
                  leads?.payload?.schedule_shipment?.moving_date * 1000
                ).format("DD-MM-YYYY")}
              </h6>
            )}

            <p>Moving Date</p>
          </div>
        </div>
        <div className="details">
          <div className="shipment_mode">
            {isLoading ? (
              <Skeleton variant="text" height={30} />
            ) : (
              <h6>
                {leads?.payload?.shipment_details?.shipment_mode
                  ? leads?.payload?.shipment_details?.shipment_mode
                  : "NA"}
              </h6>
            )}

            <p>Shipment Mode</p>
          </div>

          <div className="status">
            {assigned && (
              <span style={{ border: "1px solid #7CB217", color: "#7CB217" }}>
                assigned
              </span>
            )}
            {!assigned && <span>Not assigned</span>}
            <p>Status</p>
          </div>
        </div>

        <div className="location py-2">
          <div className="py-1">
            <div>
              <div className="circle  mx-3">
                <div className="dot" />
              </div>
            </div>
            <div className="line mx-4 " />
            <div>
              <div className="circle  mx-3">
                <div className="dot" />
              </div>
            </div>
          </div>

          <div>
            {isLoading ? (
              <Skeleton variant="text" height={30} />
            ) : (
              <p>{`${
                leads?.payload?.schedule_shipment?.from_address?.city || "NA"
              },${
                leads?.payload?.schedule_shipment?.from_address?.country_code ||
                "NA"
              } `}</p>
            )}

            {isLoading ? (
              <Skeleton variant="text" height={30} width={50} />
            ) : (
              <p>{`${
                leads?.payload?.schedule_shipment?.to_address?.city || "NA"
              },${
                leads?.payload?.schedule_shipment?.to_address?.country_code ||
                "NA"
              } `}</p>
            )}
          </div>
        </div>

        <div
          className="info pt-2 "
          style={{
            cursor: "pointer",
            marginBottom: -25,
          }}
          onClick={() => handleopenRequired()}
        >
          <Tooltip title="Standard Document Required ">
            <IconButton>
              <RiFileList2Line className="info_icon3" />
            </IconButton>
          </Tooltip>
          <span>Document's Required</span>
        </div>

        <div className="card-btn d-flex justify-content-end  ">
          <button
            onClick={() => history.push(`/order/track/${leads?.id}`, leads?.id)}
            type="button"
            className="btn btn-edit btn-sm me-4 "
          >
            Track Order
          </button>
          <button
            onClick={() => handleOpenAddLeads(leads?.id)}
            type="button"
            className="btn btn-edit btn-sm me-4 "
          >
            View Details
          </button>
        </div>
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
          <Grid
            item
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Typography
              color="textSecondary"
              display="inline"
              sx={{ pl: 1 }}
              variant="body2"
            >
              Registered Date{" "}
              {moment(leads?.created_date * 1000)?.format("LLLL")}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            {/* <DownloadIcon color="action" /> */}
            {/* <Typography
              color="textSecondary"
              display="inline"
              sx={{ pl: 1 }}
              variant="body2"
            >
              {leads?.totalDownloads} Downloads
            </Typography> */}
          </Grid>
        </Grid>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {`Documents Required for ${fromCountry}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {Object?.keys(docs)?.map((doc, i) => {
              return (
                <List key={i}>
                  <ListItem>
                    <ListItemIcon>
                      <InsertDriveFileIcon />
                    </ListItemIcon>
                    <ListItemText primary={docs[doc]} />
                  </ListItem>
                </List>
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
           Disagree
         </Button> */}
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );

  BookerCards.propTypes = {
    leads: PropTypes.object.isRequired,
  };
};
