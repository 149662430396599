import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import produce from "immer";

import Modal from "react-modal";

import Button from "../../../common/components/Button";
import TextInput from "../../../common/components/TextInput";
import LocationListItem from "./LocationListItem";
import Spinner from "../../../common/components/Spinner";

import GoogleMapReact from "google-map-react";

import { API_ROUTES, MAP_API_KEY } from "../../../common/consts/config";
import Pin from "../../../assets/images/pin.png";
import { debounce, find } from "lodash";
import Helper, { HelperWithUrl } from "../../../common/consts/helper";
import { useDispatch, useSelector } from "react-redux";
import { handleShipmentState } from "../../../redux/actions/shipmentActions";
import messages from "../../../common/consts/messages";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";

const MapPin = () => (
  <img className="marker-pin-center" src={Pin} width="20" alt="map-pin" />
);

export default function LocationModal(props) {
  const [address, setAddress] = useState({ place_id: "" });
  const [addressDetails, setAddressDetails] = useState({});
  const [pinCode, setPinCode] = useState('');
  const { locationModalOpen, setLocationModalOpen, addressType } = props;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-30%",
      transform: "translate(-50%, -50%)",
      maxWidth: "700px",
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: "0px 0px 5px #00000088",
     
   
    },
  };

  useEffect(() => {
    if (address?.place_id) {
      geocodeByPlaceId(address?.place_id)
        .then((results) => {
            getLatLng(results[0])
            .then(response => {
                results[0].geometry.location = response;
                setAddressDetails(results[0]);
                if(results[0].address_components[results[0].address_components?.length-1]?.types[0] === "postal_code") {
                    setPinCode(results[0].address_components[results[0].address_components?.length-1]?.long_name);
                }
            })
            .catch(error => console.log(error));
        })
        .catch((error) => console.error(error));
      // placesService?.getDetails({
      //     placeId: address?.place_id
      // }, (placeDetails) => {
      //     console.log(placeDetails)
      //     setAddressDetails(placeDetails);
      // })
      // Helper(`places/${address?.place_id}`, "GET", undefined, false, false)
      //     .then(response => {
      //         console.log(response);
      //         setAddressDetails(response)
      //     })
      //     .catch(error => { 
      //         console.log(error)
      //         // console.log(error)
      //     })
    }
  }, [address]);

  return (
    <Modal
      isOpen={locationModalOpen !== 0 ? true : false}
      onAfterOpen={() => {}}
      onRequestClose={() => {}}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {locationModalOpen === 1 ? (
        <SearchLocation
          setLocationModalOpen={setLocationModalOpen}
          setAddress={setAddress}
        />
      ) : (
        <MapLocation
          setLocationModalOpen={setLocationModalOpen}
          addressDetails={addressDetails}
          addressType={addressType}
          postalCode={pinCode}
          isFindPostalCode={false}
        />
      )}
    </Modal>
  );
}

function MapLocation({ setLocationModalOpen, addressDetails, addressType, postalCode, isFindPostalCode }) {
  const dispatch = useDispatch();

  const schedule_shipment = useSelector(
    (state) => state.shipment?.schedule_shipment
  );

  const [currentAddress, setCurrentAddress] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [errors, setErrors] = useState({
    currentAddress: "",
    pinCode: "",
  });
  const [validationFired, setValidationFired] = useState(false);
  const [findPostalCode, setFindPostalCode] = useState(isFindPostalCode);
  const { addToast } = useToasts();

  useEffect(() => {
    setPinCode(postalCode);
  }, [postalCode])

  useEffect(() => {
    const coordinates = schedule_shipment?.from_address?.coordinates;
    const coordinates2 = schedule_shipment?.to_address?.coordinates;
    if (addressType === 0) {
      fetchPostalCode(coordinates);
    } else {
      fetchPostalCode(coordinates2);
    }
  }, [
    schedule_shipment?.from_address?.coordinates,
    schedule_shipment?.to_address?.coordinates,
  ]);

  useEffect(() => {
    if (validationFired) {
      validate();
    }
  }, [validationFired, currentAddress, pinCode]);
// 
  return (
    <>
      <div className="row mt-4 mb-0" >
        <div className="d-flex align-items-center">
          <h1 className="col-12 ">
            {addressType === 0 ? "From" : "To"} Address
          </h1>
        </div>
        <p>Please enter the required details.</p>

        <div className=" map-item-container" >
          <GoogleMapReact
            bootstrapURLKeys={{ key: MAP_API_KEY }}
            center={{
              lat: addressDetails?.geometry?.location?.lat || 59.95,
              lng: addressDetails?.geometry?.location?.lng || 30.33,
            }}
            defaultZoom={16}
            onChange={(details) => {
              handleCoords(details?.center);
              setFindPostalCode(true);
            }}
          />
          <MapPin />
        </div>
        <TextInput
          label="Enter Floor & House Number"
          placeholder="e.g. 84, Princess Street"
          id="address"
          onChange={(value) => {
            setCurrentAddress(value);
          }}
          error={errors.currentAddress}
        />
        <TextInput
          label="Enter Postal / Zip Code"
          placeholder="e.g. 14402"
          
          id="zipCode"
          onChange={(value) => {
            setPinCode(value);
          }}
          value={pinCode}
          error={errors.pinCode}
        />
      </div>
      <div className="row mt-5">
        <div className="col-12 text-end d-flex" style={{marginTop:-40}}>
          <Button
            type="light"
            title="Close"
            style={{
              width: "150px",
            }}
            onClick={() => {
              setLocationModalOpen(0);
            }}
          />
          <Button
            type="dark"
            title="Save"
            style={{
              width: "150px",
              marginLeft: "20px",
            }}
            onClick={submitMap}
          />
        </div>
      </div>
    </>
  );

  function validate() {
    setValidationFired(true);
    let validated = true;

    const localErrors = {
      currentAddress: "",
      pinCode: "",
    };

    if (currentAddress == "") {
      validated = false;
      localErrors.currentAddress = "Field Required";
    }
    if (pinCode === "") {
      validated = false;
      localErrors.currentAddress = "Field Required";
    }

    setErrors(localErrors);
    return validated;
  }

  function submitMap() {
    if (validate()) {
      handleAddress(
        `${currentAddress}, ${addressDetails?.formatted_address}`,
        pinCode
      );
      setLocationModalOpen(0);
    }
  }
  function fetchPostalCode(coordinates) {
    if(findPostalCode) {
        fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates?.latitude},${coordinates?.longitude}&key=${MAP_API_KEY}&result_type=postal_code`
          )
            .then((response) => response.json())
            .then((response) => {
              if (response?.results && response?.results[0]?.address_components) {
                const address_components = response?.results[0]?.address_components;
                const postalCode = address_components?.find((e) =>
                  e.types?.includes("postal_code")
                )?.long_name;
                if (postalCode) {
                  setPinCode(postalCode);
                }
              }
            })
            .catch((error) => {
              addToast(messages.postalCodeError, { appearance: "error" });
            });
    }
  }

  function formatCountryCode(address) {
    let add = address.find(
      (element) =>
        (element?.types?.includes("political") ||
          element?.types?.includes("POLITICAL")) &&
        (element?.types?.includes("country") ||
          element?.types?.includes("COUNTRY"))
    );
    return add?.short_name || "";
  }

  function formatCity(address) {
    let add = address.find(
      (element) =>
        (element?.types?.includes("political") ||
          element?.types?.includes("POLITICAL")) &&
        (element?.types?.includes("locality") ||
          element?.types?.includes("LOCALITY"))
    );
    return add?.long_name || "";
  }

  function handleAddress(address, zip) {
    handleChange(
      "schedule_shipment",
      produce(schedule_shipment, (draft) => {
        if (addressType === 0) {
          draft.from_address.street_lines = address;
          draft.from_address.postal_code = zip;
          draft.from_address.country_code = formatCountryCode(
            addressDetails?.address_components || []
          );
          draft.from_address.city = formatCity(
            addressDetails?.address_components || []
          );
        } else {
          draft.to_address.street_lines = address;
          draft.to_address.postal_code = zip;
          draft.to_address.country_code = formatCountryCode(
            addressDetails?.address_components || []
          );
          draft.to_address.city = formatCity(
            addressDetails?.address_components || []
          );
        }
      })
    );
  }

  function handleCoords(center) {
    handleChange(
      "schedule_shipment",
      produce(schedule_shipment, (draft) => {
        if (addressType === 0) {
          draft.from_address.coordinates.longitude = center?.lng;
          draft.from_address.coordinates.latitude = center?.lat;
        } else {
          draft.to_address.coordinates.longitude = center?.lng;
          draft.to_address.coordinates.latitude = center?.lat;
        }
      })
    );
  }

  function handleChange(name, value) {
    dispatch(handleShipmentState(name, value));
  }
}

function SearchLocation({ setLocationModalOpen, setAddress }) {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [value, setValue] = useState(null);
  const getLocations = debounce((value) => getLocationsApi(value), 1000);
  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: MAP_API_KEY,
  });

  useEffect(() => {
    if (placePredictions.length) {
      setIsLoading(false);
      setLocations(placePredictions);
    }
  }, [placePredictions]);

  return (
    <>
      <div className="row mt-4 mb-1">
        {/* <h1 className="col-12">Are you a new customer?</h1> */}
        <h1 className="col-12">Search Location</h1>
        <p>Type a post code or part of your address to begin with</p>
        <TextInput
          label="Search Location"
          placeholder="e.g. 84, Princess Street"
          onChange={(value) => getLocations(value)}
        />
        <div className="col-12 text-center">
          <Spinner active={isLoading} />
        </div>
        <div className="location-item-container">
          {locations
            ? locations
                ?.filter((e) => e?.terms[e.terms?.length - 1]?.offset > 15)
                ?.map((loc, index) => {
                  return (
                    <LocationListItem
                      key={index}
                      location={loc}
                      onClick={() => {
                        setAddress(loc);
                        setLocationModalOpen(2);
                      }}
                    />
                  );
                })
            : null}
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 text-end">
          <Button
            type="dark"
            title="Close"
            style={{
              width: "150px",
            }}
            onClick={() => {
              setLocationModalOpen(0);
            }}
          />
        </div>
      </div>
    </>
  );

  function getLocationsApi(value) {
    if (value.length > 2) {
      setIsLoading(true);
      getPlacePredictions({ input: value });
      // const autoComplete = new google.maps.plcaes.Autocomplete()
      // HelperWithUrl(`https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${MAP_API_KEY}&input=${value}`)
      //     .then(response => {
      //         if (!response.error_code) {
      //             console.log(response);
      //             setLocations(response?.predictions);
      //         }
      //         else {
      //             addToast(messages.locationError, {
      //                 autoDismiss: true,
      //                 appearance: 'error',
      //             })
      //         }
      //     })
      //     .catch(error => {
      //         console.log(error)
      //     })
      //     .finally(() => setIsLoading(false))
    }
  }
}
