import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  TextField,
  Typography,
  colors,
  Input,
  Grid,
  Select,
  MenuItem,
  Divider,
  CircularProgress,
  IconButton,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { snakeCase } from "lodash";
import { makeStyles } from "@material-ui/core/styles";

import LocationModal from "./LocationModal";
import { useSelector, useDispatch } from "react-redux";
import {
  API_ROUTES,
  COMPONENT_DATA,
  SHIPMENT_TYPE,
  CONTAINER_TYPE_OPTIONS,
  CUSTOMER_STATE,
} from "../../../common/consts/config";
import produce from "immer";
import moment from "moment";
import {
  handleShipmentState,
  resetShipmentState,
} from "../../../redux/actions/shipmentActions";
import LuggageTable from "./LuggageTable";
import BoxTable from "./BoxTable";
import { lowerCase, startCase } from "lodash";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import Helper from "../../../common/consts/helper";
import { storageToken } from "../../../common/consts/browserVariables";
import { Save } from "@material-ui/icons";
import PhoneInput from "react-phone-input-2";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Box,
  Checkbox,
  DialogContent,
  DialogTitle,
  ListItemText,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 960,
    height: 700,
    [theme.breakpoints.down("md")]: {
      maxWidth: 290,
    },
    overflowX: "hidden",
  },

  header: {
    // padding: theme.spacing(3),
    maxWidth: 720,
    margin: "0 auto",
  },
  content: {
    padding: theme.spacing(0, 2),
    margin: "20px 20px",
    [theme.breakpoints.down("md")]: {
      margin: "20px auto",
    },
  },
  helperText: {
    textAlign: "right",
    marginRight: 0,
  },
  author: {
    margin: theme.spacing(4, 0),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-around",
    // position: "fixed",
    bottom: 0,
    [theme.breakpoints.down("md")]: {
      Width: 270,
      display: "block",
    },
  },
  applyButton: {
    color: "#fff",
    marginLeft: 20,
    backgroundColor: "#4E3883",
    "&:hover": {
      backgroundColor: " #8267C1",
    },
  },
  applyButtonCancel: {
    color: "#fff",
    marginLeft: 20,
    backgroundColor: "#FF2E2E",
    "&:hover": {
      backgroundColor: " #FF2E2f",
    },
  },
  applyButtonUpdate: {
    color: "#fff",
    marginLeft: 20,
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "lightgreen",
    },
  },
  input: {
    width: 350,
    // width: 150,
    // backgroundColor: " #FF2E2f",
    [theme.breakpoints.down("md")]: {
      maxWidth: 275,
    },
  },
  inputContainer: {
    width: 450,
    // backgroundColor: "#4E3883",
    margin: "20px 30px",
    // margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      margin: "10px 0",
    },
  },
  divider: {
    // height: 10,
    backgroundColor: "#4E3883",
    marginTop: 30,
    marginBottom: 20,
    // borderWidth: 2,
  },
  divider1: {
    // height: 10,
    backgroundColor: "#4E3883",
    marginTop: 10,
    marginBottom: 20,
    // borderWidth: 2,
  },
  link: {
    textDecoration: "none",
  },
  button: {
    position: "relative",
    color: "#4E3883",
    right: "40px",
    bottom: "10px",
    // color: "#fff",
    // backgroundColor: "#4E3883",
    // "&:hover": {
    //   backgroundColor: " #8267C1",
    // },
    // marginLeft:-85,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BookerView = (props) => {
  const { open, onClose, className, itemId, pathname, ...rest } = props;

  // const path = pathname.split("/")[3];
  const shipment = useSelector((state) => state?.shipment);
  const comments = useSelector((state) => state?.comments);

  const [apiError, setApiError] = useState("");
  const [removed, setRemoved] = useState(false);
  const [priceError, setPriceError] = useState("");
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [customCrate, setCustomCrate] = useState([
    {
      item_name: "",
      magnitude: "",
    },
  ]);

  const {
    schedule_shipment,
    shipment_details,
    rate,
    user_info,
    surveyType,
    arrangements,
  } = shipment;

  const shipment_mode = shipment_details?.shipment_mode;
  const [locationModal, setLocationModal] = useState(0);
  // 0 -> closed
  // 1 -> open with search locations
  // 2 -> open for map
  const [addressType, setAddressType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const employees = useSelector((state) => state?.employee?.myEmployees?.users);
  const packers = useSelector((state) => state?.employee?.packers?.users);
  const destinations = useSelector(
    (state) => state?.employee?.destinations?.users
  );
  const salesAssigned = shipment?.sales_associate_id;
  const operationsAssigned = shipment?.operations_associate_id;

  const packerAssigned = shipment?.packer_id;

  const [assignedSales, setAssignedSales] = useState(salesAssigned || "");
  const [assignedOperations, setAssignedOperations] = useState(
    operationsAssigned || ""
  );
  const [assignedPacker, setAssignedPacker] = useState(packerAssigned || "");
  const [assignedDestination, setAssignedDestination] = useState("");

  const initialErrors = {
    ...COMPONENT_DATA?.bookShipment?.formOne?.initialErrors,
  };

  const seaMovingType =
    COMPONENT_DATA?.bookShipment?.formTwo?.seaMovingType || [];
  const roadMovingType =
    COMPONENT_DATA?.bookShipment?.formTwo?.roadMovingType || [];
  const airMovingType =
    COMPONENT_DATA?.bookShipment?.formTwo?.airMovingType || [];
  const [errors, setErrors] = useState({
    ...initialErrors,
  });

  const [validationFired, setValidationFired] = useState(false);
  const [isValidationFired, setIsValidationFired] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [movingTypeItem, setMovingTypeItem] = useState([]);

  useEffect(() => {
    if (shipment_details?.shipment_mode === "SEA") {
      setMovingTypeItem(seaMovingType);
    } else if (shipment_details?.shipment_mode === "AIR") {
      setMovingTypeItem(airMovingType);
    } else {
      setMovingTypeItem(roadMovingType);
    }
  }, [shipment_details?.shipment_mode]);
  useEffect(() => {
    if (validationFired) {
      validateScheduleDetails();
    }
  }, [shipment_details, schedule_shipment, validationFired]);

  useEffect(() => {
    if (isValidationFired) {
      validateScheduleShipment();
    }
  }, [isValidationFired, shipment_details]);

  const handleOpenLocationModal = (e, item) => {
    setLocationModal(1);
    setAddressType(item);
  };
  const handleCloseLocationModal = () => {
    setLocationModal(0);
  };
  const dateToEpoch = (date) => {
    return (date.getTime() / 1000.0).toFixed(0);
  };

  const epochToDateConverter = (epochDate) => {
    const valueConvert = 60000;
    const milliseconds = 1000;
    const zone = new Date().getTimezoneOffset() * -1 * valueConvert;
    const newEpoch = epochDate * milliseconds;
    const dateConvert = new Date(newEpoch + zone);
    return moment.utc(dateConvert).format("YYYY-MM-DD");
  };

  //Comments

  // user inputs
  function handleChange(name, value) {
    dispatch(handleShipmentState(name, value));
  }

  function handleShipmentDate(date) {
    let newDate = dateToEpoch(date);
    handleChange(
      "schedule_shipment",
      produce(schedule_shipment, (draft) => {
        draft.moving_date = newDate;
      })
    );
  }

  function handleShipmentMode(mode) {
    handleChange(
      "shipment_details",
      produce(shipment_details, (draft) => {
        draft.shipment_mode = mode;
      })
    );
  }

  function handleLevel1Data(value, type) {
    handleChange(
      "shipment_details",
      produce(shipment_details, (draft) => {
        draft[type] = value;
      })
    );
  }

  function handleuserData(value, type) {
    handleChange(
      "user_info",
      produce(user_info, (draft) => {
        draft[type] = value;
      })
    );
  }

  function handleArrangmentData(value, type, deliveryType, material) {
    handleChange(
      "arrangements",
      produce(arrangements, (draft) => {
        if (deliveryType === "pre_materials_delivery") {
          draft.pre_materials_delivery[type] = value;
          if (material) {
            draft.pre_materials_delivery.materials[type] = value;
          }
        }

        if (deliveryType === "packing_day_materials_delivery") {
          draft.packing_day_materials_delivery[type] = value;
          if (material) {
            draft.packing_day_materials_delivery.materials[type] = value;
          }
        }

        if (deliveryType === "crate") {
          if (type === "items_to_crate") {
            draft.crate.items_to_crate = { ...value };
          }

          if (type === "number_of_crate") draft.crate.number_of_crate = value;
        }
      })
    );
  }

  const handleNewItem = () => {
    setCustomCrate([
      ...customCrate,
      {
        item_name: "",
        magnitude: "",
      },
    ]);
  };

  function handleRemoveCustomCrate(index) {
    const values = [...customCrate];
    values.splice(index, 1);
    setCustomCrate(values);
    setRemoved(true);
  }

  const handleCratechange = (index, event) => {
    const values = [...customCrate];
    values[index][event.target.name] = event.target.value;
    setCustomCrate(values);
  };

  useEffect(() => {
    if (customCrate[0]?.item_name !== "") {
      newMappingCrates(customCrate);
    }
  }, [customCrate, removed]);

  const newMappingCrates = (values) => {
    if (values.item_name !== "" && values.magnitude !== "") {
      const newValues = values?.map((customCrate, index) => ({
        [customCrate.item_name]: customCrate.magnitude,
      }));
      if (newValues.length > 0) {
        const newValueObj = newValues?.reduce((itm, idx) => {
          if (!idx[""] || !itm[""]) {
            return {
              ...itm,
              ...idx,
            };
          }
        });
        delete newValueObj[""];
        if (!removed) {
          handleArrangmentData(
            { ...arrangements?.crate?.items_to_crate, ...newValueObj },
            "items_to_crate",
            "crate",
            "material"
          );
          // console.log(newValueObj);
        } else {
          handleArrangmentData(
            { ...newValueObj },
            "items_to_crate",
            "crate",
            "material"
          );

          setRemoved(false);
        }
      }
    }
  };

  function handleDeliveryDate(date) {
    let newDate = dateToEpoch(date);

    handleArrangmentData(newDate, "delivery_date", "pre_materials_delivery");
  }
  function handleDeliveryDate2(date) {
    let newDate = dateToEpoch(date);

    handleArrangmentData(
      newDate,
      "delivery_date",
      "packing_day_materials_delivery"
    );
  }

  function handleRateData(value, type) {
    handleChange(
      "rate",
      produce(rate, (draft) => {
        draft[type] = value;
      })
    );
  }

  // validations

  function isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  function validateUserInfo() {
    setValidationFired(true);
    let validated = true;
    let userErrors = {};
    const fieldsToValidate = {
      family_name: user_info?.family_name,
      given_name: user_info?.given_name,
      phone: user_info?.phone,
      email: user_info?.email,
      address: user_info?.address,
    };
    Object.keys(fieldsToValidate)?.forEach((field, index) => {
      if (!fieldsToValidate[field]) {
        validated = false;
        userErrors[field] = `${startCase(field)} is Required!`;
      }
      if (field === "email" && !isEmail(fieldsToValidate?.email)) {
        validated = false;
        userErrors[field] = `${startCase(field)} is in valid!`;
      }
    });
    setErrors(userErrors);
    return validated;
  }

  function validateScheduleShipment() {
    setValidationFired(true);
    let validated = true;
    const localErrors = {
      ...initialErrors,
    };
    const fieldsToValidate = {
      from_address_street_lines: schedule_shipment?.from_address?.street_lines,
      to_address_street_lines: schedule_shipment?.to_address?.street_lines,
      moving_date: schedule_shipment?.moving_date,
      shipment_mode: shipment_details?.shipment_mode,
    };

    Object.keys(fieldsToValidate)?.forEach((field, index) => {
      if (!fieldsToValidate[field]) {
        validated = false;
        localErrors[field] = `Field is Required!`;
      }
    });
    setErrors(localErrors);
    return validated;
  }

  function validateScheduleDetails() {
    let validated = true;
    setIsValidationFired(true);
    const localErrors = {
      ...initialErrors,
    };
    let fieldsToValidate = {
      moving_type: shipment_details?.moving_type,
      item_description: shipment_details?.item_description,
    };
    if (shipment_details?.shipment_mode === "SEA") {
      fieldsToValidate.premove_survey = shipment_details?.premove_survey;
      fieldsToValidate.container_type = shipment_details?.container_type;
    }
    if (shipment_details?.need_packing === "") {
      validated = false;
      localErrors.need_packing = "Need Packing Field is Required!";
    }
    Object.keys(fieldsToValidate)?.forEach((field, index) => {
      if (fieldsToValidate[field] === "") {
        validated = false;
        localErrors[field] = `${startCase(field)} is Required!`;
      }
    });
    setErrors(localErrors);
    return validated;
  }

  function validateBox() {
    let validated = true;
    if (shipment_details.moving_type === "BOXES") {
      for (const box of shipment_details?.moversly_boxes) {
        const description = box?.custom ? "Custom" : box.description;
        if (box.number_of_such_boxes && !box.weight.magnitude) {
          validated = false;
          addToast(`Please add weight for ${description} box. `, {
            autoDismiss: true,
            appearance: "error",
          });
          return validated;
        } else if (!box.number_of_such_boxes && box.weight.magnitude) {
          validated = false;
          addToast(`Please add No. of Boxes for ${description} box. `, {
            autoDismiss: true,
            appearance: "error",
          });
          return validated;
        }
      }
    }

    if (shipment_details.moving_type === "LUGGAGE") {
      let indexOfBox = 1;
      for (const box of shipment_details?.moversly_boxes_luggage) {
        if (
          box.number_of_such_boxes ||
          box.weight.magnitude ||
          box.dimensions?.width ||
          box.dimensions?.height ||
          box.dimensions?.length
        ) {
          if (
            !(
              box.dimensions?.width &&
              box.dimensions?.height &&
              box.dimensions?.length
            )
          ) {
            validated = false;
            addToast(`Please add all Dimensions for box ${indexOfBox}. `, {
              autoDismiss: true,
              appearance: "error",
            });
          } else if (!box.weight?.magnitude) {
            validated = false;
            addToast(`Please add Weight for box ${indexOfBox}. `, {
              autoDismiss: true,
              appearance: "error",
            });
          } else if (!box.number_of_such_boxes) {
            validated = false;
            addToast(`Please add number of boxes for box ${indexOfBox}. `, {
              autoDismiss: true,
              appearance: "error",
            });
          }
          if (validated === false) {
            break;
          }
        }
        indexOfBox += 1;
      }
    }
    return validated;
  }

  const handleResetPrice = async () => {
    if (validateScheduleShipment && validateScheduleDetails && validateBox) {
      try {
        setIsLoading(true);
        let seaMoveTypeTitle = snakeCase(
          shipment_details?.moving_type?.toLowerCase()
        );
        seaMoveTypeTitle =
          seaMoveTypeTitle === "boxes" ? "moversly_boxes" : seaMoveTypeTitle;

        const sea_move_type = {
          moving_type: shipment_details?.moving_type,
          [seaMoveTypeTitle]: {
            description: shipment_details?.item_description,
          },
        };
        const selectedBoxes = shipment_details?.moversly_boxes.filter(
          (box) =>
            box.number_of_such_boxes !== "" && box.number_of_such_boxes !== "0"
        );
        const payload = {
          schedule_shipment: { ...shipment?.schedule_shipment },
          shipment_details: {
            shipment_mode: shipment_mode,
            [shipment_mode?.toLowerCase()]: {
              need_packing: shipment_details?.need_packing,
              move_type:
                shipment_mode === "SEA"
                  ? sea_move_type
                  : {
                      moving_type: shipment_details?.moving_type,
                      [shipment_details?.moving_type?.toLowerCase()]: {
                        moversly_boxes:
                          shipment_details?.moving_type === "LUGGAGE"
                            ? shipment_details?.moversly_boxes_luggage
                            : selectedBoxes,
                      },
                    },
              survey: {
                pre_move_survey_type:
                  shipment_mode === "SEA"
                    ? shipment_details?.premove_survey || ""
                    : "SELF",
                // pre_move_survey_type: "SELF",
                self_survey: {
                  container_type: shipment_details?.container_type,
                },
              },
            },
          },
        };
        const response = await Helper(
          API_ROUTES?.getResetPrice?.route,
          API_ROUTES?.getResetPrice?.method,
          payload
        );

        if (response?.error_code) {
          setPriceError(response?.message);
          setIsLoading(false);
        } else {
          setIsLoading(false);

          dispatch(handleShipmentState("rate", { ...response.price }));
        }
      } catch (error) {}
    }
  };

  const getOrdersById = async (id) => {
    const response = await Helper(`order/${id}`);
    console.log(response?.payload?.shipment_details?.sea);
    if (response?.error_code) {
    } else {
      // console.log(response);
      if (response?.id) {
        dispatch(handleShipmentState("order_id", response.id));
      }

      if (response?.payload?.shipment_details?.air) {
        let shipment_details_air = {
          shipment_mode: response?.payload?.shipment_details?.shipment_mode,
          moving_type:
            response?.payload?.shipment_details?.air?.move_type?.moving_type,
          need_packing: response?.payload?.shipment_details?.air?.need_packing,
          premove_survey:
            response?.payload?.shipment_details?.air?.survey
              ?.pre_move_survey_type,

          item_description: response?.payload?.item_description,
          moversly_boxes: response?.payload?.shipment_details?.air?.move_type
            ?.boxes?.moversly_boxes
            ? [
                ...response?.payload?.shipment_details?.air?.move_type?.boxes
                  ?.moversly_boxes,
              ]
            : [...shipment_details?.moversly_boxes],
          moversly_boxes_luggage: response?.payload?.shipment_details?.air
            ?.move_type?.luggage?.moversly_boxes
            ? [
                ...response?.payload?.shipment_details?.air?.move_type?.luggage
                  ?.moversly_boxes,
              ]
            : [...shipment_details?.moversly_boxes_luggage],
        };

        dispatch(
          handleShipmentState("shipment_details", {
            ...shipment_details_air,
          })
        );
      }

      if (response?.payload?.shipment_details?.road) {
        let shipment_details_road = {
          shipment_mode: response?.payload?.shipment_details?.shipment_mode,
          moving_type:
            response?.payload?.shipment_details?.road?.move_type?.moving_type,
          need_packing: response?.payload?.shipment_details?.road?.need_packing,

          survey: { ...response?.payload?.shipment_details?.road?.survey },
          moversly_boxes: [...shipment_details?.moversly_boxes],
          moversly_boxes_luggage: [...shipment_details?.moversly_boxes_luggage],
          item_description: response?.payload?.item_description,
          premove_survey:
            response?.shipment_details?.road?.survey?.pre_move_survey_type ===
            "NO"
              ? false
              : true,
        };
        dispatch(
          handleShipmentState("shipment_details", {
            ...shipment_details_road,
          })
        );
      }

      if (response?.payload?.shipment_details?.sea) {
        let shipment_details_sea = {
          shipment_mode: response?.payload?.shipment_details?.shipment_mode,
          moving_type:
            response?.payload?.shipment_details?.sea?.move_type?.moving_type,
          need_packing: response?.payload?.shipment_details?.sea?.need_packing,

          survey: { ...response?.payload?.shipment_details?.sea?.survey },
          moversly_boxes: [...shipment_details?.moversly_boxes],
          moversly_boxes_luggage: [...shipment_details?.moversly_boxes_luggage],
          // container_type:
          //   response?.payload?.shipment_details?.sea?.survey?.self_survey
          //     ?.container_type,
          shipment_type:
            response?.payload?.shipment_details?.sea?.move_type
              ?.sea_designation,
          item_description: response?.payload?.item_description,
          premove_survey:
            response?.shipment_details?.sea?.survey?.pre_move_survey_type ===
            "NO"
              ? false
              : true,
        };
        console.log(shipment_details_sea);
        dispatch(
          handleShipmentState("shipment_details", {
            ...shipment_details_sea,
          })
        );
      }

      if (response?.state) {
        dispatch(handleShipmentState("state", response?.state));
      }

      dispatch(
        handleShipmentState("sales_associate_id", response?.sales_associate_id)
      );
      dispatch(
        handleShipmentState(
          "operations_associate_id",
          response?.operations_associate_id
        )
      );

      dispatch(
        handleShipmentState(
          "customer_order_state",
          response?.customer_order_state
        )
      );

      if (response?.rate) {
        dispatch(handleShipmentState("rate", { ...response?.rate }));
      }
      if (response?.discounts) {
        dispatch(handleShipmentState("discounts", [...response?.discounts]));
      }
      if (response?.payload?.user_info) {
        dispatch(
          handleShipmentState("user_info", { ...response?.payload?.user_info })
        );
      }
      if (response?.payment_transaction) {
        dispatch(
          handleShipmentState("payment_transaction", {
            ...response?.payment_transaction,
          })
        );
      }
      if (response?.payload?.schedule_shipment) {
        dispatch(
          handleShipmentState("schedule_shipment", {
            ...response?.payload?.schedule_shipment,
          })
        );
      }
      if (response?.payload?.arrangements) {
        dispatch(
          handleShipmentState("arrangements", {
            ...response?.payload?.arrangements,
          })
        );
      }
      dispatch(handleShipmentState("created_date", response?.created_date));
      dispatch(handleShipmentState("user_id", response?.user_id));
    }
  };

  const getAssignedDestinationAgent = async (id) => {
    try {
      const response = await Helper(
        `partner/destinationAgent/all-destination-agent-for-order/${id}`,
        "POST"
      );
      if (response?.error_code) {
      } else {
        // console.log(response, ":: destination agent response");
        // to be addressed later on redux
        //dispatch(handleShipmentState("packer_id", response.packer_id));
      }
    } catch (error) {}
  };

  const getAssignedPacker = async (id) => {
    try {
      const response = await Helper(`partner/get-packer?orderId=${id}`);
      if (response?.error_code) {
      } else {
        dispatch(handleShipmentState("packer_id", response.packer_id));
      }
    } catch (error) {}
  };

  async function createComments() {
    try {
      setIsLoading(true);
      const payload = {
        order_id: comments?.order_id,
        comment: comments?.comment_input,
      };
      const response = await Helper(
        API_ROUTES?.createComments?.route,
        API_ROUTES?.createComments?.method,
        payload
      );

      if (response?.error_code) {
        setApiError(response?.message);
        setIsLoading(false);
      } else {
        // console.log(response);
        setIsLoading(false);
      }
    } catch (error) {}
  }

  // const getAllComment = async (id) => {
  //   try {
  //     const response = await Helper(`order/get-comments/${id}`, "POST");
  //     if (response.error_code) {
  //       setApiError(response.meesage);
  //     } else {
  //       // console.log(response);
  //       dispatch(getAllComments([...response]));
  //     }
  //   } catch (error) {}
  // };

  useEffect(() => {
    if (itemId) {
      getOrdersById(itemId);
      getAssignedPacker(itemId);
      getAssignedDestinationAgent(itemId);
      // getAllComment(itemId);
    }
  }, [itemId]);

  const handleAssign = async (e) => {
    const { name, value } = e.target;

    if (value !== "" && name === "sales") {
      setAssignedSales(value);
      try {
        const response = await Helper(
          `partner/assign/sales/${value}/${shipment?.order_id}`,
          "POST"
        );

        if (response.error_code) {
          setApiError(response.message);
        } else {
          addToast(response.message, { appearance: "success" });
          // to be fixed to dynamic values in case of operation associate
          dispatch(handleShipmentState("sales_associate_id", value));
        }
      } catch (error) {}
    } else if (value !== "" && name === "operations") {
      setAssignedOperations(value);
      try {
        const response = await Helper(
          `partner/assign/operations/${value}/${shipment?.order_id}`,
          "POST"
        );

        if (response.error_code) {
          setApiError(response.message);
        } else {
          addToast(response.message, { appearance: "success" });
          // to be fixed to dynamic values in case of operation associate
          dispatch(handleShipmentState("operations_associate_id", value));
        }
      } catch (error) {}
    } else {
      setAssignedPacker(value);
      try {
        const response = await Helper(
          `partner/assign/order/${shipment?.order_id}/packer/${value}`,
          "POST"
        );
        if (response.err_code) {
          setApiError(response.message);
        } else {
          addToast(response.message, { appearance: "success" });
          // to be fixed to dynamic values in case of operation associate
          dispatch(handleShipmentState("packer_id", value));
        }
      } catch (error) {}
    }
  };

  const handleSelectedAgent = async (event) => {
    const n = event.target.value;
    const {
      target: { value },
    } = event;

    setSelectedAgent(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    setTimeout(async () => {
      try {
        const payload = {
          order_id: shipment?.order_id,
          destination_agent_ids: selectedAgent,
          status: "NEW_QUOTE_REQUEST",
        };
        // console.log(payload);
        const response = await Helper(
          `partner/assign-order-destination-agent`,
          "POST",
          payload
        );

        if (response?.error_code) {
          setApiError(response.message);
        } else {
          addToast(response.message, { appearance: "success" });
        }
      } catch (error) {}
    }, 3000);
  };

  async function handleCreate() {
    if (
      validateUserInfo() &&
      validateScheduleShipment() &&
      validateScheduleDetails() &&
      validateBox()
    ) {
      try {
        setIsLoading(true);
        let seaMoveTypeTitle = snakeCase(
          shipment_details?.moving_type?.toLowerCase()
        );
        seaMoveTypeTitle =
          seaMoveTypeTitle === "boxes" ? "moversly_boxes" : seaMoveTypeTitle;

        const sea_move_type = {
          moving_type: shipment_details?.moving_type,
          [seaMoveTypeTitle]: {
            description: shipment_details?.item_description,
          },
          sea_designation: shipment?.shipment_details?.shipment_type,
        };
        const selectedBoxes = shipment_details?.moversly_boxes.filter(
          (box) =>
            box.number_of_such_boxes !== "" && box.number_of_such_boxes !== "0"
        );
        const payload = {
          payload: {
            schedule_shipment: {
              ...shipment?.schedule_shipment,
              moving_date: shipment?.schedule_shipment?.moving_date,
            },
            shipment_details: {
              shipment_mode: shipment_mode,
              [shipment_mode?.toLowerCase()]: {
                need_packing: shipment_details?.need_packing,
                move_type:
                  shipment_mode === "SEA"
                    ? sea_move_type
                    : {
                        moving_type: shipment_details?.moving_type,
                        [shipment_details?.moving_type?.toLowerCase()]: {
                          moversly_boxes:
                            shipment_details?.moving_type === "LUGGAGE"
                              ? shipment_details?.moversly_boxes_luggage
                              : selectedBoxes,
                        },
                      },
                survey: {
                  pre_move_survey_type:
                    shipment_mode === "SEA"
                      ? shipment_details?.pre_move_survey_type ||
                        shipment_details?.survey?.pre_move_survey_type
                      : "NO",
                  // pre_move_survey_type: "SELF",
                  [surveyType]: {
                    container_type: shipment_details?.container_type,
                  },
                },
              },
            },
            user_info: { ...user_info },
            item_description: shipment_details?.item_description,
          },
          rate: { ...rate },
        };

        // console.log(payload, ":: payload");

        const response = await Helper(
          API_ROUTES?.createUpdate?.route,
          API_ROUTES?.createUpdate?.method,
          payload
        );

        if (response?.error_code) {
          setApiError(response?.message);
          setIsLoading(false);
        } else {
          setIsLoading(false);

          dispatch(resetShipmentState());
          onClose();
        }
      } catch (error) {}
    }
  }

  const handleUpdate = async () => {
    let seaMoveTypeTitle = snakeCase(
      shipment_details?.moving_type?.toLowerCase()
    );
    seaMoveTypeTitle =
      seaMoveTypeTitle === "boxes" ? "moversly_boxes" : seaMoveTypeTitle;
    const sea_move_type = {
      moving_type: shipment_details?.moving_type,
      [seaMoveTypeTitle]: {
        description: shipment_details?.item_description,
      },
      sea_designation: shipment?.shipment_details?.shipment_type,
    };
    const selectedBoxes = shipment_details?.moversly_boxes.filter(
      (box) =>
        box.number_of_such_boxes !== "" && box.number_of_such_boxes !== "0"
    );
    const payload = {
      id: shipment?.order_id,
      payload: {
        schedule_shipment: {
          ...shipment?.schedule_shipment,
          moving_date: shipment?.schedule_shipment?.moving_date,
        },
        shipment_details: {
          shipment_mode: shipment_mode,
          [shipment_mode?.toLowerCase()]: {
            need_packing: shipment_details?.need_packing,
            move_type:
              shipment_mode === "SEA"
                ? sea_move_type
                : {
                    moving_type: shipment_details?.moving_type,
                    [shipment_details?.moving_type?.toLowerCase()]: {
                      moversly_boxes:
                        shipment_details?.moving_type === "LUGGAGE"
                          ? shipment_details?.moversly_boxes_luggage
                          : selectedBoxes,
                    },
                  },
            survey: {
              pre_move_survey_type:
                shipment_mode === "SEA"
                  ? shipment_details?.pre_move_survey_type ||
                    shipment_details?.survey?.pre_move_survey_type
                  : "NO",
              // pre_move_survey_type: "SELF",
              [surveyType]: {
                container_type:
                  shipment_details?.container_type ||
                  shipment_details?.survey?.[surveyType]?.container_type,
                items:
                  shipment_mode === "AIR"
                    ? null
                    : {
                        ...shipment_details?.survey[surveyType]?.items,
                      },
                volume_unit:
                  shipment_mode === "AIR"
                    ? "CFT"
                    : shipment_details?.survey[surveyType]?.volume_unit,
              },
            },
          },
        },
        user_info: { ...user_info },
        item_description: shipment_details?.item_description,
        arrangements: { ...arrangements },
      },
      rate: { ...rate },
      state: shipment?.state,
      discounts: [...shipment?.discounts],
      created_date: shipment?.created_date,
      sales_associate_id: shipment?.sales_associate_id,
      operations_associate_id: shipment?.operations_associate_id,
      user_id: shipment?.user_id,
      payment_transaction: { ...shipment?.payment_transaction },
    };

    const access_token = await localStorage.getItem(storageToken);

    if (access_token) {
      if (
        validateUserInfo() &&
        validateScheduleShipment() &&
        validateScheduleDetails() &&
        validateBox()
      ) {
        try {
          setIsLoading(true);
          const response = await Helper(
            API_ROUTES.createUpdate.route,
            API_ROUTES.createUpdate.method,
            payload
          );
          if (response.error_code) {
            setApiError(response.message);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            addToast("Lead updated successfully", { appearance: "success" });

            dispatch(resetShipmentState());
            onClose();
          }
        } catch (error) {
        } finally {
          // reloadPage();
        }
      }
    }
  };
  const [contype, setConType] = useState("");
  useEffect(() => {
    let selectedSurvey =
      shipment_details?.pre_move_survey_type ||
      shipment_details?.survey?.pre_move_survey_type;
    let surveyType = "";

    if (selectedSurvey === undefined) {
      surveyType = "no_survey";
    }

    if (selectedSurvey === "SELF") {
      surveyType = "self_survey";
    }
    if (selectedSurvey === "COMPANY") {
      surveyType = "company_survey";
    }

    if (selectedSurvey === "NO") {
      surveyType = "no_survey";
    }

    dispatch(handleShipmentState("surveyType", surveyType));

    let containerType = shipment_details?.survey?.[surveyType]?.container_type;
    setConType(containerType);
    // handleLevel1Data(containerType, "container_type");
  }, [
    surveyType,
    shipment_details?.pre_move_survey_type,
    shipment_details?.survey?.pre_move_survey_type,
  ]);

  console.log(shipment_details?.shipment_mode);

  useEffect(() => {
    handleLevel1Data(contype, "container_type");
  }, [contype]);

  useEffect(() => {
    setTimeout(() => {
      setPriceError("");
    }, 15000);
  }, [priceError]);

  return (
    <Dialog
      maxWidth="lg"
      onClose={() => {
        onClose();
      }}
      open={open}
      onBackdropClick="false"
    >
      <DialogTitle
        sx={{
          background: "whitesmoke",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 20,
          fontWeight: 700,
          padding: 1,
          margin: 0,
          width: "100%",
        }}
      >
        <div className={classes.header}>
          <Typography
            align="center"
            className={classes.title}
            gutterBottom
            variant="h6"
          >
            {!itemId
              ? "Book New shipment"
              : `View Lead With Request Id :: ${shipment?.order_id}`}
          </Typography>
        </div>
        <IconButton
          onClick={() => {
            onClose();
            setApiError("");
          }}
        >
          <Close
            style={{
              color: "red",
            }}
          />
        </IconButton>
      </DialogTitle>

      <DialogContent {...rest} className={clsx(classes.root, className)}>
        <div className={classes.content}>
          <Typography gutterBottom variant="h6">
            Customer Details
          </Typography>
          <Divider className={classes.divider1} color={"#4E3883"}></Divider>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className={classes.inputContainer}>
                <Typography gutterBottom variant="body2">
                  Given Name
                </Typography>
                <TextField
                  inputProps={{ tabIndex: "1" }}
                  className={classes.input}
                  fullWidth
                  placeholder="John"
                  value={user_info?.given_name}
                  error={errors?.given_name}
                  helperText={errors?.given_name}
                />
              </div>
              <div className={classes.inputContainer}>
                <Typography gutterBottom variant="body2">
                  Email
                </Typography>
                <TextField
                  inputProps={{ tabIndex: "3" }}
                  className={classes.input}
                  fullWidth
                  // variant="standard"

                  // label="Email"
                  placeholder="Enter a valid customer email"
                  value={user_info?.email}
                  error={errors?.email}
                  helperText={errors.email}
                />
              </div>

              <div className={classes.inputContainer}>
                <Typography gutterBottom variant="body2">
                  Moving From
                </Typography>
                <TextField
                  inputProps={{ tabIndex: "5" }}
                  className={classes.input}
                  name=""
                  placeholder=" e.g. 84 Princess street"
                  value={schedule_shipment?.from_address?.street_lines}
                  helperText={errors?.from_address_street_lines}
                  error={errors?.from_address_street_lines}
                  //   onKeyPress={() => {
                  //     handleOpenLocationModal(0);
                  //     setAddressType(0);
                  //   }}
                />
              </div>

              {/* {itemId && (
                <div className={classes.inputContainer}>
                  <Typography gutterBottom variant="body2">
                    Order State
                  </Typography>
                  <Select
                    inputProps={{ tabIndex: "7" }}
                    value={shipment?.customer_order_state}
                    onChange={(e) => {
                      dispatch(
                        handleShipmentState(
                          "customer_order_state",
                          e.target.value
                        )
                      );
                    }}
                    label="Customer State"
                    className={classes.input}
                  >
                    {CUSTOMER_STATE?.map((item, index) => (
                      <MenuItem
                        style={{ textTransform: "capitalize" }}
                        value={item.value}
                        key={index}
                      >
                        {startCase(item.label)}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )} */}
              <div className={classes.inputContainer}>
                <Typography gutterBottom variant="body2">
                  Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    type="date"
                    className={classes.input}
                    inputStyle={{
                      width: 350,
                    }}
                    inputProps={{
                      min: new Date().toISOString().split("T")[0],
                    }}
                    disabled
                    onChange={(newValue) =>
                      handleShipmentDate(new Date(newValue))
                    }
                    error={errors?.moving_date}
                    value={epochToDateConverter(schedule_shipment?.moving_date)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        variant="standard"
                        className={classes.input}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className={classes.inputContainer}>
                <Typography gutterBottom variant="body2">
                  Shipment Mode
                </Typography>
                <Select
                  inputProps={{ tabIndex: "10" }}
                  value={shipment_details?.shipment_mode}
                  //   onChange={(e) => {
                  //     handleShipmentMode(e.target.value);
                  //   }}
                  disabled
                  label="Shipment Mode"
                  className={classes.input}
                >
                  <MenuItem value="SEA">SEA</MenuItem>
                  <MenuItem value="AIR">AIR</MenuItem>
                  <MenuItem value="ROAD">ROAD</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.inputContainer}>
                <Typography gutterBottom variant="body2">
                  Family Name
                </Typography>
                <TextField
                  inputProps={{ tabIndex: "2" }}
                  className={classes.input}
                  fullWidth
                  placeholder="Paul"
                  value={user_info?.family_name}
                  error={errors?.family_name}
                  helperText={errors.family_name}
                  returnKeyType="next"
                />
              </div>
              <div className={classes.inputContainer}>
                <Typography gutterBottom variant="body2">
                  Customer's Address
                </Typography>
                <TextField
                  inputProps={{ tabIndex: "2" }}
                  className={classes.input}
                  fullWidth
                  placeholder="eg 82 Prince Street "
                  value={user_info?.address}
                  error={errors?.address}
                  helperText={errors.address}
                  returnKeyType="next"
                />
              </div>

              <div className={classes.inputContainer}>
                <Typography gutterBottom variant="body2">
                  Moving To
                </Typography>
                <TextField
                  inputProps={{ tabIndex: "6" }}
                  className={classes.input}
                  // disableUnderline
                  placeholder=" e.g. 84 Princess street"
                  value={schedule_shipment?.to_address?.street_lines}
                  //   onKeyPress={() => {
                  //     handleOpenLocationModal(1);
                  //     setAddressType(1);
                  //   }}
                  helperText={errors?.to_address_street_lines}
                  error={errors?.to_address_street_lines}
                />
              </div>

              <div className={classes.inputContainer}>
                {/* <Typography gutterBottom variant="body2">
                  Phone
                </Typography> */}
                <PhoneInput
                  inputProps={{ tabIndex: "4" }}
                  className={classes.input}
                  fullWidth
                  containerStyle={{
                    outline: "none",
                    borderBottom: "2px solid grey",
                    width: 350,
                  }}
                  inputStyle={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    "&:focusWithin": {
                      outline: "none",
                      border: "none",
                    },
                  }}
                  buttonStyle={{
                    border: "none",
                    background: "transparent",
                  }}
                  label="Phone"
                  placeholder="e.g. +91 12345 67890"
                  id="phone"
                  prefix="+"
                  name="phone"
                  country={"sg"}
                  value={user_info?.phone}
                  error={errors?.phone}
                  helperText={errors?.phone}
                  disabled
                />
                {errors.phone && (
                  <p style={{ color: "red", fontSize: 14 }}>{errors.phone}</p>
                )}
              </div>
            </Grid>
          </Grid>
          <Divider className={classes.divider} color={"#4E3883"}></Divider>
          <Typography gutterBottom variant="h6">
            Leads Details
          </Typography>
          {/* name grid ends  */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <div
                className={classes.inputContainer}
                style={{
                  display: "flex",

                  flexDirection: "column",
                }}
              >
                <Typography gutterBottom variant="body2">
                  Moving Type
                </Typography>
                <Select
                  inputProps={{ tabIndex: "17" }}
                  value={shipment_details?.moving_type}
                  name="moving_type"
                  disabled
                  className={classes.input}
                  error={errors.moving_type}
                  helperText={errors.moving_type}
                >
                  {movingTypeItem.map((item) => (
                    <MenuItem id="moving_type" value={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="caption" style={{ color: "red" }}>
                  {" "}
                  {errors.moving_type}
                </Typography>
              </div>

              <div className={classes.inputContainer}>
                {shipment_details?.shipment_mode === "AIR" ||
                shipment_details?.shipment_mode === "ROAD" ? (
                  shipment_details?.moving_type === "LUGGAGE" ? (
                    <LuggageTable label="Please enter the luggage details" />
                  ) : shipment_details?.moving_type === "BOXES" ? (
                    <BoxTable label="Please select your box details" />
                  ) : null
                ) : null}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.inputContainer}>
                <Typography gutterBottom variant="body2">
                  Item Description
                </Typography>
                <TextField
                  // autoFocus
                  inputProps={{ tabindex: "18" }}
                  className={classes.input}
                  placeholder="e.g. Handle with care"
                  value={shipment_details?.item_description}
                  ext={errors?.item_description}
                  error={errors?.item_description}
                  disabled
                />
              </div>
            </Grid>
          </Grid>
          {/* leads details end here */}
        </div>

        <div className={classes.actions}>
          <Button
            className={classes.applyButtonUpdate}
            onClick={() => {
              //   handleUpdate();
              onClose();

              // setApiError("Comment Must Not be Empty");
            }}
            variant="contained"
            tabIndex="19"
          >
            {isLoading ? (
              <CircularProgress size={20} color={"green"} />
            ) : (
              "Close"
            )}
          </Button>
        </div>
      </DialogContent>
      <LocationModal
        onSubmit={handleCloseLocationModal}
        onClose={handleCloseLocationModal}
        open={locationModal}
        addressType={addressType}
        setLocationModal={setLocationModal}
        locationModal={locationModal}
      />
    </Dialog>
  );
};
export default BookerView;
