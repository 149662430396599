// hooks
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import Radio from "../../common/components/Radio";
import Select from "../../common/components/Select";
import Button from "../../common/components/Button";
import LuggageTable from "./components/LuggageTable";
import BoxTable from "./components/BoxTable";
import TextInput from '../../common/components/TextInput';

// constants
import { API_ROUTES, COMPONENT_DATA, CONTAINER_TYPE_OPTIONS, YES_NO_OPTIONS } from '../../common/consts/config';
import { handleShipmentState } from "../../redux/actions/shipmentActions";
import produce from "immer";
import { snakeCase, startCase } from "lodash";
import { useEffect } from "react";
import Helper from "../../common/consts/helper";
import { useToasts } from 'react-toast-notifications';

const initialErrors = COMPONENT_DATA?.bookShipment?.formTwo?.initialErrors || {};

export default function ShipmentDetails(props) {

    const {
        setActiveStep,
        activeStep,
        handleDB
    } = props;

    const [errors, setErrors] = useState({
        ...initialErrors
    });
    const [isValidationFired, setIsValidationFired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const isLoggedIn = useSelector(state => state?.auth?.isLoggedIn || false);

    const seaMovingType = COMPONENT_DATA?.bookShipment?.formTwo?.seaMovingType || [];
    const roadMovingType = COMPONENT_DATA?.bookShipment?.formTwo?.roadMovingType || [];
    const airMovingType = COMPONENT_DATA?.bookShipment?.formTwo?.airMovingType || [];

    const shipment = useSelector(state => state.shipment);
    const shipment_details = shipment?.shipment_details;

    const shipment_mode = shipment_details?.shipment_mode || '';

    useEffect(() => {
        if (isValidationFired) {
            validate();
        }
    }, [isValidationFired, shipment_details]);

    return (
        <>
            <Select
                label="Moving Type"
                items={shipment_mode === 'SEA' ? seaMovingType : shipment_mode === 'AIR' ? airMovingType : roadMovingType}
                value={shipment_details?.moving_type}
                id="moving_type"
                onChange={handleLevel1Data}
                error={errors.moving_type}
            />
            <div className="col-12 fs-09">Do you need packing?</div>
            <div className="row mt-3 gx-4">
                {
                    YES_NO_OPTIONS?.map((mode, index) => {
                        return (
                            <Radio
                                key={index}
                                title={mode?.label}
                                cols={3}
                                active={shipment_details?.need_packing === mode?.value}
                                onClick={() => {
                                    handleLevel1Data(mode?.value, 'need_packing')
                                }}
                            />
                        )
                    })
                }
                {
                    shipment_mode === 'SEA' ? (
                        <>
                            <div className="col-12 fs-09 mt-3">Do you premove survey?</div>
                            <div className={`row mt-3 gx-4 ${errors.premove_survey ? 'is-invalid' : ''}`}>
                                {
                                    YES_NO_OPTIONS?.map((mode, index) => {
                                        return (
                                            <Radio
                                                key={index}
                                                title={mode?.label}
                                                cols={3}
                                                active={shipment_details?.premove_survey === mode?.value}
                                                onClick={() => {
                                                    handleLevel1Data(mode?.value, 'premove_survey')
                                                }}
                                            />
                                        )
                                    })
                                }
                            </div>
                            <div className="invalid-feedback">
                                {errors.premove_survey}
                            </div>
                            <div className="col-12 fs-09 mt-3">Container Type</div>
                            <div className={`row mt-3 gx-4 ${errors.container_type ? 'is-invalid' : ''}`}>
                                {
                                    CONTAINER_TYPE_OPTIONS?.map((mode, index) => {
                                        return (
                                            <Radio
                                                key={index}
                                                title={mode?.label}
                                                cols={3}
                                                active={shipment_details?.container_type === mode?.value}
                                                onClick={() => {
                                                    handleLevel1Data(mode?.value, 'container_type')
                                                }}
                                            />
                                        )
                                    })
                                }
                            </div>
                            <div className="invalid-feedback">
                                {errors.container_type}
                            </div>
                        </>
                    ) : null
                }
                {
                    shipment_mode === 'AIR' || shipment_mode === 'ROAD' ? (
                        shipment_details?.moving_type === 'LUGGAGE' ? (
                            <LuggageTable
                                label="Please enter the luggage details"
                            />
                        ) : shipment_details?.moving_type === 'BOXES' ? (
                            <BoxTable
                                label="Please select your box details"
                            />
                        ) : null
                    ) : null
                }
            </div>
            <div className="row mt-3">
                <TextInput
                    label="Item Description"
                    id="item_description"
                    placeholder="e.g. Handle with care"
                    value={shipment_details?.item_description}
                    onChange={handleLevel1Data}
                    error={errors?.item_description}
                />
            </div>

            <div className="col-12 mt-5 d-flex justify-content-between">
                <Button
                    type="light"
                    title="Previous"
                    containerClassName="prev-button"
                    onClick={() => {
                        setActiveStep(activeStep - 1);
                    }}
                />
                <Button
                    title="Next"
                    containerClassName="prev-button"
                    onClick={submit}
                    isLoading={isLoading}
                />
            </div>

        </>
    )

    async function submit() {
        // let validated = validate();
        let validated = validate();
        if (validated) {
            let boxValidated = validateBox();
            if (boxValidated) {
                if (isLoggedIn) {
                    setIsLoading(true);
                    await handleDB();
                    setIsLoading(false);
                }
                else {
                    setActiveStep(activeStep + 1);
                }
            }
        }
        // if (validated) {
        //     if (isLoggedIn) {
        //         setIsLoading(true);
        //         await handleDB();
        //         setIsLoading(false);
        //     }
        //     else {
        //         setActiveStep(activeStep + 1);
        //     }
        // }
    }

    function validate() {
        let validated = true;
        setIsValidationFired(true);
        const localErrors = {
            ...initialErrors
        }
        let fieldsToValidate = {
            moving_type: shipment_details?.moving_type,
            item_description: shipment_details?.item_description
        };
        if (shipment_mode === 'SEA') {
            fieldsToValidate.premove_survey = shipment_details?.premove_survey;
            fieldsToValidate.container_type = shipment_details?.container_type;
        }
        if (shipment_details?.need_packing === '') {
            validated = false;
            localErrors.need_packing = "Need Packing Field is Required!"
        }
        Object.keys(fieldsToValidate)?.forEach((field, index) => {
            if (fieldsToValidate[field] === '') {
                validated = false;
                localErrors[field] = `${startCase(field)} is Required!`;
            }
        });
        setErrors(localErrors);
        return validated;
    }

    function validateBox() {
        let validated = true;
        if (shipment_details.moving_type === 'BOXES') {
            for (const box of shipment_details?.moversly_boxes) {
                const description = box?.custom ? 'Custom' : box.description;
                if (box.number_of_such_boxes && !box.weight.magnitude) {
                    validated = false;
                    addToast(`Please add weight for ${description} box. `, {
                        autoDismiss: true,
                        appearance: 'error',
                    });
                    return validated;
                }
                else if (!box.number_of_such_boxes && box.weight.magnitude) {
                    validated = false;
                    addToast(`Please add No. of Boxes for ${description} box. `, {
                        autoDismiss: true,
                        appearance: 'error',
                    });
                    return validated;
                }
            }
        }

        if (shipment_details.moving_type === 'LUGGAGE') {
            let indexOfBox = 1;
            for (const box of shipment_details?.moversly_boxes_luggage) {
                if (
                    box.number_of_such_boxes || 
                    box.weight.magnitude ||
                    box.dimensions?.width || 
                    box.dimensions?.height || 
                    box.dimensions?.length
                ) {
                    if (!(box.dimensions?.width && box.dimensions?.height && box.dimensions?.length)) {
                        validated = false;
                        addToast(`Please add all Dimensions for box ${indexOfBox}. `, {
                            autoDismiss: true,
                            appearance: 'error',
                        });
                    }
                    else if (!box.weight?.magnitude) {
                        validated = false;
                        addToast(`Please add Weight for box ${indexOfBox}. `, {
                            autoDismiss: true,
                            appearance: 'error',
                        });
                    }
                    else if (!box.number_of_such_boxes) {
                        validated = false;
                        addToast(`Please add number of boxes for box ${indexOfBox}. `, {
                            autoDismiss: true,
                            appearance: 'error',
                        });
                    }
                    if (validated === false) {
                        break;
                    }
                }
                indexOfBox += 1;
            }
        }
        return validated;

    }

    function handleLevel1Data(value, type) {
        handleChange(
            'shipment_details',
            produce(shipment_details, draft => {
                draft[type] = value;
            })
        );
    }

    function handleChange(name, value) {
        dispatch(handleShipmentState(name, value));
    }
}