export default function LocationListItem(props) {

    const {
        location,
        onClick
    } = props;

    return (
        <div
            className="location-item row d-flex align-items-center"
            onClick={() => {
                if(onClick){
                    onClick();
                }
            }}
        >
            <i className="col-1 bi bi-pin-map-fill"></i>
            <span className="col-10">{location.description || ''}</span>
        </div>
    )
}