import { PersonAdd, Settings } from "@material-ui/icons";
import { Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { handleAuthState } from "../../../redux/actions/authActions";
import { resetShipmentState } from "../../../redux/actions/shipmentActions";
import { storageToken } from "../../../common/consts/browserVariables";
import { confirmAlert } from "react-confirm-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#8276F4",
    height: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
  },
}));

const OrderHeader = (props) => {
  const { order_id } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((state) => state?.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function logout() {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure you want to logout?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            localStorage.removeItem(storageToken);
            dispatch(handleAuthState("isLoggedIn", false));
            dispatch(resetShipmentState());
            history.push("/");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  return (
    <Box
      className={classes.root}
      // sx={{
      //   background: "#8276F4",
      //   height: 300,
      //   display: "flex",
      //   flexDirection: "column",
      //   justifyContent: "space-around",
      // }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "space-between",
            width: "80%",
            margin: "0px auto 0px auto",
          }}
        >
          <img src="/assets/logo/moversly-logo-w.svg" width="100px" />
          {/* <Typography sx={{ minWidth: 100 }}>Logo</Typography> */}

          {user?.email !== "" && (
            <Tooltip title="Account Menu">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MenuIcon fontSize="large" />
                {/* <Avatar sx={{ width: 50, height: 50 }}>M</Avatar> */}
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem component={Link} to="/profile">
            <Avatar /> Profile
          </MenuItem>
          <MenuItem component={Link} to="/orders">
            <Avatar /> My Orders
          </MenuItem>
          <Divider />
          <MenuItem component={Link} to="/orders/track">
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Track order
          </MenuItem>

          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
      <Box
        sx={{
          display: "flex",
          // background: "red",
          justifyContent: "space-between",
          width: "80%",
          margin: "0px auto 35px auto",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "#fff",
            fontSize: 30,
          }}
        >
          Hello {user?.given_name}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: "#fff",
          }}
        >
          {order_id}
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderHeader;
