import Button from "../../../common/components/Button";
import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';
import { moversly_boxes_luggage as empty_moversly_boxes_luggage } from "../../../common/consts/emptyStates";
import { handleShipmentState } from '../../../redux/actions/shipmentActions';
import { sum } from 'lodash';
import { makeStyles } from "@material-ui/core/styles";
import { Input } from '@material-ui/core';

import { IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
    luggage_table_header:{
        backgroundColor: "#eeeeee88",
    },
    table_input:{
display:"flex",
// background:"red",
maxWidth:350,

    },
    luggage_input:{
        height: 30,
//   marginBottom: 0 !important,
//   borderBottom: "2px solid #4834b255",
//   borderTop: none !important,
//   borderLeft: none !important,
//   borderRight: none !important,
  width: "20%",
  marginLeft: "5%",
  textAlign: "center",
    }
}));

export default function LuggageTable(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    // const role = useSelector((state) => state?.user?.role["cognito:groups"]);
    const Booker = true

    const {
        label = "Label",
        labelClassName,
        containerClassName,
        disabled=false
    } = props;

    const shipment_details = useSelector(state => state.shipment?.shipment_details);
    const moversly_boxes_luggage = shipment_details?.moversly_boxes_luggage || [];
    const totalWeight = sum(moversly_boxes_luggage?.map(ele => +ele.number_of_such_boxes * (+ele?.weight?.magnitude)));
    const totalVolume = sum(moversly_boxes_luggage?.map(ele => {
        return (
            (+ele.number_of_such_boxes > 0 || +ele.weight.magnitude)
                ? (+ele.number_of_such_boxes * (+(+ele?.dimensions.length * +ele?.dimensions?.width * +ele?.dimensions?.height)) / 5000)
                : 1
        )
    }))?.toFixed(1);

    return (
        <div className={`col-12 mb-3 mt-2 ${containerClassName}`}>
            <div className={`col-12 fs-09 py-2 ${labelClassName}`}>{label}</div>
            <div className={`col-12`}>
                <table className="w-100">
                    <tr className={classes.luggage_table_header}>
                        <th>S/N
                            <div className="fw-light fs-09 invisible">INV</div>
                        </th>
                        <th className="fs-09 p-2 py-3">Dimensions (cm)
                            <div className="fw-light fs-09">Width/Breadth/Height</div>
                        </th>
                        <th className="fs-09 p-2 py-3">Weight
                            <div className="fw-light fs-09">(KGs)</div>
                        </th>
                        <th className="fs-09 p-2 py-3">Qty
                            <div className="fw-light fs-09 invisible">(KGs)</div>
                        </th>
                        <th className="fs-09 p-2 py-3">Options
                            <div className="fw-light fs-09 invisible">INV</div>
                        </th>
                    </tr>
                    {
                        moversly_boxes_luggage?.map((luggageItem, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <div className={classes.table_input}>
                                            <Input
                                                className={classes.luggage_input}
                                                placeholder="0"
                                                value={luggageItem?.dimensions?.width || ''}
                                                onChange={(e) => {
                                                    handleValueChange(e.target.value, index, 'dimensions', 'width')
                                                }}
                                                disabled={disabled}
                                            />
                                            <Input
                                                className={classes.luggage_input}
                                                placeholder="0"
                                                value={luggageItem?.dimensions?.length || ''}
                                                onChange={(e) => {
                                                    handleValueChange(e.target.value, index, 'dimensions', 'length')
                                                }}
                                                disabled={disabled}
                                            />
                                            <Input
                                                className={classes.luggage_input}
                                                placeholder="0"
                                                value={luggageItem?.dimensions?.height || ''}
                                                onChange={(e) => {
                                                    handleValueChange(e.target.value, index, 'dimensions', 'height')
                                                }}
                                                disabled={disabled}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <Input
                                            className={classes.luggage_input}
                                            placeholder="0"
                                            style={{ width: '80%' }}
                                            value={luggageItem?.weight?.magnitude || ''}
                                            onChange={(e) => {
                                                handleValueChange(e.target.value, index, 'weight', 'magnitude')
                                            }}
                                            disabled={disabled}
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            className={classes.luggage_input}
                                            placeholder="0"
                                            style={{ width: '80%' }}
                                            value={luggageItem?.number_of_such_boxes || ''}
                                            onChange={(e) => {
                                                handleValueChange(e.target.value, index, 'number_of_such_boxes', null);
                                            }}
                                            disabled={disabled}
                                        />
                                    </td>
                                    <td className="d-flex justify-content-center  align-items-center">
                                  {!Booker &&  <IconButton
                        onClick={() => {
                          deleteDimension(index);
                        }}
                      >
                        <Delete
                          size={100}
                          className="cursor-pointer fs-10 text-danger bi bi-x my-3 "
                        />
                      </IconButton>}

                                       
                                    </td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
          {!Booker &&  <div className="col-12 mt-3 text-end">
                {/* <Button
                    title="+"
                    containerClassName="luggage-table-add"
                    onClick={addDimension}
                /> */}
                 <IconButton style={{
          backgroundColor:"lightgreen"
        }}   onClick={addDimension} >
          <Add/>
        </IconButton>
            </div>}
            <div className="row mt-3 luggage-table-details">
                <div className="col-4">
                    <div className="fw-bold">Total</div>
                    <div className="fw-bold">Weight</div>
                </div>
                <div className="col-4">
                    <div className="fw-bold text-secondary">
                        (KGs):
                    </div>
                    <div className="fw-bold text-secondary">
                        (Volumetric):
                    </div>
                </div>
                <div className="col-4 text-end">
                    <div className="fw-bold text-secondary">
                        {totalWeight}
                    </div>
                    <div className="fw-bold text-secondary">
                        {totalVolume}
                    </div>
                </div>
            </div>
        </div>
    )

    function deleteDimension(index) {
        handleChange(
            'shipment_details',
            produce(shipment_details, draft => {
                draft.moversly_boxes_luggage.splice(index, 1);
            })
        );
    }

    function handleValueChange(value, index, prop1, prop2) {
        if (prop2) {
            handleChange(
                'shipment_details',
                produce(shipment_details, draft => {
                    draft.moversly_boxes_luggage[index][prop1][prop2] = value;
                })
            );
        }
        else {
            handleChange(
                'shipment_details',
                produce(shipment_details, draft => {
                    draft.moversly_boxes_luggage[index][prop1] = value;
                })
            );
        }
    }

    function addDimension() {
        handleChange(
            'shipment_details',
            produce(shipment_details, draft => {
                draft.moversly_boxes_luggage.push(empty_moversly_boxes_luggage.moversly_boxes_luggage);
            })
        );
    }
    function handleChange(name, value) {
        dispatch(handleShipmentState(name, value));
    }
}