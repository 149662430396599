import produce from "immer";

// import { Button } from "../../../components";

// constants
import { useDispatch, useSelector } from "react-redux";
import { COMPONENT_DATA } from "../../../common/consts/config";
import { sum } from "lodash";
import { handleShipmentState } from "../../../redux/actions/shipmentActions";
import { moversly_boxes_box } from "../../../common/consts/emptyStates";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Input, Select } from "@material-ui/core";

import { Delete } from "@material-ui/icons";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  luggage_table_header: {
    backgroundColor: "#eeeeee88",
  },
  table_input: {
    display: "flex",
    maxWidth: 350,
  },
  luggage_input: {
    height: 30,
    width: "20%",
    marginLeft: "5%",
    textAlign: "center",
  },
}));

export default function BoxTable(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  //  const role = useSelector((state) => state?.user?.role["cognito:groups"]);
  // const Booker = role?.include("BOOKER") 
  // const Booker = role?.includes("BOOKER")
  const Booker =true

  const { label = "Label", labelClassName, containerClassName } = props;

  const shipment_details = useSelector(
    (state) => state.shipment?.shipment_details
  );
  const moversly_boxes = shipment_details?.moversly_boxes || [];
  const bodyItems =
    COMPONENT_DATA?.bookShipment?.formTwo?.moverslyBox?.bodyItems || [];

  const totalWeight = sum(
    moversly_boxes?.map(
      (ele) => +ele.number_of_such_boxes * +ele?.weight?.magnitude
    )
  );
  const totalVolume = sum(
    moversly_boxes?.map((ele) => {
      return +ele.number_of_such_boxes > 0 || ele.weight.magnitude
        ? (+ele.number_of_such_boxes *
            +(
              +ele?.dimensions.length *
              +ele?.dimensions?.width *
              +ele?.dimensions?.height
            )) /
            5000
        : 0;
    })
  )?.toFixed(1);

  return (
    <div className={`col-12 mb-3 mt-2 ${containerClassName}`}>
      <div className={`col-12 fs-09 py-2 ${labelClassName}`}>{label}</div>
      <div className={`col-12`}>
        <table className="w-100">
          <tr className={classes.luggage_table_header}>
            <th className="fs-09 p-2 py-3">Box Size (cm)</th>
            <th className="fs-09 p-2 py-3">No. of Boxes</th>
            <th className="fs-09 p-2 py-3">Weight</th>
            <th className="fs-09 p-2 py-3">Options</th>
          </tr>
          {moversly_boxes?.map((box, index) => {
            return (
              <tr key={index}>
                <td>
                  <span className="fs-08 fw-bold text-secondary">
                    {box?.custom
                      ? `Custom ${index + 1}`
                      : box?.description || ""}
                  </span>
                  {box?.custom ? null : (
                    <div className="text-secondary fs-st ">W : B : H</div>
                  )}
                  {box?.custom ? (
                    <div className="text-secondary fs-st">
                      <Input
                        className={classes.luggage_input}
                        placeholder="W"
                        onChange={(e) => {
                          handleValueChange(
                            e.target.value,
                            index,
                            "dimensions",
                            "width"
                          );
                        }}
                        value={box?.dimensions?.width}
                      />
                      <Input
                        className={classes.luggage_input}
                        placeholder="B"
                        onChange={(e) => {
                          handleValueChange(
                            e.target.value,
                            index,
                            "dimensions",
                            "length"
                          );
                        }}
                        value={box?.dimensions?.length}
                      />
                      <Input
                        className={classes.luggage_input}
                        placeholder="H"
                        onChange={(e) => {
                          handleValueChange(
                            e.target.value,
                            index,
                            "dimensions",
                            "height"
                          );
                        }}
                        value={box?.dimensions?.height}
                      />
                    </div>
                  ) : (
                    <div
                      className="text-secondary fs-st "
                      style={{ width: 120 }}
                    >
                      {`${box?.dimensions?.width} :` || 0}{" "}
                      {`${box?.dimensions?.length} :` || 0}{" "}
                      {`${box?.dimensions?.height}` || 0}
                    </div>
                  )}
                </td>
                <td>
                  <Input
                    className={classes.luggage_input}
                    style={{
                      width: "80%",
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="0"
                    value={box?.number_of_such_boxes}
                    onChange={(e) => {
                      handleNumBoxes(e.target.value, index);
                    }}
                  />
                  {/* <Select
                    className={classes.luggage_input}
                    style={{
                      width: "80%",
                     
                    }}
                    value={box?.number_of_such_boxes || 0}
                    onChange={(e) => {
                      handleNumBoxes(e.target.value, index);
                    }}
                  >
                    {bodyItems?.map((num, index) => {
                      return (
                        <option value={num} key={index}>
                          {num}
                        </option>
                      );
                    })}
                  </Select> */}
                </td>
                <td>
                  <div className="d-flex justify-content-center align-items-center">
                    <Input
                      className={`${classes.luggage_input} w-100`}
                      placeholder="0"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        handleWeight(e.target.value, index);
                      }}
                      value={box?.weight?.magnitude || ""}
                    />
                  </div>
                </td>
                {moversly_boxes.length > 1 && (
                  <>
                {!Booker &&  <td className="d-flex justify-content-center align-items-center">
                    {box?.custom ? (
                      <IconButton
                        onClick={() => {
                          deleteDimension(index);
                        }}
                      >
                        <Delete className="cursor-pointer fs-10 text-danger bi bi-x my-3 " />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          deleteDimension(index);
                        }}
                      >
                        <Delete
                          size={100}
                          className="cursor-pointer fs-10 text-danger bi bi-x my-3 "
                        />
                      </IconButton>
                    )}
                  </td>
                
                  }
                    </>
                )}
              </tr>
            );
          })}
        </table>
      </div>
 {   !Booker &&  <div className="col-12 mt-3 text-end">
      
       <IconButton style={{
          backgroundColor:"lightgreen"
        }}  onClick={handleAddCustomDimension} >
          <Add/>
        </IconButton>
      </div>}
      <div className="row mt-3 luggage-table-details">
        <div className="col-4">
          <div className="fw-bold">Total</div>
          <div className="fw-bold">Weight</div>
        </div>
        <div className="col-4 text-end">
          <div className="fw-bold text-secondary">(KGs):</div>
          <div className="fw-bold text-secondary">(Volumetric):</div>
        </div>
        <div className="col-4 text-end">
          <div className="fw-bold text-secondary">{totalWeight}</div>
          <div className="fw-bold text-secondary">{totalVolume}</div>
        </div>
      </div>
    </div>
  );

  function handleValueChange(value, index, prop1, prop2) {
    handleChange(
      "shipment_details",
      produce(shipment_details, (draft) => {
        draft.moversly_boxes[index][prop1][prop2] = value;
      })
    );
  }

  function deleteDimension(index) {
    handleChange(
      "shipment_details",
      produce(shipment_details, (draft) => {
        draft.moversly_boxes.splice(index, 1);
      })
    );
  }

  function handleAddCustomDimension() {
    handleChange(
      "shipment_details",
      produce(shipment_details, (draft) => {
        draft.moversly_boxes.push(moversly_boxes_box);
      })
    );
  }

  function handleNumBoxes(value, index) {
    handleChange(
      "shipment_details",
      produce(shipment_details, (draft) => {
        draft.moversly_boxes[index].number_of_such_boxes = value;
      })
    );
  }

  function handleWeight(value, index) {
    handleChange(
      "shipment_details",
      produce(shipment_details, (draft) => {
        draft.moversly_boxes[index].weight.magnitude = value;
      })
    );
  }

  function handleChange(name, value) {
    dispatch(handleShipmentState(name, value));
  }
}
