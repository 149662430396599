import { useEffect } from "react";
import PricePack from "./components/PricePack";

export default function PricePacks() {

  useEffect(() => {
    window.scrollTo(0, 0);
  })

    return (
        <>
            <div className="container-fluid bg-color-2 color-2 py-4">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-6 my-auto">
                            <h1 className="col-12 fs-1">International Movers</h1>
                            <h4 className="col-12 fs-3">Join now to get one month free usage!</h4>
                        </div>
                        <div className="col-6 col-lg-4">
                            <img className="img-fluid" src="./assets/images/image-2.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <PricePack />
        </>
    )
}