import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";

export default function PrivacyPolicy({ setShowHeaderFooter }) {
  const location = useLocation();
  if (location.pathname === "/terms-conditions") {
    setShowHeaderFooter(false);
  }
  return (
    <div className="container">
      <div className="row">
        {/* <div className="col-12 py-5">
          <div className="privacy-heading">
            Moversly.com Terms and Conditions
          </div>

          <div className="privacy-heading">Shipment Terms & Conditions</div>

          <div className="privacy-content">
            In order to deliver its services, Moversly may execute any of the
            following acts on behalf of the Shipper or Receiver:
            <div className="ps-5">
              <div className="privacy-sub-heading">Customs</div>

              <div className="privacy-content my-0">
                complete any papers, modify product or service codes, and pay
                any duties, taxes, or penalties imposed by applicable laws and
                regulations (collectively, "Customs Duties"). act as the
                Shipper's forwarding agent for customs and export control
                purposes, and as the Receiver solely for the purpose of
                designating a customs broker to perform customs clearance and
                entry, and redirect the Shipment to the Receiver's customs
                broker or another address upon request by anyone Moversly
                believes to be authorised.
              </div>

              <div className="privacy-sub-heading">Shipment</div>

              <div className="privacy-content">
                A shipment is deemed inadmissible if it contains counterfeit
                products, animals, bullion, cash, gem stones; firearms,
                explosives, and ammunition; human remains; illicit commodities,
                such as ivory and narcotics; and no customs declaration is made
                when required by applicable customs requirements. IATA
                (International Air Transport Association), ICAO (International
                Civil Aviation Body), ADR (European Road Transport Regulation on
                Dangerous Goods) or any applicable organisation (“Dangerous
                Goods”) classifies it as hazardous material, dangerous goods,
                forbidden or restricted commodities. It contains any other item
                that our shipping partners determines cannot be transported
                safely or legally because its address is inaccurate or not
                properly marked, or its packaging is defective or inadequate to
                enable safe transportation with ordinary care in handling.
              </div>
            </div>
          </div>

          <div className="privacy-heading">Deliveries & Undeliverable</div>

          <div className="privacy-content">
            <ul>
              <li className="pb-3">
                PO boxes and postal codes are not acceptable delivery addresses.
                Shipments are delivered to the address provided by the Shipper,
                but not always to the identified Receiver directly. Deliveries
                to addresses with a central reception area will go to that
                location.
              </li>
              <li>
                Receiver may be notified by shipping partner of an impending
                delivery or a missed delivery. Alternative delivery
                alternatives, such as delivery on a different day, no signature
                necessary, redirection, may be offered to the recipient. On
                request, the shipper may exclude some delivery alternatives.
              </li>
            </ul>
          </div>

          <div className="privacy-heading">Safety Precautions</div>

          <div className="privacy-content">
            For safety, security, customs, or other regulatory reasons, our
            shipping partner reserves the right to open and inspect a shipment
            without prior notification.
          </div>

          <div className="privacy-heading">Pricing & Costs</div>

          <div className="privacy-content">
            <ul>
              <li className="pb-3">
                Our charges are based on the greater of actual or volumetric
                weight per piece, and any piece may be re-weighed and measured
                by our shipping partner to confirm this computation.
              </li>
              <li>
                Shipper, or the Receiver when our shipping partner acts on
                behalf of the Receiver, shall pay or reimburse our shipping
                partner for all Shipment or other charges due, or Customs Duties
                payable for services supplied by our shipping partner or
                incurred on Shipper's or Receiver's behalf. Prior to delivery,
                payment of customs duties may be demanded.
              </li>
            </ul>
          </div>

          <div className="privacy-heading">Shipment Insurance</div>

          <div className="privacy-content">
            We issue our shipping partner in writing, including by completing
            the insurance section on the front of the waybill and we pay the
            necessary premium, our shipping partner may need to arrange
            insurance covering the value in the event of loss or damage to the
            Shipment. Indirect loss or damage, as well as loss or damage caused
            by delays, are not covered by shipment insurance.
          </div>

          <div className="privacy-heading">Unforeseen Circumstances</div>

          <div className="privacy-content">
            <ul>
              <li className="pb-3">
                We are not responsible for any loss or damage caused by events
                beyond its control. These include, but are not limited to,
                electrical or magnetic damage to, or erasure of, electronic or
                photographic images, data, or recordings; any defect or
                characteristic related to the nature of the Shipment, even if
                known to our shipping partner; and any other defect or
                characteristic related to the nature of the Shipment, even if
                known to our shipping partner.
              </li>
              <li>
                Any act or omission by a person who is not employed or
                contracted by us, such as the shipper, receiver, third party,
                customs, or another government official; “Force Majeure” - such
                as an earthquake, cyclone, storm, flood, fog, war, plane crash,
                embargo, riot, civil commotion, or industrial action.
              </li>
            </ul>
          </div>

          <div className="privacy-heading">Claims</div>

          <div className="privacy-content">
            All claims must be reported to us in writing within thirty (30) days
            of the date we accepted the shipment; otherwise, we will have no
            liability. Claims are restricted to one per shipment, and settlement
            will be full and final for all loss or damage associated with that
            shipment.
          </div>

          <div className="privacy-heading">The Choice is Yours</div>

          <div className="privacy-content">
            You can review and change your personal information on our website
            (for example - your name and contact information). Additionally, you
            may choose to unsubscribe from our marketing / promotional /
            newsletters mailings.
          </div>

          <div className="privacy-heading">Policy Updates</div>

          <div className="privacy-content">
            Moversly.com may update the privacy policy to make changes in our
            information practices. Moversly.com reserves the right to change or
            update the Privacy Policy at any given time. The changes in the
            privacy policy are effective immediately upon posting to this site.
          </div>

          <div className="privacy-heading">Contact Us</div>

          <div className="privacy-content">
            If you have any queries about our Privacy Policy, you can reach out
            to us at Contact Number: <b>+65 9634-3448</b> or write to us at{" "}
            <b>info@moversly.com</b>.
          </div>
        </div> */}
        <div className="col-12 py-5">
          <div className="privacy-heading">Terms and Conditions</div>

          <div className="privacy-content">
            <div className="ps-5">
              <div className="privacy-sub-heading">
                Our Services in Location
              </div>
              <div className="privacy-content">
                <ul>
                  <li>Pre-move consultation and survey</li>
                  <li>
                    Experienced professional packing team will pack the items
                    based on international moving standards.
                  </li>
                  <li>
                    We take necessary precautions to prevent goods from any kind
                    of moisture and water contamination damage.
                  </li>
                  <li>
                    Our moving consultants will coordinate with your building
                    management authority.
                  </li>
                  <li>
                    The quotation includes all port or shipping line charges in
                    Singapore and ocean freight charges to the destination port
                  </li>
                  <li>
                    Provision of various sizes of cartons, tapes, bubble wrap,
                    wrapping papers, hardboard and all other required
                    International standard packing material.
                  </li>
                  <li>
                    Our expert moving consultants will take care of
                    all-important paperwork such as preparing transit inventory
                    list, handling packing completion documents, managing export
                    and outbound customs clearance documentation etc.
                  </li>
                </ul>
              </div>
              <div className="privacy-sub-heading">
                Our Services at Destination
              </div>
              <div className="privacy-content">
                <ul>
                  <li>
                    Import Documentation and Custom clearance including
                    Destination port charges
                  </li>
                  <li>
                    Transportation of the shipment from port to the
                    residence/warehouse.
                  </li>
                  <li>
                    Our services at client destination include safe delivery of
                    belongings to client residence, placing of boxes in flat
                    surface or placing cartons into respective rooms, and
                    assembling of normal furniture’s like dining table beds etc.
                    which does not require any highly-train.
                  </li>
                  <li>
                    On the day of delivery, our crew will take care of cleaning
                    debris and will return the empty container to the nearest
                    port.
                  </li>
                  <li>
                    The customer has to provide all import documentation for
                    customs clearance prior to the arrival of the shipment at
                    the port or in advance as per the requirement in each
                    country.
                  </li>
                </ul>
              </div>
              <div className="privacy-sub-heading">Transit Insurance</div>
              <div className="privacy-content">
                <ul>
                  <li>
                    APAC Relocation can arrange transit insurance in accordance
                    with the terms and conditions of transit insurance
                    underwriters. If client report of damage to any goods during
                    transit, we can assist them in claim settlement proceedings.
                  </li>

                  <li>
                    All claim shall be reported within 30 days of delivery as
                    per the delivery document date.
                  </li>
                  <li>
                    Completed insurance form must be submitted by the client 3
                    days prior to the packing date.
                  </li>
                  <li>Minimum insurance premium will be SGD 150.</li>
                  <li>
                    Storage insurance extension is available after 90 free days,
                    applicable fees may apply.
                  </li>
                  <li>
                    All claims will be subject to insurance underwriter’s terms
                    and conditions. Apac Relocation has no liability for the
                    claim procedure or claiming amounts. In case of non-insured
                    shipment, APAC can behold liable for max S$ 100 per
                    shipment.
                  </li>
                </ul>
              </div>
              <div className="privacy-sub-heading">Rates Exclude</div>
              <div className="privacy-content">
                <ul>
                  <li>
                    Dismantling and assembling of new or flat packed, IKEA or
                    furniture/items which require a specialist.
                  </li>
                  <li>
                    Wall mounting, electrical works, piano handling, valet
                    serviceunpacking of boxes and placing in cupboards, shelves
                    etc.
                  </li>
                  <li>
                    Non-refundable deposit to condo/building management
                    authority.
                  </li>
                  <li>
                    Delivery services on weekends, public holidays and Non
                    –office hours.
                  </li>
                  <li>
                    Stair carrying above 1st floor and use of use external
                    elevator. Parking permit slot charges and shuttling services
                    beyond 50 feet between container/truck parking location and
                    the main entrance of the residence.
                  </li>
                  <li>
                    Destination port charges, destination customs warehouse
                    storage, customs warehouse handling charges etc.
                  </li>
                  <li>
                    Customs duty/tax, quarantine charges, X-ray/ Gamma Radiation
                    scanning fee, examination fee, and any govt. charges if any
                    at the destination.
                  </li>

                  <li>
                    Port storage charges/container detention charges. These
                    charges can be applicable in following cases - Lack of
                    necessary documents, Non-Availability of the client for the
                    delivery, and any delay in the release of shipment from
                    customs due to holidays, technical issues in customs website
                    and unexpected issues like port congestions, natural
                    calamities, and strikes.
                  </li>
                  <li>
                    Warehouse handling charges(in/out) other than indicated in
                    the proposal. These charges are applicable only if storage
                    is required by the client at origin/ destination.
                  </li>
                </ul>
              </div>
              <div className="privacy-sub-heading">Payment Terms</div>
              <div className="privacy-content">
                <ul>
                  <li>
                    The client is required to make full payment before we can
                    start with the shipment.
                  </li>
                  <li>
                    In case of long term storage(3 months or more) at origin is
                    required, client must pay at least 60% of the total
                    quotation plus the 3-month storage fee. Additionally, if
                    short term storage(less than 3 months), client must pay at
                    least 60% of the total quotation amount plus total months of
                    storage fee applicable.
                  </li>
                  <li>Quote validity 30 days.</li>
                  <li>
                    Credit card payment will have additional transaction charge.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
