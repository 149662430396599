import { useState } from 'react';
import emailjs from 'emailjs-com';
import Banner from "../../common/components/Banner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// info@moversly.com

export default function AboutContactUS() {

    const [ToastActive, setToastActive] = useState()

    const notify = () =>
        toast("Succesfully Details Sent", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });;

    function sendEmail(e) {
        e.preventDefault();
        // emailjs.sendForm('', '', e.target, '')
        //     .then((result) => {
        //         setToastActive(result.text);
        //     },
        //         (error) => {
        //             console.log(error.text);
        //         });
    }

    return (
        <>
            {renderPage()}
        </>
    )
    function renderPage() {
        let pathname = window.location.pathname;
        if (pathname === "/about") {
            return (
                <>
                    <Banner
                        title={'About'}
                    />
                    <div className="container">
                        <div className="row">
                            <div className="col-12 py-5">
                                <div className="privacy-content">
                                    Are you tired by the outdated and costly packages of international moving services? Calling multiple moving companies & checking credibility, the lack of transparency in price and availability?  Our Team of hard-working individuals have come together to develop a very own own digital solution. Moversly was created with the goal of enabling everyone to receive door-to-door delivery services at the click of a button.
                                </div>
                                <div className="privacy-content">
                                    Users can access a large pool of experienced & credible movers through a single app. Simply enter your moving from & moving to locations, select the shipment type you require, and you'll be receiving the best quote from movers. In the app, you can track the entire delivery process from beginning to end, make digital payments, and keep track of all your activities in one spot. For any types, and sizes of moving, Moversly is a crucial partner in simplifying international moving process for both customers & movers.
                                </div>
                            </div>
                        </div>
                    </div >
                </>
            )
        }
        else if (pathname === "/contact_us") {
            return (
                <>
                    <Banner
                        title={'Contact Us'}
                    />
                    <div className="container my-5">
                        <div className="row center">
                            <div className="contact-up-resposive">
                                <div className="row d-flex justify-content-between px-2 mb-5">
                                    <div className={`col-12 mb-2 pb-2`}>
                                        <h3 className="col-12">Fill the form here</h3>
                                    </div>
                                    <form onSubmit={sendEmail}>
                                        <div className="form-group row gap-3">
                                            <div>
                                                <label className="mb-1" for="exampleFormControlInput1"><span style={{ color: "#ed4640" }}>*</span> Full Name</label>
                                                <input type="name" name="name" className="form-control" id="exampleFormControlInput1" placeholder="Full Name" />
                                            </div>
                                            <div>
                                                <label className="mb-1" for="exampleFormControlInput1"><span style={{ color: "#ed4640" }}>*</span> Company Name</label>
                                                <input type="name" name="from_name" className="form-control" id="exampleFormControlInput1" placeholder="Enter the name of your company" />
                                            </div>
                                            <div>
                                                <label className="mb-1" for="exampleFormControlInput1"><span style={{ color: "#ed4640" }}>*</span> Email Address</label>
                                                <input type="email" name="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                                            </div>
                                            <div>
                                                <label className="mb-1" for="exampleFormControlSelect1"><span style={{ color: "#ed4640" }}>*</span> We are </label>
                                                <select type="selection" name="selection" className="form-control" id="exampleFormControlSelect1">
                                                    <option disabled defaultValue selected>Select option</option>
                                                    <option>Mover</option>
                                                    <option>Packer</option>
                                                    <option>O/F Agent</option>
                                                    <option>Airlines</option>
                                                    <option>Courier</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label className="mb-1" for="exampleFormControlTextarea1"><span style={{ color: "#ed4640" }}>*</span> Message</label>
                                                <textarea name="message" className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Write any Message."></textarea>
                                            </div>
                                            <div className="mt-2 d-flex justify-content-end">
                                                <button type="submit" className="btn btn-primary px-4" id="liveToastBtn" onClick={notify}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        ToastActive === "OK" &&
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable={false}
                            pauseOnHover={false}
                        />
                    }
                </>
            )
        }
    }
}
