import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useHistory } from "react-router-dom";
import { API_ROUTES, BASE_URL } from "../../../common/consts/config";
import Helper, { HelperWithUrl } from "../../../common/consts/helper";
import { storageToken } from "../../../common/consts/browserVariables";
import { startCase } from "lodash";
import { FileCopyOutlined } from "@material-ui/icons";
import { AiOutlineOrderedList } from "react-icons/ai";
import { useSelector } from "react-redux";
import { BookmarkAdd, Visibility } from "@mui/icons-material";
import OrderHeader from "./OrderHeader";
import moment from "moment";

const CUSTOMER_STATE = [
  {
    label: "INITIAL_STATE",
    value: 1,
  },
  {
    label: "JOB_ASSIGNED",
    value: 2,
  },
  {
    label: "QUOTE_ISSUED",
    value: 3,
  },
  {
    label: "DELIVERY_ARRANGED",
    value: 4,
  },
  {
    label: "DELIVERY_COMPLETED",
    value: 5,
  },
  {
    label: "CUSTOMS_CLEARANCE_DONE",
    value: 6,
  },
  {
    label: "VESSEL_REACHED_POD",
    value: 7,
  },
  {
    label: "VESSEL_SAILING_STARTED",
    value: 8,
  },
  {
    label: "WAREHOUSE_STORAGE",
    value: 9,
  },
];

const CUSTOMER_STATE2 = [
  {
    label: "PACKING_COMPLETED",
    value: 10,
  },
  {
    label: "INSURANCE_APPLIED",
    value: 11,
  },
  {
    label: "BL_GENERATED",
    value: 12,
  },
  {
    label: "CONTAINER_TRUCKING_ARRANGED",
    value: 13,
  },
  {
    label: "VESSEL_OR_CARRIER_BOOKED",
    value: 14,
  },
  {
    label: "DESTINATION_AGENT_SELECTED",
    value: 15,
  },
  {
    label: "BUILDING_PERMISSION_TOKEN",
    value: 16,
  },
  {
    label: "PACKING_CREW_BOOKED",
    value: 17,
  },
];

const TrackOrder = (props) => {
  const { setShowHeaderFooter } = props;
  const user = useSelector((state) => state?.user);
  const [isLoading, setIsLoading] = useState("");
  const [orderDetails, setOrderDetails] = useState({});
  const [quote, setQuote] = useState([]);
  const [apiError, setApiError] = useState("");
  const history = useHistory();
  const pathname = history?.location?.pathname;
  const Id = pathname.split("/").join().split(",");

  const order_id = history?.location?.state || Id[4];

  const status = orderDetails?.order_tracking_state;

  const trackOrder = async () => {
    try {
      const { route, method } = API_ROUTES?.trackOrder;
      // const response = await Helper(`${route}/${order_id}`, method);
      // const response = await Helper(
      //   `Customer/get-order-track-order-state-time/${order_id}`,
      //   "GET"
      // );

      const response = await HelperWithUrl(
        `${BASE_URL}Customer/get-order-track-order-state-time/${order_id}`
      );
      // console.log(response);

      if (response?.error_code) {
        setApiError(response?.message);
      } else {
        setOrderDetails(response);
      }
    } catch (error) {}
  };

  const getQuote = async () => {
    const access_token = localStorage.getItem(storageToken);
    if (access_token) {
      const res = await Helper(`quote/order/${order_id}`, "GET");
      if (!res?.error_code) {
        setQuote(res);
      }
    }
  };
  useEffect(() => {
    trackOrder();
    setShowHeaderFooter(false);
    getQuote();
  }, []);

  const determineCompleted = (item, value) => {
    let arrayToFilter = orderDetails?.order_tracking_state_and_time;
    const data = arrayToFilter?.findIndex((object) => {
      return object?.order_tracking_state === item;
    });

    const active = data >= value;
    if (active) {
      return "#293356";
    } else {
      return "#C7C5C5";
    }
  };

  const getDocToPreview = async (id) => {
    try {
      setIsLoading(true);
      const res = await Helper(`quote/get-downloadable/${id}`, "GET");
      if (res.error_code) {
        setIsLoading(false);
        setApiError(res.message);
      } else {
        setIsLoading(false);
        const pdf = res.url;
        const link = document.createElement("a");
        link.setAttribute("target", "_blank");
        link.download = `${pdf}`;
        link.href = `${pdf}`;
        link.click();
      }
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        // background: "#8276F4",
        height: "100%",
      }}
    >
      <OrderHeader order_id={order_id} />
      {apiError ? (
        <Card
          style={{
            background: "#EEEBFC",
            width: "80%",
            height: 400,

            borderRadius: 10,
            margin: "-60px auto 40px auto",
            padding: "20px 20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: "red",
              fontSize: 30,
            }}
          >
            {apiError}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "red",
              fontSize: 30,
            }}
          >
            {`Please verify that the order Id ( ${order_id} ) is correct`}
          </Typography>

          <Button
            sx={{
              fontSize: 25,
              color: "#fff",
              background: "#8276F4",
            }}
            onClick={() => history.goBack()}
          >
            Click here to go back
          </Button>
        </Card>
      ) : (
        <Card
          style={{
            background: "#EEEBFC",
            width: "80%",

            borderRadius: 10,
            margin: "-60px auto 40px auto",
            padding: "20px 20px",
          }}
        >
          <CardContent>
            <Box
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              component="div"
              onClick={() => history?.goBack()}
            >
              <ArrowBackIosIcon />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Delivery Status
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: 3,
              }}
            >
              <Box
                sx={{
                  p: 2,
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {orderDetails?.order_tracking_state_and_time
                  ?.slice(0, 9)
                  ?.map((step, i) => {
                    return (
                      <Box
                        key={step?.order_tracking_state}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: 400,
                          // justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              background: determineCompleted(status, i),
                              height: 36,
                              width: 5,
                              borderRadius: 10,
                            }}
                          />

                          <Box
                            sx={{
                              background: determineCompleted(status, i),
                              height: 30,
                              width: 30,
                              borderRadius: 15,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#fff",
                            }}
                          >
                            <Typography>{i + 1}</Typography>
                          </Box>
                          <Box
                            sx={{
                              background: determineCompleted(status, i),
                              height: 36,
                              width: 5,
                              borderRadius: 10,
                            }}
                          />
                        </Box>
                        {/* <Typography
                          sx={{
                            marginLeft: 10,
                          }}
                        >
                          {startCase(step?.order_tracking_state)}
                        </Typography> */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            style={{
                              marginLeft: 10,
                            }}
                          >
                            {startCase(step?.order_tracking_state)}
                          </Typography>
                          <Typography
                            style={{
                              marginLeft: 10,
                              color: "lightsalmon",
                            }}
                          >
                            {step?.scheduled_time
                              ? `${moment(
                                  (step?.scheduled_time || "") * 1000
                                )?.format("LLLL")}`
                              : ""}
                          </Typography>
                          <Typography
                            style={{
                              marginLeft: 10,
                              color: "lightseagreen",
                            }}
                          >
                            {step?.time
                              ? `${moment((step?.time || "") * 1000)?.format(
                                  "LLLL"
                                )}`
                              : ""}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
              <Box
                sx={{
                  p: 2,
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {orderDetails?.order_tracking_state_and_time
                  ?.slice(9, 18)
                  ?.map((step, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: 400,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              background: determineCompleted(status, i + 9),
                              height: 36,
                              width: 5,
                              borderRadius: 10,
                            }}
                          />
                          <Box
                            sx={{
                              background: determineCompleted(status, i + 9),
                              height: 30,
                              width: 30,
                              borderRadius: 15,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#fff",
                            }}
                          >
                            <Typography>{i + 10}</Typography>
                          </Box>
                          <Box
                            sx={{
                              background: determineCompleted(status, i + 9),
                              height: 36,
                              width: 5,
                              borderRadius: 10,
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            style={{
                              marginLeft: 10,
                            }}
                          >
                            {startCase(step?.order_tracking_state)}
                          </Typography>
                          <Typography
                            style={{
                              marginLeft: 10,
                              color: "lightsalmon",
                            }}
                          >
                            {step?.scheduled_time
                              ? `${moment(
                                  (step?.scheduled_time || "") * 1000
                                )?.format("LLLL")}`
                              : ""}
                          </Typography>
                          <Typography
                            style={{
                              marginLeft: 10,
                              color: "lightseagreen",
                            }}
                          >
                            {step?.time
                              ? `${moment((step?.time || "") * 1000)?.format(
                                  "LLLL"
                                )}`
                              : ""}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
      {!apiError && (
        <Card
          style={{
            background: "#EEEBFC",
            width: "80%",
            borderRadius: 10,

            margin: "20px auto 60px auto",
            padding: "20px 20px",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontWeight: "600",
              fontSize: 20,
            }}
          >
            Leads status
          </Typography>
          {quote?.length > 0 && (
            <CardContent>
              {quote?.map((item, i) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "space-between",
                      marginBottom: 4,
                    }}
                  >
                    <FileCopyOutlined fontSize="large" size={30} />
                    <Box
                      sx={{
                        marginLeft: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#F3722C",
                          fontSize: 18,
                          fontWeight: "500",
                        }}
                      >
                        {item?.quote_status === "ISSUED"
                          ? "Quote Sent"
                          : `${item?.quote_status}`}
                      </Typography>
                      <Typography
                        sx={{
                          color: " #81829A",
                          fontSize: 16,
                        }}
                      >
                        {`${moment(item?.created_date * 1000.0).format("lll")}`}
                        {/* 07-06-2022 | 11:50AM */}
                      </Typography>
                    </Box>

                    <IconButton
                      sx={{
                        marginLeft: 10,
                      }}
                      onClick={() => getDocToPreview(item?.quote_id)}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Tooltip title="View Quote">
                          <Visibility
                            sx={{
                              color: "#F3722C",
                            }}
                          />
                        </Tooltip>
                      )}
                    </IconButton>
                  </Box>
                );
              })}
            </CardContent>
          )}

          {user?.email === "" && (
            <CardContent>
              <Typography>Login to view more order details</Typography>
              <Button href="/login">Click here to login</Button>
            </CardContent>
          )}
        </Card>
      )}
    </Box>
  );
};

export default TrackOrder;
