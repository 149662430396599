import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  CloudUpload,
  Download,
  InsertPageBreak,
  Upload,
  Visibility,
} from "@mui/icons-material";
import Helper from "../../../common/consts/helper";
import { useSelector } from "react-redux";
import { API_ROUTES } from "../../../common/consts/config";
import OrderHeader from "./OrderHeader";

const pageData = [
  {
    orderId: "Sample Docs",
    shipped_from: "Singarpore",
    moving_type: "Air",
    destination: "New York",
    status: "delivered",
  },
  {
    orderId: "Sample Docs2",
    shipped_from: "Singarpore",
    moving_type: "Air",
    destination: "New York",
    status: "pending",
  },
];

const DocsUpload = (props) => {
  const { setPage, setShowHeaderFooter } = props;
  const history = useHistory();
  const user = useSelector((state) => state?.user);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [docs_name, setDocs_name] = useState("");
  const [apiError, setApiError] = useState("");
  const [file, setFile] = useState(null);
  const [docs, setDocs] = useState([]);
  const [upload_id, setUpload_id] = useState("");
  const order_id = history?.location?.state;

  const handleUpload = async (e) => {
    // e.preventDefault();
    setIsLoading(true);
    setProgress(2);
    const response = await Helper(
      `documents/get-document-upload-url/${order_id}/${upload_id}/${docs_name}`,
      "GET"
    );
    // console.log("docs res::", response);
    if (response.error_code) {
      setApiError(response.message);
    } else {
      setProgress(30);
      const awsUrl = response?.url;
      const doc_id = response?.doc_id;
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", `${file?.type}`);

      // const file = e.target.files[0];
      // console.log(file.type, "selected");
      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: file,
        redirect: "follow",
      };
      if (awsUrl && doc_id) {
        await fetch(`${awsUrl}`, requestOptions)
          .then(async (response) => {
            if (response.status === 200) {
              setProgress(60);

              const image_url = await Helper(
                `documents/get-doument-url/${order_id}/${upload_id}/${docs_name}/${doc_id}`
              );
              // console.log(image_url.url, "this is the pdf url");
              if (image_url?.url) {
                setIsLoading(false);
                setDocs_name("");
                setFile(null);
                setProgress(100);
                getDocs();
              }
            } else {
              setIsLoading(false);
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        setIsLoading(false);
      }
    }
  };

  const getDocs = async () => {
    try {
      const { route, method } = API_ROUTES?.trackOrder;
      const response = await Helper(`${route}/${order_id}`, method);
      console.log(response);

      setDocs(response?.document);
      setUpload_id(response?.user_id);
    } catch (error) {}
  };

  useEffect(() => {
    getDocs();
  }, []);

  const docsMap = () => {
    const data = docs?.map((doc) => {
      return {
        doc_name: doc?.document_name,
        doc_id: doc?.doc_id,
      };
    });
    return data;
  };

  const onDownload = async (docs_name, doc_id, type) => {
    const res = await Helper(
      `documents/get-doument-url/${order_id}/${upload_id}/${docs_name}/${doc_id}`
    );
    console.log(res);

    if (res && type === "view") {
      let pdf = res?.url;
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.download = `${pdf}`;
      link.href = `${pdf}`;
      link.click();
    }

    if (res && type === "download") {
      let pdf = res?.url;
      const link = document.createElement("a");
      link.setAttribute("target", "download");
      link.download = `${pdf}`;
      link.href = `${pdf}`;
      link.click();
    }

    // const link = document.createElement("a");
    // link.setAttribute("target", "_blank");
    // link.download = `${pdf}`;
    // link.href = `${pdf}`;
    // link.click();
  };

  useEffect(() => {
    setShowHeaderFooter(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setApiError("");
    }, 5000);
  }, [apiError]);

  return (
    <Box
      sx={{
        // background: "#8276F4",
        height: "100vh",
      }}
    >
      <OrderHeader order_id={order_id} />
      <Card
        style={{
          background: "#EEEBFC",
          width: "80%",
          borderRadius: 10,
          margin: "-60px auto 40px auto",
          padding: "20px 20px",
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
            }}
          >
            Additional Documents
          </Typography>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress
                variant="determinate"
                size={50}
                value={progress}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="caption" sx={{ pl: 1 }}>
                  Document Name
                </Typography>
                <Box
                  sx={{
                    borderRadius: 10,
                    height: 40,
                    display: "flex",
                    alignItems: "center",
                    background: "white",
                    width: "100%",
                  }}
                >
                  <input
                    placeholder="Enter Document Name"
                    style={{
                      // width: "90%",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      fontSize: 14,
                      padding: "0px 10px",
                    }}
                    onChange={(e) => setDocs_name(e.target.value)}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="caption">Upload File</Typography>
                <Box
                  component="div"
                  sx={{
                    borderRadius: 10,
                    height: 40,
                    display: "flex",
                    alignItems: "center",
                    background: "white",
                    width: "100%",
                  }}
                >
                  <input
                    style={{
                      width: "60%",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      fontSize: 14,
                      padding: "0px 10px",
                    }}
                    type="text"
                    value={file === null ? "" : file?.name}
                  />
                  <label
                    htmlFor="upload"
                    style={{
                      width: "40%",
                    }}
                  >
                    <TextField
                      type="file"
                      id="upload"
                      accept="pdf"
                      style={{ display: "none" }}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <Button
                      component="span"
                      sx={{
                        background: "#F3722C",
                        width: "100%",
                        borderTopRightRadius: 20,
                        borderBottomRightRadius: 20,
                        fontSize: 14,
                        color: "#fff",
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "#8276F3",
                        },
                      }}
                    >
                      Browse
                    </Button>
                  </label>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",

                  mt: 2,
                  width: "30%",
                }}
              >
                <Button
                  sx={{
                    background: "#fff",
                    borderRadius: 3,
                    width: "45%",
                  }}
                  onClick={() => setFile(null)}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    background: "#8276F3",
                    borderRadius: 3,
                    width: "45%",
                  }}
                  onClick={() => {
                    if (file === null) {
                      setApiError("File cannot be empty");
                    } else if (docs_name === "") {
                      setApiError("Document name can not be empty");
                    } else {
                      handleUpload();
                    }
                  }}
                >
                  save
                </Button>
              </Box>
            </Box>
          )}
        </CardContent>
        {apiError && (
          <Typography
            sx={{
              textAlign: "center",
              color: "red",
              fontSize: 16,
            }}
          >
            {apiError}
          </Typography>
        )}
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            Document Lists
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          {/* orer history table */}
          <table className="separatetable">
            <thead className="tablehead">
              <tr className="tableheadrow">
                <th className="tablehead text-bold">Document Name</th>
                <th className="tablehead">File ID</th>

                <th className="tablehead" style={{ textAlign: "center" }}>
                  Action
                </th>
                {/* <th className="tablehead"> Action</th> */}
              </tr>
            </thead>
            <tbody className="tablebody">
              {docsMap()
                ?.sort((a, b) => b.created_date - a.created_date)
                ?.map((data, i) => {
                  // console.log(data);
                  return (
                    <tr
                      // onClick={() => handleClick(data)}
                      key={i}
                      className="tablerow text-secondary-blue text-left text-bold"
                      style={{
                        background: "#CAC5F5",
                      }}
                    >
                      <td className="d-block d-md-none">
                        <div
                          className="mobiletableitem bg-secondary-blue text-white d-flex justify-content-between"
                          style={{ background: " #4f4562" }}
                        >
                          <div style={{ width: "50%" }}>{data?.doc_name}</div>
                        </div>
                      </td>
                      <td
                        data-label="Quote Id: "
                        className="tableitemtitle d-none d-md-block fw-bold text-capitalize"
                      >
                        {data?.doc_name}
                      </td>
                      <td
                        data-label="Email: "
                        className="tableitem"
                        style={{
                          fontSize: 16,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {data?.doc_id}
                        </div>
                      </td>
                      {/* <td data-label="status: " className="tableitem">
                        <span
                          style={{
                            // color: determineTextColor(data?.destination),
                            fontSize: "20px",
                          }}
                        >
                          {data?.moving_type}
                        </span>
                      </td> */}

                      {/* <td
                        data-label="Rates: "
                        className="tableitem d-none d-md-block"
                        style={{
                          fontSize: 16,
                        }}
                      >
                        {data?.destination}
                      </td> */}
                      {/* <td
                        data-label="status: "
                        className="tableitem text-center"
                      >
                        <span
                          style={{
                            fontSize: 16,
                            // color: determineTextColor(data?.quote_status),
                          }}
                        >
                          {data?.status}
                        </span>
                      </td> */}

                      <td
                        data-label="Action: "
                        className="tableitem text-center d-flex justify-content-center"
                      >
                        <IconButton
                          onClick={() =>
                            onDownload(data?.doc_name, data?.doc_id, "view")
                          }
                        >
                          <Tooltip title="View Documents">
                            <Visibility
                              sx={{
                                color: "#4D3BF4",
                              }}
                            />
                          </Tooltip>
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            onDownload(data?.doc_name, data?.doc_id, "download")
                          }
                        >
                          <Tooltip title="Download Document">
                            <Download
                              sx={{
                                color: "#4D3BF4",
                              }}
                            />
                          </Tooltip>
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DocsUpload;
