import { useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

// components
import BreadCrumb from "../../../common/components/BreadCrumb";
import Features from "../../../common/components/Features";
import Button from '../../../common/components/Button';
import TextInput from '../../../common/components/TextInput';
import PhoneInput from "../../../common/components/PhoneInput";
import Checkbox from "../../../common/components/Checkbox";


// constants
import Helper from '../../../common/consts/helper';
import { API_ROUTES, COMPONENT_DATA } from "../../../common/consts/config";
import { snakeCase, startCase } from "lodash";
import messages from "../../../common/consts/messages";
import Spinner from "../../../common/components/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { handleAuthState } from "../../../redux/actions/authActions";
import { storageToken } from "../../../common/consts/browserVariables";

const DEFAULT_COUNTRY_CODE = 'GB';
const initialErrorsSignup = COMPONENT_DATA?.signUp?.initialErrors || {};
const initialErrorsConfirmation = COMPONENT_DATA?.confirmation?.initialErrors || {};

export default function SignupForm(props) {

    const {
        isCustomerDetails,
        onSignupSuccess,
        setAuthSection,
        manageDetailsAfterLogin,
    } = props;

    const dispatch = useDispatch();

    const history = useHistory();

    const phoneInputRef = useRef();

    const { addToast } = useToasts();
    const gotConfirmation = history?.location?.state?.confirmation;

    const from_address = useSelector(state => state?.shipment?.schedule_shipment?.from_address);
    const shipment = useSelector(state=> state?.shipment)
    const shipment_details=useSelector(state=>state?.shipment?.shipment_details)
    // console.log(shipment)

    const [data, setData] = useState({
        email: "",
        phone: "",
        password: "",
        address: "",
        given_name: "",
        family_name: "N/A",
        country_code: DEFAULT_COUNTRY_CODE,
        termsAccepted: false
    });

    const rate = useSelector(state => state.shipment.rate);

    const [apiError, setApiError] = useState('');

    const [validationFired, setValidationFired] = useState(false);

    const [isConfirmation, setIsConfirmation] = useState(false);

    const [confirmationCode, setConfirmationCode] = useState('');

    // loaders
    const [isLoading, setIsLoading] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [price , setPrice] =useState({})

    const [error, setError] = useState(isConfirmation ? { ...initialErrorsConfirmation } : { ...initialErrorsSignup });

    useEffect(() => {
        if (validationFired) {
            validate();
        }
    }, [validationFired, data]);

    useEffect(() => {
        if (history?.location?.state?.confirmation) {
            setIsConfirmation(true);
            resendConfirmation(history?.location?.state?.email);
            setData({
                ...data,
                email: history?.location?.state?.email,
                password:history?.location?.state?.password
            })
        }
    }, [history]);
    useEffect(() => {
      fetchPrice()
    }, [])


    const fetchPrice= async ()=>{
       
        let airMoveTypeTitle = snakeCase(shipment_details?.moving_type?.toLowerCase());
        airMoveTypeTitle = airMoveTypeTitle ==="boxes"? "moversly_boxes":airMoveTypeTitle;

        const mode = shipment_details?.shipment_mode;
        const selectedBoxes =shipment_details?.moversly_boxes.filter( (box)=>box.number_of_such_boxes !=="" && box.number_of_such_boxes !=="0");
        const air_move_type={
            moving_type:shipment?.shipment_details?.moving_type,
            [airMoveTypeTitle]:{
                description:shipment?.shipment_details?.item_description
            }
        }
        const payload={
schedule_shipment:{
    from_address: shipment?.schedule_shipment?.from_address,
    to_address:shipment?.schedule_shipment?.to_address,
    moving_date:shipment?.schedule_shipment?.moving_date
},
shipment_details:{
    shipment_mode:mode,
    [mode?.toLowerCase()]:{
        need_packing:shipment?.shipment_details?.need_packing,
        move_type:mode==="SEA"? air_move_type:{
            moving_type:shipment_details?.moving_type,
            [shipment_details?.moving_type?.toLowerCase()]:{
                moversly_boxes:shipment_details?.moving_type==="LUGGAGE"?shipment_details?.moversly_boxes_luggage:selectedBoxes
            }
        },
        survey:{
            pre_move_survey_type:"SELF",
            self_survey:{}
        }
    }
}
        }
        // console.log(payload)
        const response = await Helper(`order/pricing-estimate`, "POST", payload,false,false,false)
        if(response){
            setPrice(response.price)
            console.log(response)
        }
    }

    return (
        <>
            {/* BreadCrubs */}
            <BreadCrumb
                list={[
                    {
                        title: 'Home',
                        link: '/'
                    },
                    {
                        title: 'International Movers',
                        link: '/PricePacks'
                    },
                    {
                        title: 'Sign Up',
                        link: '/'
                    }
                ]}
                fullWidth
            />
            {/* form */} 
            <div className={`row d-flex justify-content-between ${isCustomerDetails ? '' : 'px-2'}`}>
                {
                    isConfirmation ? (
                        <div className={`col-12 col-lg-${isCustomerDetails ? '12' : '5 px-5'} mt-4 mb-5 pb-4`}>
                            <h1 className="col-12">Confirmation</h1>
                            <p>Please enter confirmation code recieved on your E-Mail.</p>
                            <div className="col-12">
                                <TextInput
                                    label="Confirmation Code"
                                    placeholder="e.g. 000000"
                                    onChange={(value) => { setConfirmationCode(value) }}
                                    error={error?.code}
                                    value={confirmationCode}
                                />
                                <div className="text-primary cursor-pointer" onClick={() => { resendConfirmation() }}>
                                    {
                                        isResending ? (
                                            <Spinner active />
                                        ) : (
                                            'Resend Code'
                                        )
                                    }
                                </div>
                            </div>
                            {
                                apiError ? (
                                    <div className="error-feedback">
                                        {apiError}
                                    </div>
                                ) : null
                            }
                            <div className="col-12 mt-4 p-0 d-flex justify-content-between gap-4">
                                <Button
                                    title="Back"
                                    type="light"
                                    onClick={() => {
                                        setIsConfirmation(false);
                                    }}
                                />
                                <Button
                                    title="Verify"
                                    type="dark"
                                    isLoading={isConfirming}
                                    onClick={verifyConfirmation}
                                />
                            </div>
                        </div>
                    ) : (

                        <div className={`col-12 col-lg-${isCustomerDetails ? '12' : '5 px-5'}  mt-4 mb-5 pb-4`}>
                            <h1 className="col-12">Sign Up</h1><br/>
                          {price?.currency ? ( <div className="price">
                                <p>{`Total Amount: ${price?.currency} ${
                price?.amount ? `${parseFloat(price?.amount).toFixed(2)}` : ""
              }`}
                               </p>
                                
                            </div>):null}

                          
                           
                            <TextInput
                                label="Full name as Passport:"
                                placeholder="e.g. John Smith"
                                id="given_name"
                                value={data?.given_name}
                                onChange={handleChange}
                                error={error?.given_name}
                            />
                            {/* <TextInput
                                label="Family Name:"
                                placeholder="e.g. Smith"
                                id="family_name"
                                value={data?.family_name}
                                onChange={handleChange}
                                error={error?.family_name}
                            /> */}
                            <TextInput
                                label="Email:"
                                placeholder="e.g. john.smith@gmail.com"
                                id="email"
                                value={data?.email}
                                onChange={handleChange}
                                error={error?.email}
                            />
                            <PhoneInput
                                label="Phone:"
                                placeholder="e.g. +91 12345 67890"
                                id="phone"
                                value={data?.phone}
                                error={error?.phone}
                                onChange={handleChange}
                                inputProps={{
                                    defaultCountry: from_address?.country_code || DEFAULT_COUNTRY_CODE,
                                    ref: phoneInputRef
                                }}
                                onCountryChange={(country) => { handleChange(country, 'country_code') }}
                            />
                            <TextInput
                                label="Password:"
                                placeholder="*******"
                                id="password"
                                value={data?.password}
                                onChange={handleChange}
                                error={error?.password}
                                type="password"
                                popupNode={passwordPopup}
                            />
                            <TextInput
                                label="Address:"
                                placeholder="e.g. Street 12, Arlington, U.S"
                                id="address"
                                value={data?.address}
                                error={error?.address}
                                onChange={handleChange}
                            />
                            <Checkbox
                                placeholder={
                                    <span>I agree to Moversly's <Link to="/terms-conditions">Terms & Conditions</Link></span>
                                }
                                id="termsAccepted"
                                onChange={handleChange}
                                error={error?.termsAccepted}
                            />
                            {
                                apiError ? (
                                    <div className="error-feedback">
                                        {apiError}
                                    </div>
                                ) : null
                            }
                            <div className="col-12 mt-4 p-0 d-flex justify-content-between gap-4">
                                <Button
                                    title="Cancel"
                                    type="light"
                                    onClick={handleShowLogin}
                                />
                                <Button
                                    title="Save"
                                    type="dark"
                                    isLoading={isLoading}
                                    onClick={submit}
                                />
                            </div>
                            <div className="col-12 mt-4 d-flex flex-column align-items-center">
                                <div className="text mt-3 mb-2">
                                    Registered User?
                                </div>
                                <Button
                                    title="Login"
                                    type="dark"
                                    onClick={handleShowLogin}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    !isCustomerDetails ? (
                        <Features />
                    ) : null
                }
            </div>
        </>
    );

    function handleShowLogin() {
        if (isCustomerDetails) {
            setAuthSection('login');
        }
        else {
            history.push('/login');
        }
    }

    function passwordPopup() {
        return (
            <div className="p-2">
                <ul>
                    <li>Atleast one capital letter.</li>
                    <li>Atleast one number.</li>
                    <li>Atleast one symbol.</li>
                </ul>
            </div>
        )
    }

    async function resendConfirmation(email) {
        const route = API_ROUTES?.resendConfirmation?.route;
        const method = API_ROUTES?.resendConfirmation?.method;

        try {
            setIsResending(true);
            const response = await Helper(route, method, {
                user_name: email ? email : data?.email
            }, false, false);
            if (response?.message) {
                addToast(response?.message, {
                    appearance: 'info'
                });
            }
            setIsResending(false);
        }
        catch (error) {
            addToast(messages?.resendConfirmation, {
                appearance: 'error'
            });
            setIsResending(false);
        }
    }

    async function verifyConfirmation() {
        let validated = validate();
        if (true) {
            const route = API_ROUTES?.confirmation?.route;
            const method = API_ROUTES?.confirmation?.method;
            try {
                setIsConfirming(true);
                const response = await Helper(route, method, {
                    user_name: data?.email,
                    code: confirmationCode
                }, false, false);
                setIsConfirming(false);
                if (!response?.error_code) {
                    addToast(messages.confirmationDone, {
                        appearance: 'success'
                    });
                    await loginNow()
                    // if (onSignupSuccess) {
                    //     // hit login
                    //     await loginNow();
                    // }
                    // else {
                    //     history.push('/login');
                    // }
                }
                else {
                    addToast(response.message, {
                        appearance: 'error'
                    });
                }
            }
            catch (error) {
                addToast(messages.confirmationError, {
                    appearance: 'error'
                })
                setIsConfirming(true);
            }
        }
    }

    async function loginNow() {
        const body = {
            user_name: data.email,
            password: data.password
        }
        const route = API_ROUTES?.login?.route || '';
        const method = API_ROUTES?.login?.method || '';

        const response = await Helper(route, method, body, false, false);
        if (response?.error_code) {
            setApiError(response?.message);
        }
        else if (response?.access_token) {
            localStorage.setItem(storageToken, response?.access_token);
            dispatch(handleAuthState('isLoggedIn', true));
            if (manageDetailsAfterLogin) {
                manageDetailsAfterLogin();
            }else{
                history.push("/orders")
            }
        }
        else {
            // toast here with error
            addToast(messages.loginError, { appearance: 'error' });
        }
    }

    function validate() {
        setValidationFired(true);
        let validated = true;

        let errors = {};
        Object.keys(data)?.forEach((field) => {
            if (data[field] === '') {
                errors[field] = `${startCase(field)} is required!`;
                validated = false;
            }
        });
        setError(errors);
        return validated;
    }

    function submit() {
        const validated = validate(data);
        if (validated) {
            setIsLoading(true);
            setApiError('');
            const user = { ...data };
            delete user.termsAccepted;
            Helper(API_ROUTES?.signup?.route, API_ROUTES?.signup?.method, data, false, false)
                .then((response) => {
                    if (response?.error_code)
                        setApiError(response?.message)
                    else {
                        setIsConfirmation(true);
                    }
                })
                .catch(error => {

                })
                .finally(() => setIsLoading(false))
        }
    }

    function handleChange(text, type) {
        setData({
            ...data,
            [type]: text
        })
    }
}