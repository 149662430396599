import ActionTypes from "../actions/types";

const initialState = {
  is_summary: false,
  is_order_details: false,
  order_state_counts: {},
  request_ids: [],
  dates: [],
  shipment_modes: [],
  from_city: [],
  to_city: [],
  matching_orders: [],
  isLoading: false,
  prev_order_id: "",
  next_order_id: "",
};

const ordersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_ALL_LEADS:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.GET_PACKER_LEADS:
      return {
        ...state,
        is_summary: false,
        is_order_details: false,
        order_state_counts: {},
        request_ids: [],
        dates: [],
        shipment_modes: [],
        from_city: [],
        to_city: [],
        matching_orders: payload,
      };
    case ActionTypes.GET_SUPER_ADMIN_LEADS:
      return {
        ...state,
        order_state_counts: {},
        request_ids: [],
        dates: [],
        shipment_modes: [],
        from_city: [],
        to_city: [],
        matching_orders: [...payload.matching_orders],
        prev_order_id: payload.prev,
        next_order_id: payload.next,
      };
    case ActionTypes.LOADING:
      return {
        ...state,
        isLoading: payload.value,
      };
    default: {
      return state;
    }
  }
};

export default ordersReducer;
