
import { startCase } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Banner from "../../common/components/Banner";
import Button from "../../common/components/Button";
import PhoneInput from "../../common/components/PhoneInput";
import TextInput from "../../common/components/TextInput";
import messages from "../../common/consts/messages";


const DEFAULT_COUNTRY_CODE = "GB";
export default function PackerPage() {
    const from_address = useSelector(
        (state) => state?.shipment?.schedule_shipment?.from_address
      );
      const [error, setError] = useState({});
      const [validationFired, setValidationFired] = useState(false);
      const [isLoading, setIsLoading] = useState(false)
       const dispatch = useDispatch()
       const { addToast } = useToasts();
      const [data, setData] = useState({
        email: "",
        phone: "",
        name: "",
        country_code: DEFAULT_COUNTRY_CODE,
        message: "",
        signup_as: "",
      });
    
      const phoneInputRef = useRef();
    
      useEffect(() => {
        if (validationFired) {
          validate();
        }
      }, [validationFired, data]);
    
      function validate() {
        setValidationFired(true);
        let validated = true;
    
        let errors = {};
        Object.keys(data)?.forEach((field) => {
          if (data[field] === "") {
            errors[field] = `${startCase(field)} is required!`;
            validated = false;
          }
        });
        setError(errors);
        return validated;
      }
    
      function handleChange(text, type) {
        setData({
          ...data,
          [type]: text,
        });
      }
      const handleMessageChange = (e) => {
        const { name, value } = e.target;
        setData({
          ...data,
          [name]: value,
        });
      };
      const handleSubmit = async () => {
        const validated = validate(data);
        if(validated){
          setIsLoading(true)
    
          let url = "https://api.moversly.com/api/v1/partner/signup-intent"
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: data.email,
              phone: data.phone,
              name: data.name,
              message: data.message,
              signup_as: data.signup_as,
            }),
          };
          await fetch(url, requestOptions).then((res) => {
            if(res.status === 200){
              addToast(messages.partnerSuccess, {
                appearance: 'success'
            });
            }
          }).catch((error) => {
            console.log(error)
          });
          setIsLoading(false)
        }
      }    
    
    return (
        <>
        <Banner title={'Packer'}/>
        <div className="container">
            <div className="row">
                <div className="col-12 py-5">
                    <div className="privacy-heading">
                    Looking for Ways to Get more Packing Deals? Tired of Delayed Payments? Want to Make More Money? 
                    </div>
                    <div className="privacy-heading">
                    Join Moversly for Free!
                    </div>
                    <div className="privacy-content">
                    With Moversly, you can quickly get connected to a large movers base, who are looking for International Moving. Our powerful software automates moving requests and helps you generate higher leads, and maximize earnings! You can get multiple bookings, instant jobs and accept only the jobs that you want. Benefit from our digitized documents policy with online bills, invoices and payments. Say goodbye to delayed payments & half-done jobs with Moversly. Join Now!
                    </div>

                   <div className="d-flex row">
                       <div className="col-lg-5">
                       <div className="privacy-heading">
                        Features
                    </div>

                    <div className="privacy-content">
                        <ul>
                            <li className="listings">
                            Increased Earnings
                            </li>
                            <li className="listings">
                            Quick Payments
                            </li>
                            <li className="listings">
                            Get Bookings Anytime & Anywhere
                            </li>
                            <li className="listings">
                            Automated Rates
                            </li>
                            <li className="listings">
                            Accurate Information
                            </li>
                            <li className="listings">
                            E-Invoice & POS
                            </li>
                            <li className="listings">
                            Managing Inventory Tool 
                            </li>
                            <li className="listings">
                            Cost Savings
                            </li>
                            <li className="listings">
                            Custom Language & Currency 
                            </li>
                        </ul>
                        
                    </div>
                       </div>
                       <div className="col-lg-1 " ></div>
              {/* form section */}
              <div className=" col-lg-6  " >
              <div className="privacy-heading text-center">Join Moversly for and maximize profits!</div> 
              <TextInput
                  label={"Name"}
                  placeholder={"e.g. John Doe"}
                  id="name"
                  value={data?.name}
                  onChange={handleChange}
                  error={error?.name}
                />
                <TextInput
                  label={"Email"}
                  placeholder={"e.g. john@amail.com"}
                  onChange={handleChange}
                  id="email"
                  value={data?.email}
                  onChange={handleChange}
                  error={error?.email}
                />
                <PhoneInput
                  label={"Phone"}
                  placeholder="e.g. +91 12345 67890"
                  id="phone"
                  value={data?.phone}
                  error={error?.phone}
                  onChange={handleChange}
                  inputProps={{
                    defaultCountry:
                      from_address?.country_code || DEFAULT_COUNTRY_CODE,
                    ref: phoneInputRef,
                  }}
                  onCountryChange={(country) => {
                    handleChange(country, "country_code");
                  }}
                />
                <div className="mt-3">
                  <label className="mb-2" for="exampleFormControlSelect1 ">
                    We are
                  </label>
                  <select
                    type="selection"
                    name="signup_as"
                    className="form-control input-bg border-0 button-border subtext-color"
                    id="type_any"
                    onChange={handleMessageChange}
                    value={data?.signup_as}
                  >
                    {/* <option  disabled defaultValue selected> */}
                    <option>Select option</option>
                    <option>Mover</option>
                    <option>Packer</option>
                    <option>O/F Agent</option>
                    <option>Airlines</option>
                    <option>Courier</option>
                  </select>
                  <div
                    id="validationServerUsernameFeedback"
                    style={{ fontSize: 14 }}
                    className="mt-2 text-danger"
                  >
                    {error?.signup_as}
                  </div>
                </div>
                <div>
                  <label
                    className="mb-1 mt-3"
                    for="exampleFormControlTextarea1"
                  >
                    Message
                  </label>
                  <textarea
                    name="message"
                    className={`form-control input-bg border-0 button-border subtext-color ${error.message? "border-danger": null}`}
                    // id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="e.g. Hi moversly team i would like to sign up with you as a mover."
                    onChange={handleMessageChange}
                    id="message"
                    value={data?.message}
                    error={error.message}
                  />
                </div>
                {/* {error.message && <div className="invalid-feedback">
                    {error?.message}
                </div>} */}
                <div
                  id="validationServerUsernameFeedback"
                  style={{ fontSize: 14 }}
                  className="text-danger mt-2"
                >
                  {error?.message}
                </div>
                <div className="my-3" style={{ textAlign: "center" }}>
                  <Button title={"Submit"} onClick={handleSubmit} isLoading={isLoading} />
                </div>
              </div>
                   </div>
                </div>
            </div>
        </div>
    </>
    )
}
