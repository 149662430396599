export default function Spinner(props) {

    const {
        active
    } = props;

    if (active) {
        return (
            <div className="spinner-grow spinner-grow-sm" role="status">
                <span className="sr-only"></span>
            </div>
        )
    }
    else return null;
}