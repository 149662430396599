import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { CloudUpload, InsertPageBreak, Upload } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { fetchBookerLeads } from "../../../redux/actions/orderAction";
import { lowerCase, startCase } from "lodash";
import OrderHeader from "./OrderHeader";

const AllOrders = (props) => {
  const { setShowHeaderFooter, setPage } = props;
  const history = useHistory();
  const { matching_orders, isLoading } = useSelector((state) => state?.order);
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const [requestId, setRequestId] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    user?.email && dispatch(fetchBookerLeads(user?.email));
  }, [user]);

  const tableOrders = () => {
    const data = matching_orders?.map((item, i) => {
      return {
        orderId: item?.id,
        shipped_from:
          item?.payload?.schedule_shipment?.from_address?.city || "NA",
        destination: item?.payload?.schedule_shipment?.to_address?.city || "NA",
        moving_type: item?.payload?.shipment_details?.shipment_mode || "NA",
        status: startCase(item?.order_tracking_state) || "NA",
        create_date: item?.create_date,
      };
    });
    return data;
  };

  useEffect(() => {
    setShowHeaderFooter(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
  }, [error]);

  return (
    <Box
      sx={{
        // background: "#8276F4",
        height: "100vh",
      }}
    >
      <OrderHeader />
      <Card
        style={{
          background: "#EEEBFC",
          width: "80%",
          borderRadius: 10,
          margin: "-60px auto 40px auto",
          padding: "20px 20px",
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
            }}
          >
            Track Your Order
          </Typography>
          <Typography
            variant="caption"
            gutterBottom
            sx={{
              fontWeight: "bold",
            }}
          >
            Now track your shipment real-time with Moversly.
          </Typography>

          <Box
            component={"div"}
            sx={{
              background: "white",
              borderRadius: 25,
              margin: "20px auto 40px auto",
              width: "60%",
              display: "flex",
              alignItems: "center",
              padding: "0px 0px 0px 30px",
              height: 50,
            }}
          >
            <input
              placeholder="Enter Your Order Number"
              style={{
                width: "80%",
                outline: "none",
                border: "none",
                background: "transparent",
              }}
              onChange={(e) => setRequestId(e.target.value)}
            />
            <Button
              // component={Link}
              sx={{
                background: "#8276F4",
                color: "white",
                width: "20%",
                height: 47,
                borderTopRightRadius: 25,
                borderBottomRightRadius: 25,
                cursor: "pointer",
                "&:hover": {
                  background: "#4f4562",
                },
              }}
              // disabled={requestId === ""}
              onClick={() => {
                if (requestId === "") {
                  setError("Order Id Can not be empty");
                } else {
                  history.push(`/order/track/${requestId}`, requestId);
                }
              }}
              // to={{
              //   pathname: `/order/track/${requestId}`,
              //   state: requestId,
              // }}
            >
              Track Now
            </Button>
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              color: "red",
              fontSize: 20,
            }}
          >
            {error}
          </Typography>
        </CardContent>
      </Card>
      <Card
        style={{
          background: "#EEEBFC",
          width: "80%",
          borderRadius: 10,

          margin: "20px auto 40px auto",
          padding: "20px 20px",
        }}
      >
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Order History
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          {/* orer history table */}
          <table className="separatetable">
            <thead className="tablehead">
              <tr className="tableheadrow">
                <th className="tablehead">Order Id</th>
                <th className="tablehead">Shipped From</th>
                <th className="tablehead">Moving Type</th>
                <th className="tablehead">Destination</th>
                {/* <th className={classes?.tablehead}>Rates</th>
              <th className={classes?.tablehead}>Accepted Rate</th> */}
                <th className="tablehead" style={{ textAlign: "center" }}>
                  Status
                </th>
                <th className="tablehead"> Action</th>
              </tr>
            </thead>
            <tbody className="tablebody">
              {tableOrders()
                ?.sort((a, b) => b.created_date - a.created_date)
                ?.map((data, i) => {
                  // console.log(data);
                  return (
                    <tr
                      // onClick={() => handleClick(data)}
                      key={i}
                      className="tablerow text-secondary-blue text-left text-bold"
                      style={{
                        background: "#CAC5F5",
                      }}
                    >
                      <td className="d-block d-md-none">
                        <div
                          className="mobiletableitem bg-secondary-blue text-white d-flex justify-content-between"
                          style={{ background: " #4f4562" }}
                        >
                          <div style={{ width: "50%" }}>{data?.orderId}</div>
                        </div>
                      </td>
                      <td
                        data-label="Order Id: "
                        className="tableitemtitle d-none d-md-block fw-bold text-capitalize"
                      >
                        {data?.orderId}
                      </td>
                      <td
                        data-label="Shipped From: "
                        className="tableitem"
                        style={{
                          fontSize: 16,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {data?.shipped_from}
                        </div>
                      </td>
                      <td data-label="Move Type: " className="tableitem">
                        <span
                          style={{
                            // color: determineTextColor(data?.destination),
                            fontSize: "20px",
                          }}
                        >
                          {data?.moving_type}
                        </span>
                      </td>

                      <td
                        data-label="Destination: "
                        className="tableitem d-none d-md-block"
                        style={{
                          fontSize: 16,
                        }}
                      >
                        {data?.destination}
                      </td>
                      <td
                        data-label="status: "
                        className="tableitem text-center"
                      >
                        <span
                          style={{
                            fontSize: 16,
                            // color: determineTextColor(data?.quote_status),
                          }}
                        >
                          {data?.status}
                        </span>
                      </td>
                      <td
                        data-label="Action: "
                        className="tableitem text-center d-flex"
                      >
                        <IconButton
                          onClick={() =>
                            history.push(
                              `/upload/${data?.orderId}`,
                              data?.orderId
                            )
                          }
                        >
                          <Tooltip title="upload Custom Documents">
                            <CloudUpload />
                          </Tooltip>
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            history.push(
                              `/order/survey-list/${data?.orderId}`,
                              data?.orderId
                            )
                          }
                        >
                          <Tooltip title="View Survey List">
                            <InsertPageBreak />
                          </Tooltip>
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AllOrders;
