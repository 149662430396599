import React,{useState,useEffect} from 'react'
import Button from "../../common/components/Button"
import TextInput from "../../common/components/TextInput";
import { useHistory } from "react-router";
import {
  makeStyles,
  Typography,
  Grid,
  Divider,
  Input,
} from "@material-ui/core";
import PhoneInput from "../../common/components/PhoneInput";
import Checkbox from "../../common/components/Checkbox";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Helper, { HelperWithUrl } from "../../common/consts/helper";
import LocationModal from "../home/components/LocationModal";
import { useToasts } from "react-toast-notifications";



const useStyles = makeStyles((theme) => ({
  root: {
    width: 960,
    height: 700,
  },
  
  details: {
    maxWidth: 350,
    marginright: "0 auto",
    marginLeft: 0,
    alignItems: "center",
    padding: theme.spacing(0, 2),
    paddingBottom: 15,
    paddingTop:15,
    fontsize: 25,
    fontFamily: "'Montserrat', sans-serif",
  },
  detail:{
    marginLeft: 20,
  },
 
}));

const AppointmentResponse = () => {
const classes = useStyles();
const history = useHistory()
  console.log(history)
  const pathname=history?.location?.pathname
  const Id = pathname.split("/").join().slice(26,67).split(",appointment,");
  console.log(Id)
 
  const orderId = Id[0];
  const appointmentId =Id[1];
  console.log(appointmentId)
  console.log(orderId)

  const requestId=pathname.split("/").join().slice(42,200).split(",id,")[1];
  console.log(requestId)

  async function accept(){
    try {
      const payload={
        order_code: orderId,
        apppointment_code: appointmentId,
        request_id: requestId,
      }
      const response = await HelperWithUrl(`http://api.moversly.com/api/v1/appointment/accept/${orderId}/${appointmentId}?request-id=${requestId}`,payload)
      console.log(response)
    } catch (error) {
      
    }
  }

    return (
        <div>
          <h3 className={classes.details}>Thanks for accepting</h3>
          <p className={classes.detail}>Your Appointment Id is {appointmentId}</p>
          <p className={classes.detail}>Your Order Id is {orderId}</p>
        </div>
    )
}

export default AppointmentResponse
