import ActionTypes from '../actions/types';
import { SHIPMENT_REDUCER } from '../../common/consts/emptyStates';

const initialState = {
    ...SHIPMENT_REDUCER
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.HANDLE_SHIPMENT:
            return {
                ...state,
                [payload.name]: payload.value
            }
        case ActionTypes.SET_CLIENT_SECRET:
            return {
                ...state,
                [payload.name]: payload.value
            }
        case ActionTypes.RESET_SHIPMENT_REDUCER:
        case ActionTypes.LOAD_PREVIOUS_SHIPMENT:
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}