import ActionTypes from './types';

export const handleUserState = (name, value) => ({
    type: ActionTypes.HANDLE_USER,
    payload: {
        name,
        value
    }
});

export const handleUserMultiState = (values) => ({
    type: ActionTypes.HANDLE_USER_MULTI_STATE,
    payload: values
})