import React, { useEffect, useState } from "react";
import {
  Box,
  Button as Btn,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import OrderHeader from "./OrderHeader";
import { startCase, sum } from "lodash";
import Helper from "../../../common/consts/helper";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../../common/components/Button";
import ImageModal from "./ImageModal";

const SurveyList = (props) => {
  const { setShowHeaderFooter } = props;
  const history = useHistory();
  const order_id = history?.location?.state;

  console.log(order_id);
  const shipment = useSelector((state) => state?.shipment);
  const { shipment_details, shipment_mode } = shipment;
  const [sub_categories, setSub_categories] = useState([]);
  const [surveySum, setSurveySum] = useState(0);
  const [surveys, setSurveys] = useState({});
  const [leadsById, setLeadsById] = useState({});
  const [surveyType, setSurveyType] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [imageArray, setImageArray] = useState({});
  const [selectedRoom, setSelectedRoom] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getOrdersById = async () => {
    const response = await Helper(`order/${order_id}`);
    setIsLoading(true);
    if (response) {
      setIsLoading(false);
      //   console.log(response);
      setLeadsById(response);
    }
  };

  const moving_type =
    leadsById?.payload?.shipment_details?.shipment_mode === "SEA"
      ? startCase(
          leadsById?.payload?.shipment_details?.sea?.move_type?.moving_type
        )
      : startCase(
          leadsById?.payload?.shipment_details?.road?.move_type?.moving_type
        );

  const getTotalVolume = (data, fields) => {
    let total = 0;

    if (data.length) {
      const itemMultiply = data.map((item) => {
        return item[fields[0]] * item[fields[1]];
      });
      return sum(itemMultiply);
    }
    return total;
  };
  useEffect(() => {
    getOrdersById();
    setShowHeaderFooter(false);
  }, []);
  useEffect(() => {
    let selectedSurvey =
      leadsById?.payload?.shipment_details?.shipment_mode === "SEA"
        ? leadsById?.payload?.shipment_details?.sea?.survey
            ?.pre_move_survey_type
        : leadsById?.payload?.shipment_details?.road?.survey
            ?.pre_move_survey_type;
    let surveyType = "";
    if (selectedSurvey === "SELF") {
      surveyType = "self_survey";
    }
    if (selectedSurvey === "COMPANY") {
      surveyType = "company_survey";
    }

    if (selectedSurvey === "NO") {
      surveyType = "no_survey";
    }

    if (selectedSurvey === undefined) {
      surveyType = "no_survey";
    }
    setSurveyType(surveyType);
    // dispatch(handleShipmentState("surveyType", surveyType));
  }, [surveyType, leadsById]);

  useEffect(() => {
    if (surveys) {
      const sub_categories = Object.keys(surveys);
      setSub_categories(sub_categories);
      const totalSumArray = sub_categories.map((category) => {
        const newData = getTotalVolume(surveys[category], [
          "count",
          "magnitude",
        ]);
        return newData;
      });

      const theSum = sum(totalSumArray).toFixed(2);
      setSurveySum(theSum);
    }
  }, [surveys]);

  useEffect(() => {
    if (leadsById?.payload?.shipment_details?.shipment_mode === "SEA") {
      setSurveys(
        leadsById?.payload?.shipment_details?.sea?.survey?.[surveyType]?.items
      );
    } else {
      setSurveys(
        leadsById?.payload?.shipment_details?.road?.survey?.[surveyType]?.items
      );
    }
  }, [leadsById, surveyType]);

  return (
    <Box
      sx={{
        // background: "#8276F4",
        height: "100%",
      }}
    >
      <OrderHeader order_id={order_id} />

      <Card
        style={{
          background: "#EEEBFC",
          width: "80%",
          borderRadius: 10,
          margin: "-60px auto 40px auto",
          padding: "20px 20px",
        }}
      >
        <CardContent>
          {sub_categories?.length > 0 && !isLoading ? (
            <div
              className="scroll edit-table"
              style={{
                maxWidth: " 120%",
                width: "120%",
              }}
            >
              <Table sx={{ marginTop: 10 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 20,
                        width: "100%",
                        background: "#e4deee",
                      }}
                    >
                      <TableCell
                        align="center"
                        style={{
                          fontSize: 12,
                          textTransform: "capitalize",
                          display: "flex",
                          width: "3%",
                        }}
                      >
                        #
                      </TableCell>
                      <div
                        style={{
                          width: "5%",
                          fontWeight: "500",
                          fontSize: 12,
                          textTransform: "capitalize",
                          display: "flex",
                        }}
                      >
                        <TableCell align="center">Category</TableCell>
                      </div>

                      <TableCell
                        align="center"
                        style={{
                          width: "18%",
                          fontSize: 14,
                        }}
                      >
                        Sub Category
                      </TableCell>

                      <TableCell align="center" style={{ width: "10%" }}>
                        Item Name
                      </TableCell>
                      <div
                        style={{
                          width: "47%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            width: "100%",
                          }}
                        >
                          <TableCell
                            align="center"
                            style={{
                              fontSize: 14,
                              width: "5%",
                            }}
                          >
                            Quantity
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              width: "5%",
                              //  marginLeft: -80
                            }}
                          >
                            Volume(CFT)
                          </TableCell>
                          <TableCell
                            align="center"
                            style={
                              {
                                // marginLeft: -60,
                              }
                            }
                          >
                            Total Volume(CFT)
                          </TableCell>

                          <TableCell
                            align="center"
                            style={
                              {
                                // width: "45%",s
                                // marginLeft: -60,
                              }
                            }
                          >
                            Photo
                          </TableCell>

                          <TableCell
                            align="center"
                            style={
                              {
                                // width: "25%"
                              }
                            }
                          ></TableCell>
                        </div>
                      </div>
                    </div>
                  </TableRow>
                </TableHead>

                <TableBody align="center">
                  {/* <TableCell>{moving_type}</TableCell> */}
                  {sub_categories.map((item, index) => (
                    <TableRow>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 20,
                          width: "100%",
                        }}
                      >
                        <TableCell
                          align="center"
                          padding="none"
                          style={{
                            width: "2%",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="center"
                          padding="none"
                          style={{
                            width: "5%",

                            // height: 50,

                            marginBottom: 20,
                          }}
                        >
                          {moving_type}
                        </TableCell>
                        <div
                          style={{
                            display: "flex",
                            width: "75%",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            padding="none"
                            style={{ width: "25%" }}
                          >
                            {item}
                          </TableCell>
                          <div
                            style={{
                              width: "75%",
                            }}
                          >
                            {surveys?.[item]?.map((itm, indx) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                                key={indx}
                              >
                                <TableCell
                                  align="center"
                                  padding="none"
                                  style={{ width: "17%" }}
                                >
                                  {itm.item}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  size="small"
                                  padding="none"
                                  style={{ width: "5%" }}
                                >
                                  {itm.count}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  size="small"
                                  padding="none"
                                  style={{ width: "5%" }}
                                >
                                  {itm.magnitude}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  size="small"
                                  padding="none"
                                  style={{
                                    width: "5%",
                                  }}
                                >
                                  {parseFloat(
                                    itm.magnitude * itm?.count
                                  ).toFixed(2)}
                                </TableCell>

                                <TableCell size="small" padding="none">
                                  <Button
                                    title={
                                      itm?.image_id?.length > 0
                                        ? "View Photos"
                                        : "No Photos"
                                    }
                                    style={{
                                      width: 150,
                                      padding: 0,
                                      fontSize: 14,
                                    }}
                                    onClick={() => {
                                      if (itm?.image_id?.length > 0) {
                                        openImageModal(itm, item); // to be worked on
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell></TableCell>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </TableRow>
                  ))}
                </TableBody>
                {/* custom table item */}
              </Table>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItem: "center",
                justifyContent: "center",
              }}
            >
              {isLoading ? (
                // <CircularProgress size={40} />
                <h1
                  style={{
                    textAlign: "center",
                  }}
                >
                  Fetching List .....
                </h1>
              ) : (
                <h1
                  style={{
                    textAlign: "center",
                  }}
                >
                  No Survey List Found
                </h1>
              )}
            </div>
          )}
        </CardContent>
      </Card>

      {selectedRoom !== "" && (
        <ImageModal
          onClose={() => closeImageModal()}
          open={imageModal}
          images={imageArray}
          currentImageIndex={currentImageIndex}
          selectedRoom={selectedRoom}
          setCurrentImageIndex={setCurrentImageIndex}
        />
      )}
    </Box>
  );

  function openImageModal(image, room) {
    setImageModal(true);
    setImageArray(image);
    setCurrentImageIndex(0);
    setSelectedRoom(room);
  }
  function closeImageModal() {
    setImageModal(false);
    setImageArray({});
    setCurrentImageIndex(null);
    setSelectedRoom("");
  }
};

export default SurveyList;
