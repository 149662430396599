import { useLocation } from 'react-router-dom';

export default function PrivacyPolicy({ setShowHeaderFooter }) {
    const location = useLocation();
    if (location.pathname === "/privacy-policy") {
        setShowHeaderFooter(false);
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 py-5">
                    <div className="privacy-heading">
                        Moversly.com Privacy Policy
                    </div>
                    <div className="privacy-content">
                        We at moversly.com highly respect your privacy. We understand how important your personal information is to you and our privacy policy explains how we collect personal information, and how we maintain, use, disclose and deal with personal information we gather from individuals during our business. We encourage our customers to read our privacy policy carefully. By accessing the services provided by moversly.com on our website www.moversly.com you agree to the collection and use of your data/information by Moversly.com as stated in our Privacy Policy. The Privacy Policy applies to the website Moversly.com and all products and services offered. The Moversly.com will not sell, rent, or lease your personal information to others. We encourage you to review the privacy policy of our company before submitting your personal information.
                    </div>

                    <div className="privacy-heading">
                        Personal Information
                    </div>
                    <div className="privacy-content">
                        All personal information that moversly.com collects is non-intrusive and with your consent, to be able to provide our services which you have requested. Personal information means information about you which can be ascertained. Personal information refers to your name or personal identity.
                    </div>

                    <div className="privacy-heading">
                        Need for Privacy Policy
                    </div>

                    <div className="privacy-content">
                        <ul>
                            <li>
                                We want our customers to be comfortable browsing our website.
                            </li>
                            <li>
                                We want our customers to feel secure navigating our website.
                            </li>
                            <li>
                                We want our customers to feel free to contact us for any privacy-related queries.
                            </li>
                        </ul>
                    </div>

                    <div className="privacy-heading">
                        Information We collect
                    </div>

                    <div className="privacy-content">
                        The Privacy Policy covers personal information and non-personal data collection. We at moversly.com take your trust in us seriously. The types of personal information we collect include name, phone number, address, e-mail ids, user IDs and passwords, billing and transaction information, contact preferences, credit card information, educational and employment background, and job application data. Moversly.com collects non-personal data which is limited to the data about service operation and usage not associated with any specific identity. The information we collect includes the pages visited on moversly.com websites, any other unique URLs visited within our website, browser type, and IP address. Most non-personal data is collected via cookies. Moversly.com does not collect information about your political, philosophical, religious beliefs, or sexual preferences.
                    </div>

                    <div className="privacy-heading">
                        How we use of Information
                    </div>

                    <div className="privacy-content">
                        Moversly.com uses your personal details for the purpose for which it was provided to us, other related purposes, and as permitted or required by law. We use personal information to help us provide you with improved services, conduct surveys, and get into touch with you when required or respond to your inquiry. We may also use your contact information internally to direct our efforts for product improvement, contact you as a survey respondent, share contact leads with movers, notify you for upcoming events/ promotions.
                    </div>

                    <div className="privacy-heading">
                        Information We Share
                    </div>

                    <div className="privacy-content">
                        Moversly.com does not use or store your financial information for any purpose other than to complete a transaction with you. We at Moversly.com do not disclose any of your personal information to third parties unless:
                        <ul className="mt-4">
                            <li>
                                To offer products or services you have requested.
                            </li>
                            <li>
                                To help investigate, prevent, or take action regarding unlawful and illegal activities, violations of moversly.com’s terms of use or to defend against legal claims.
                            </li>
                            <li>
                                To bill you for goods and services.
                            </li>
                            <li>
                                To Share contact leads with Movers.
                            </li>
                            <li>
                                To respond to the Government’s request/Statutory Authorities.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
