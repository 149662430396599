import ActionTypes from '../actions/types';

const initialState = {};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_CONFIG:
            return {
                ...state,
                ...payload,
            }
        default:
            return state;
    }
}