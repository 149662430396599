export const moversly_boxes_luggage = {
  moversly_boxes_luggage: {
    number_of_such_boxes: 1, // convert to number
    weight: {
      magnitude: "",
      unit: "KG",
    },
    dimensions: {
      length: "",
      width: "",
      height: "",
      units: "CM",
    },
    description: "", // currently ignored
  },
};

export const moversly_boxes_box = {
  number_of_such_boxes: "", // convert to number
  weight: {
    magnitude: "",
    unit: "KG",
  },
  dimensions: {
    length: "",
    width: "",
    height: "",
    units: "CM",
  },
  description: "Large",
  custom: true,
};

export const SHIPMENT_REDUCER = {
  order_id: "",
  fragment_edit: true,
  schedule_shipment: {
    from_address: {
      street_lines: "",
      city: "",
      postal_code: "",
      country_code: "",
      coordinates: {
        latitude: "",
        longitude: "",
      },
    },
    to_address: {
      street_lines: "",
      city: "",
      postal_code: "",
      country_code: "",
      coordinates: {
        latitude: "",
        longitude: "",
      },
    },
    moving_date: new Date(),
  },
  shipment_details: {
    shipment_mode: "AIR", // sea air road

    // form 2
    moving_type: "", // dependant    // all
    need_packing: false, // all
    premove_survey: "", // only in case of sea
    container_type: "", // only in case of sea
    item_description: "",
    moversly_boxes: [
      {
        number_of_such_boxes: "", // convert to number
        weight: {
          magnitude: "",
          unit: "KG",
        },
        dimensions: {
          length: "33",
          width: "46",
          height: "38",
          units: "CM",
        },
        description: "Small",
      },
      {
        number_of_such_boxes: "", // convert to number
        weight: {
          magnitude: "",
          unit: "KG",
        },
        dimensions: {
          length: "46",
          width: "46",
          height: "51",
          units: "CM",
        },
        description: "Medium",
      },
      {
        number_of_such_boxes: "", // convert to number
        weight: {
          magnitude: "",
          unit: "KG",
        },
        dimensions: {
          length: "46",
          width: "46",
          height: "76",
          units: "CM",
        },
        description: "Large",
      },
    ],
    moversly_boxes_luggage: [
      {
        ...moversly_boxes_luggage.moversly_boxes_luggage,
      },
    ],
  },
  rate: {
    currency: "",
    amount: "",
  },
  request_id: "",
  clientId: "",
  customer_order_state: "",
};

export const profile_details = {
  email: "",
  phone: "",
  password: "",
  address: "",
  given_name: "",
  family_name: "",
  country_code: "",
};

export const USER_REDUCER = {
  ...profile_details,
};
