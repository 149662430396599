import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Input, TextField, Typography } from "@material-ui/core";
import { debounce } from "lodash";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { MAP_API_KEY } from "../../../common/consts/config";
import Spinner from "../../../common/components/Spinner";
import LocationListItem from "./LocationListItem";
import GoogleMapReact from "google-map-react";
import messages from "../../../common/consts/messages";
import { useToasts } from "react-toast-notifications";
import produce from "immer";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { ImLocation2 } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { handleShipmentState } from "../../../redux/actions/shipmentActions";
import Button from "../../../common/components/Button";
import useFocusOnKeyDown from "react-focus-onkeydown";

// const MapPin = () => (
//   <img className="marker-pin-center" src={Pin} width="20" alt="map-pin" />
// );

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    // height: 500,
    [theme.breakpoints.down("md")]: {
      maxWidth: 275,
    },
  },
  header: {
    padding: theme.spacing(3),
    maxWidth: 500,
    margin: "0 auto",
  },
  content: {
    padding: theme.spacing(0, 2),
    maxWidth: 500,
    margin: "0 auto",
  },
  title: {
    color: "#4e3883",
    lineHeight: 1,
  },
  input: {
    // width: 350,
    marginBottom: 10,
  },
  spinner: {
    textAlign: "center",
  },
  locationItem: {
    maxHeight: 300,
    overflowY: "scroll",
  },
  inputContainer: {
    // backgroundColor: "#4E3883",
    marginBottom: 20,
    margin: "0 20px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  map: {
    width: "100%",
    height: 300,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
}));

const LocationModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    className,
    setLocationModal,
    addressType,
    locationModal,
    ...rest
  } = props;
  const [address, setAddress] = useState({ place_id: "" });
  const [addressDetails, setAddressDetails] = useState({});
  const [pinCode, setPinCode] = useState("");
  const [value, setValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [currentAddress, setCurrentAddress] = useState("");
  const [postalCode, setPostalCode] = useState(pinCode);

  const ref = useRef(null);
  useFocusOnKeyDown(ref);

  const [errors, setErrors] = useState({
    currentAddress: "",
    pinCode: "",
  });
  const [validationFired, setValidationFired] = useState(false);
  const [findPostalCode, setFindPostalCode] = useState(false);
  const { addToast } = useToasts();

  const classes = useStyles();

  const getLocations = debounce((value) => getLocationsApi(value), 1000);
  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: MAP_API_KEY,
  });
  const dispatch = useDispatch();

  const schedule_shipment = useSelector(
    (state) => state.shipment?.schedule_shipment
  );

  useEffect(() => {
    if (placePredictions.length) {
      setIsLoading(false);
      setLocations(placePredictions);
    }
  }, [placePredictions]);

  function getLocationsApi(value) {
    if (value.length > 2) {
      setIsLoading(true);
      getPlacePredictions({ input: value });
    }
  }

  useEffect(() => {
    if (address?.place_id) {
      geocodeByPlaceId(address?.place_id)
        .then((results) => {
          getLatLng(results[0])
            .then((response) => {
              results[0].geometry.location = response;
              setAddressDetails(results[0]);
              if (
                results[0].address_components[
                  results[0].address_components?.length - 1
                ]?.types[0] === "postal_code"
              ) {
                setPinCode(
                  results[0].address_components[
                    results[0].address_components?.length - 1
                  ]?.long_name
                );
              }
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.error(error));
    }
  }, [address]);

  useEffect(() => {
    setPinCode(postalCode);
  }, [postalCode]);

  useEffect(() => {
    const coordinates = schedule_shipment?.from_address?.coordinates;
    const coordinates2 = schedule_shipment?.to_address?.coordinates;
    if (addressType === 0) {
      fetchPostalCode(coordinates);
    } else {
      fetchPostalCode(coordinates2);
    }
  }, [
    schedule_shipment?.from_address?.coordinates,
    schedule_shipment?.to_address?.coordinates,
  ]);

  useEffect(() => {
    if (validationFired) {
      validate();
    }
  }, [validationFired, currentAddress, pinCode]);

  function validate() {
    setValidationFired(true);
    let validated = true;

    const localErrors = {
      currentAddress: "",
      pinCode: "",
    };

    // if (currentAddress == "") {
    //   validated = false;
    //   localErrors.currentAddress = "Field Required";
    // }
    // if (pinCode === "") {
    //   validated = false;
    //   localErrors.currentAddress = "Field Required";
    // }

    setErrors(localErrors);
    return validated;
  }

  function submitMap() {
    if (validate()) {
      handleAddress(
        currentAddress === ""
          ? ` ${addressDetails?.formatted_address}`
          : `${currentAddress}, ${addressDetails?.formatted_address}`,
        pinCode
      );
      setLocationModal(0);
      setLocations([]);
      setCurrentAddress("");
    }
  }
  function fetchPostalCode(coordinates) {
    if (findPostalCode) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates?.latitude},${coordinates?.longitude}&key=${MAP_API_KEY}&result_type=postal_code`
      )
        .then((response) => response.json())
        .then((response) => {
          if (response?.results && response?.results[0]?.address_components) {
            const address_components = response?.results[0]?.address_components;
            const postalCode = address_components?.find((e) =>
              e.types?.includes("postal_code")
            )?.long_name;
            if (postalCode) {
              setPinCode(postalCode);
            }
          }
        })
        .catch((error) => {
          addToast(messages.postalCodeError, { appearance: "error" });
        });
    }
  }

  function formatCountryCode(address) {
    let add = address.find(
      (element) =>
        (element?.types?.includes("political") ||
          element?.types?.includes("POLITICAL")) &&
        (element?.types?.includes("country") ||
          element?.types?.includes("COUNTRY"))
    );
    return add?.short_name || "";
  }

  function formatCity(address) {
    let add = address.find(
      (element) =>
        (element?.types?.includes("political") ||
          element?.types?.includes("POLITICAL")) &&
        (element?.types?.includes("locality") ||
          element?.types?.includes("LOCALITY"))
    );
    return add?.long_name || "";
  }

  function handleAddress(address, zip) {
    handleChange(
      "schedule_shipment",
      produce(schedule_shipment, (draft) => {
        if (addressType === 0) {
          draft.from_address.street_lines = address;
          draft.from_address.postal_code = zip;
          draft.from_address.country_code = formatCountryCode(
            addressDetails?.address_components || []
          );
          draft.from_address.city = formatCity(
            addressDetails?.address_components || []
          );
        } else {
          draft.to_address.street_lines = address;
          draft.to_address.postal_code = zip;
          draft.to_address.country_code = formatCountryCode(
            addressDetails?.address_components || []
          );
          draft.to_address.city = formatCity(
            addressDetails?.address_components || []
          );
        }
      })
    );
  }

  function handleCoords(center) {
    handleChange(
      "schedule_shipment",
      produce(schedule_shipment, (draft) => {
        if (addressType === 0) {
          draft.from_address.coordinates.longitude = center?.lng;
          draft.from_address.coordinates.latitude = center?.lat;
        } else {
          draft.to_address.coordinates.longitude = center?.lng;
          draft.to_address.coordinates.latitude = center?.lat;
        }
      })
    );
  }

  function handleChange(name, value) {
    dispatch(handleShipmentState(name, value));
  }

  return (
    <Dialog
      maxWidth="md"
      onClose={onClose}
      open={locationModal !== 0 ? true : false}
    >
      {locationModal === 1 ? (
        <div {...rest} className={clsx(classes.root, className)}>
          <div className={classes.header}>
            <Typography
              align="center"
              className={classes.title}
              gutterBottom
              variant="h5"
            >
              Search Location
            </Typography>
            <Typography
              align="center"
              className={classes.title}
              gutterBottom
              variant="body1"
            >
              Type a post code or part of customers address to begin with
            </Typography>
          </div>
          <div className={classes.content}>
            <div className={classes.inputContainer}>
              <Typography gutterBottom variant="body1">
                Search Location
              </Typography>
              <Input
                className={classes.input}
                name="name"
                fullWidth
                inputRef={ref}
                autoFocus
                // disableUnderline
                placeholder="e.g. 84, Princess Street"
                //   value={data.firstName}
                //   onChange={(e) => console.log(e.target.value)}
                onChange={(e) => getLocations(e.target.value)}
              />
              <div className={classes.spinner}>
                <Spinner active={isLoading} />
              </div>
            </div>
          </div>
          <div className={classes.locaationItem}>
            {locations
              ? locations
                  ?.filter((e) => e?.terms[e.terms?.length - 1]?.offset > 15)
                  ?.map((loc, index) => {
                    return (
                      <LocationListItem
                        key={index}
                        location={loc}
                        onClick={() => {
                          setAddress(loc);
                          setLocationModal(2);
                        }}
                      />
                    );
                  })
              : null}
          </div>
          <div
            style={{
              textAlign: "end",
              paddingRight: 30,
              marginBottom: 10,
            }}
          >
            <Button
              title="Close"
              style={{
                width: "150px",
              }}
              onClick={() => {
                setLocationModal(0);
                setLocations([]);
              }}
            />
          </div>
        </div>
      ) : (
        <div {...rest} className={clsx(classes.root, className)}>
          <div className={classes.header}>
            <Typography
              align="center"
              className={classes.title}
              gutterBottom
              variant="h5"
            >
              {addressType === 0 ? "From" : "To"} Address
            </Typography>
            <Typography
              align="center"
              className={classes.title}
              gutterBottom
              variant="body2"
            >
              Please enter the required details.
            </Typography>
          </div>
          <div className={classes.map}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: MAP_API_KEY }}
              center={{
                lat: addressDetails?.geometry?.location?.lat || 59.95,
                lng: addressDetails?.geometry?.location?.lng || 30.33,
              }}
              defaultZoom={16}
              onChange={(details) => {
                handleCoords(details?.center);
                setFindPostalCode(true);
              }}
            />
            <ImLocation2 style={{ position: "absolute" }} />
          </div>
          <div className={`${classes.inputContainer} mt-3`} style={{}}>
            <Typography gutterBottom variant="body1">
              Enter Floor & House Number
            </Typography>
            <Input
              className={classes.input}
              autoFocus
              name=""
              // disableUnderline
              fullWidth
              placeholder="e.g. 84, Princess Street"
              onChange={(e) => {
                setCurrentAddress(e.target.value);
              }}
              error={errors.currentAddress}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography gutterBottom variant="body1">
              Enter Postal / Zip Code
            </Typography>
            <Input
              className={classes.input}
              fullWidth
              placeholder="e.g. 14402"
              onChange={(e) => {
                setPinCode(e.target.value);
              }}
              value={pinCode}
              error={errors.pinCode}
            />
          </div>

          <div className={`row mt-5 `}>
            <div
              className={`col-12 text-center d-flex ${classes.buttons}`}
              style={{ marginTop: -40, marginBottom: 20 }}
            >
              <Button
                type="light"
                fill={false}
                title="Close"
                style={{
                  width: "150px",
                }}
                onClick={() => {
                  {
                    setLocationModal(0);
                    setLocations([]);
                  }
                }}
              />
              <Button
                type="dark"
                title="Save"
                style={{
                  width: "150px",
                  marginLeft: "20px",
                }}
                onClick={submitMap}
              />
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default LocationModal;
