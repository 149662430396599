import React from 'react';
import Popup from 'reactjs-popup';

export default function TextInput(props) {

    const {
        label = "Label",
        placeholder = "e.g. john",
        inputProps,
        onChange,
        id,
        inputClassName,
        labelClassName,
        containerClassName,
        type = "text",
        value,
        error,
        rightIcon,
        onClick,
        noLabel,
        onEnterPress,
        popupNode
    } = props;

    return (
        <div
            className={`col-12 mb-3 ${containerClassName}`}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
        >
            {
                noLabel ? null : (
                    <div className={`col-12 fs-09 py-2 ${labelClassName}`}>
                        {label}
                        {
                            popupNode ? (
                                <Popup
                                    trigger={<i className="bi bi-info-circle-fill mx-2 cursor-pointer"></i>}
                                    position="bottom center"
                                    contentStyle={{
                                        minWidth: '300px'
                                    }}
                                >
                                    {popupNode}
                                </Popup>
                            ) : null
                        }
                    </div>
                )
            }
            <div className="position-relative">
                <input
                    className={`
                        col-12 px-3 fs-09 subtext-color input-bg border-0 button-border 
                        ${error ? 'is-invalid error-shadow' : ''} 
                        ${inputClassName} 
                        ${rightIcon ? 'ps-5 p-3' : 'py-2'}
                        ${type === 'date' ? 'mobile-date-picker' : ''}
                    `}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e.target.value, id);
                        }
                    }}
                    onKeyPress={(e) => {
                        if (e.charCode === 13 && onEnterPress) {
                            onEnterPress();
                        }
                    }}
                    {...inputProps}
                />
                {
                    (rightIcon) ? (
                        <i className={`${type === 'date' ? 'd-none d-lg-block' : ''} bi bi-${rightIcon} input-icon-position`}></i>
                    ) : null
                }
                <div id="validationServerUsernameFeedback" className="invalid-feedback">
                    {error}
                </div>
            </div>
        </div>
    )
}