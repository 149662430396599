import 'react-phone-number-input/style.css'
import Phone from 'react-phone-number-input'

export default function PhoneInput(props) {

    const {
        label = "Label",
        placeholder = "e.g. john",
        inputProps,
        onChange,
        id,
        inputClassName,
        labelClassName,
        containerClassName,
        value,
        onCountryChange,
        error
    } = props;

    return (
        <div className={`col-12 mt-3 ${containerClassName}`}>
            <div className={`col-12 fs-09 py-2 ${labelClassName}`}>{label}</div>
            <Phone
                placeholder={placeholder}
                value={value}
                onChange={(value) => {
                    if (onChange) {
                        onChange(value, id);
                    }
                }}
                onCountryChange={(country) => {
                    if (onCountryChange) {
                        onCountryChange(country);
                    }
                }}
                className={`col-12 px-3 py-2 fs-09 subtext-color input-bg border-0 button-border ${inputClassName} ${error ? 'is-invalid error-shadow' : ''}`}
                {...inputProps}
            />
            <div id="validationServerUsernameFeedback" className="invalid-feedback">
                {error}
            </div>
        </div>
    )
}
