import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// pages
import HomePage from "./pages/home";
import AuthPage from "./pages/signup";
import PricePacks from "./pages/packs";
import Profile from "./pages/profile";

// providers and parents
import Layout from "./Layout";
import ApiProvider from "./common/components/ApiProvider";
import { ToastProvider } from "react-toast-notifications";

// redux imports
import { Provider } from "react-redux";
// import store from "./redux/store";

// css files
import "./Main.css";



import "react-confirm-alert/src/react-confirm-alert.css";
import Orders from "./pages/orders";
import "reactjs-popup/dist/index.css";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsConditions from "./pages/termsConditions";
// navbar pages
import Customers from "./pages/customers";
import InternationalMoversPage from "./pages/InternationalMover";
import OceanFreightPage from "./pages/oceanFreight";
import TruckersPage from "./pages/truckerPage";
import PackerPage from "./pages/packerPage";
import AboutContactUS from "./pages/AboutSupport";
import QuoteResponse from "./pages/quoteResponse/QuoteResponse";
import AppointmentResponse from "./pages/appointmentResponse/AppointmentResponse";
import configureStore from "./redux/store";
import Tracking from "./pages/TrackOrders";
import AllOrders from "./pages/TrackOrders/components/AllOrders";
import TrackOrder from "./pages/TrackOrders/components/TrackOrder";
import DocsUpload from "./pages/TrackOrders/components/DocsUpload";
import SurveyList from "./pages/TrackOrders/components/SurveyList";
import QuoteAccepted from "./pages/quoteResponse/QuoteAccepted";
import LandingPage from "./pages/LandingPage/LandingPage";

const store = configureStore();
export default function App() {
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);

  useEffect(() => {
    console.clear();
  }, []);

  return (
    <Provider store={store}>
      <ToastProvider placement="bottom-center" autoDismiss>
        <ApiProvider>
          <Router>
            <Layout showHeaderFooter={showHeaderFooter}>
              <Switch>
                <Route
                  render={() => (
                    <LandingPage  setShowHeaderFooter={setShowHeaderFooter} />
                     //<HomePage setShowHeaderFooter={setShowHeaderFooter} />
                  )}
                  exact
                  path="/"
                />
                {/* <Route  render={ () => (<LandingPage  setShowHeaderFooter={setShowHeaderFooter} />)} path="/" /> */}
                <Route component={PricePacks} path="/pricepacks" />
                <Route component={AuthPage} path="/signup" />
                <Route component={AuthPage} path="/login" />
                <Route component={AuthPage} path="/forgot" />
                <Route component={AuthPage} path="/reset" />
                <Route component={Profile} path="/profile" />
                {/* <Route component={QuoteAccepted} path="/quote-accepted" /> */}
                <Route
                  render={() => (
                    <QuoteAccepted setShowHeaderFooter={setShowHeaderFooter} />
                  )}
                  exact
                  path="/quote-accepted"
                />
                <Route
                  render={() => (
                    <Orders setShowHeaderFooter={setShowHeaderFooter} />
                  )}
                  exact
                  path="/orders"
                />
                <Route
                  render={() => (
                    <AllOrders setShowHeaderFooter={setShowHeaderFooter} />
                  )}
                  path="/orders/track"
                />
                <Route
                  render={() => (
                    <TrackOrder setShowHeaderFooter={setShowHeaderFooter} />
                  )}
                  exact
                  // path="/order/track/:id"
                  path="/customer/orderTrackingStateAndTime/orderId/:id"
                />
                <Route
                  render={() => (
                    <TrackOrder setShowHeaderFooter={setShowHeaderFooter} />
                  )}
                  exact
                  path="/order/track/:id"
                />
                <Route
                  render={() => (
                    <SurveyList setShowHeaderFooter={setShowHeaderFooter} />
                  )}
                  exact
                  path="/order/survey-list/:id"
                />
                <Route
                  render={() => (
                    <DocsUpload setShowHeaderFooter={setShowHeaderFooter} />
                  )}
                  exact
                  path="/upload/:id"
                />
                <Route component={Customers} path="/customers" />
                <Route component={OceanFreightPage} path="/ocean_freight" />
                <Route path="/quote/accept/:id">
                  <QuoteResponse setShowHeaderFooter={setShowHeaderFooter} />
                </Route>
                <Route path="/appointment/accept/:id">
                  <AppointmentResponse
                    setShowHeaderFooter={setShowHeaderFooter}
                  />
                </Route>
                <Route
                  component={InternationalMoversPage}
                  path="/international_mover"
                />
                <Route component={TruckersPage} path="/trucker" />
                <Route component={PackerPage} path="/packer" />
                <Route component={AboutContactUS} path="/about" />
                <Route component={AboutContactUS} path="/contact_us" />
                <Route
                  render={() => (
                    <PrivacyPolicy setShowHeaderFooter={setShowHeaderFooter} />
                  )}
                  path="/privacy"
                />
                <Route
                  render={() => (
                    <PrivacyPolicy setShowHeaderFooter={setShowHeaderFooter} />
                  )}
                  path="/privacy-policy"
                />
                <Route
                  render={() => (
                    <TermsConditions
                      setShowHeaderFooter={setShowHeaderFooter}
                    />
                  )}
                  path="/terms-conditions"
                />
              </Switch>
            </Layout>
          </Router>
        </ApiProvider>
      </ToastProvider>
    </Provider>
  );
}
