export default function Checkbox(props) {
  const {
    placeholder = "I agree to Moversly's Terms & Conditions",
    inputProps,
    onChange,
    id,
    inputClassName,
    labelClassName,
    containerClassName,
    value,
    error,
    checked = false,
  } = props;

  return (
    <div className={`col-12 mt-3 ms-2 ${containerClassName}`}>
      <div className="form-check">
        <input
          className={`form-check-input ${error ? "is-invalid" : ""}`}
          type="checkbox"
          value={value}
          checked={checked}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target?.checked, id);
            }
          }}
          {...inputProps}
        />
        <label className="form-check-label">{placeholder}</label>
        <div className="invalid-feedback">{error}</div>
      </div>
    </div>
  );
}
