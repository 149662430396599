export default function Features() {
    return (
        <div className="col-12 col-md-9 col-lg-5 p-4 mt-5">
            <div className="row">
                <img className="col-3 img-fluid border border-1" style={{borderRadius:"50px"}} src="./assets/icons/a-1.svg" alt="" />
                <h5 className="col-8 col-lg-7 fw-os-sb m-3">Increased Sales
                    <div className="fs-st fw-light lh-sm mb-3">
                        We channel client leads directly to your moving company. Higher lead capture equals to increased sales.
                    </div>
                </h5>
            </div>
            <div className="row mt-4">
                <img className="col-3 img-fluid border border-1" style={{borderRadius:"50px"}} src="./assets/icons/a-2.svg" alt="" />
                <h5 className="col-8 col-lg-7 fw-os-sb m-3">Increase Productivity
                    <div className="fs-st fw-light lh-sm">
                        We streamline lead generation and online estimations for your moving company, saving you precious time. Time is money!
                    </div>
                </h5>
            </div>
            <div className="row mt-4">
                <img className="col-3 img-fluid border border-1" style={{borderRadius:"50px"}} src="./assets/icons/a-3.svg" alt="" />
                <h5 className="col-8 col-lg-7 fw-os-sb m-3">Customer Satisfaction
                    <div className="fs-st fw-light lh-sm">
                        We guarantee better customer satisfaction as all information is digitally transferred eliminating delay in responses to customer queries.
                    </div>
                </h5>
            </div>
        </div>
    )
}