import { API_ROUTES } from "../../common/consts/config";
import Helper from "../../common/consts/helper";
import ActionTypes from "./types";

export const setIsLoading = (value) => ({
  type: ActionTypes.LOADING,
  payload: value,
});

export const getPackerLeads = (leads) => ({
  type: ActionTypes.GET_PACKER_LEADS,
  payload: leads,
});

export const fetchBookerLeads = (email) => async (dispatch) => {
  try {
    dispatch(setIsLoading({ name: "isLoading", value: true }));
    const response = await Helper(
      `${API_ROUTES.customerOrder.route}?customerEmail=${email}`
    );
    if (response) {
      // console.log(response);
      dispatch(setIsLoading({ name: "isLoading", value: false }));
      dispatch(getPackerLeads([...response]));
    } else {
      dispatch(setIsLoading({ name: "isLoading", value: false }));
    }
  } catch (error) {}
};
