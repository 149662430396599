import Header from './common/components/Header';
import Footer from './common/components/Footer';

export default function Layout(props) {
    const { showHeaderFooter } = props;

    return (
        <div>
            {
                showHeaderFooter ? <Header /> : null
            }


            {props.children}
            {
                showHeaderFooter ? <Footer /> : null
            }

        </div>
    )
}
