export default {
  loginError: "Unable to login, Please try again later!",
  loginSuccess: "Successfully Logged in!!",
  orderIdGenerateError: "Unable to generate Order ID, Please try again later!",
  networkStopped: "Please check your Internet Connection!",
  networkWorking: "Stable Internet Connection is Back!",
  quoteError:
    "Thank you for your booking request. Unfortunately, we do not have instant quotes for this particular location. One of our representatives will contact you shortly!",
  postalCodeError: "Unable to fetch Postal Code, Please Input the Postal Code.",
  confirmationError: "Unable to confirm, Please try again later!",
  resendConfirmation:
    "Unable to resend confirmation code, Please try again later!",
  forgotError: "Unable to send code, Please try again later!",
  resetError: "Unable to reset password, Please try again later!",
  confirmationDone: "Confirmed Email Succesfully!!",
  locationError: "Unable to fetch locations, Please try again later!",
  partnerSuccess:
    "Thank you for your reaching out to us. One of our representatives will contact you shortly!",
};
