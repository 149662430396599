export default function Banner(props) {
    const { title } = props;
    return (
        <div className="container-fluid bg-color-2 color-2 py-4">
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="col-6 my-auto">
                    <h1 className="col-12 fs-1">
                        {
                            title === undefined ? 'International Movers' : title
                        }    
                    </h1>
                    <h4 className="col-12 fs-4">Join now to get one month free usage.</h4>
                </div>
                <div className="col-6 col-lg-4">
                    <img className="img-fluid" src="./assets/images/image-2.png" alt="" />
                </div>
            </div>
        </div>
    </div>
);
}