
import Banner from "../../common/components/Banner";

export default function Customers() {
    
    return (
        <>
        <Banner title={'Customers'} />
        <div className="container">
            <div className="row">
                <div className="col-12 py-5">
                    <div className="privacy-heading">
                        Moving Internationally? Move Hassle-free with revolutionary technology like Moversly App! 
                    </div>
                    <div className="privacy-content">
                        Moversly simplifies international relocation for customers. It acts as an aggregator for movers, shippers, truckers, packers, ocean freight and air carrier agents, etc. With Moversly App customers get fast and convenient door-to-door delivery services for International Moving.
                        <p className='pt-2'>
                          No more calling hundreds of moving companies, verifying credibility, before you hire one! With us you get only trusted & reliable moving companies. Save your valuable time & get competitive moving quotes. You get access to a wide pool of moving companies & be rest assured as your goods will be in safe hands. Moversly assures you peace of mind as we have trusted brands as packers, truckers, carrier agents, etc. 
                        </p>    
                    </div>
                    <div className="privacy-heading">
                        Features
                    </div>

                    <div className="privacy-content">
                        <ul>
                            <li>
                                Affordable Prices 
                            </li>
                            <li>
                                Exclusive Offers 
                            </li>
                            <li>
                                Higher Transparency 
                            </li>
                            <li>
                                Door-to-Door Delivery Services
                            </li>
                            <li>
                                Customer Satisfaction
                            </li>
                            <li>
                                Real-Time Tracking
                            </li>
                            <li>
                                No-hidden Costs
                            </li>
                            <li>
                                User-Friendly App
                            </li>
                            <li>
                                Multi-platform support (iOS, & Android)
                            </li>
                        </ul>
                    </div>

                    <div className="privacy-heading">
                        How to Use? 
                    </div>

                    <div className="privacy-content">
                        <ul>
                            <li>
                            Tell us your moving to & from location
                            </li>
                            <li>
                            Choose the mode of shipment 
                            </li>
                            <li>
                            Fill in the shipment & customer details
                            </li>
                            <li>
                            Get a quote before you book
                            </li>
                            <li>
                            Make payment & track your shipment real-time 
                            </li>
                        </ul>
                    </div>
                    <div className="privacy-heading">
                        Why You Should Join Moversly? 
                    </div>
                    <div className="privacy-content">
                        <ul className="mt-4">
                            <li>
                                <strong> Affordable Prices –</strong> Get the most competitive prices for your International Move!
                            </li>
                            <li>
                                <strong> Exclusive Offers –</strong> Get exclusive offers only with Moversly App
                            </li>
                            <li>
                                <strong> Powerful & Reliable Software  –</strong> Revolutionary SaaS technology helping both customers & movers for fast door-to-door delivery services.
                            </li>
                            <li>
                                <strong> User-Friendly App –</strong> Simple navigation and 3 easy steps guide you to fulfil your International move. 
                            </li>
                            <li>
                                <strong> Choice of Pre-Move Survey –</strong> Now you can opt for self-survey, virtual survey, or chose not to opt for pre-move survey at all!
                            </li>
                            <li>
                                <strong> Real-Time Shipment Tracking –</strong> Get real-time notifications about your shipment delivery. 
                            </li>
                            <li>
                                <strong> Multi-platform support (iOS, & Android) –</strong>Available on both iOS & Android 
                            </li>
                            <li>
                                <strong> No Hidden Costs –</strong>Yes! Pay for exactly what you ship. 
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
