import ForgotForm from "./components/ForgotForm";
import { useEffect ,useSelector, useState} from "react";
import LoginForm from "./components/LoginForm";
import ResetForm from "./components/ResetPassword";
import SignupForm from "./components/SignupForm";


export default function SignupPage() {
    
    useEffect( () => {
        window.scrollTo(0,0);
    })


    return (

        <>
            
            <div className="container-fluid bg-color-2 color-2 py-4">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-6 my-auto">
                            <h1 className="col-12 fs-1">International Movers</h1>
                            <h4 className="col-12 fs-4">Join now to get one month free usage.</h4>
                        </div>
                        <div className="col-6 col-lg-4">
                            <img className="img-fluid" src="./assets/images/image-2.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-4 px-0 px-lg-5">
                {
                    renderForms()

                }
            </div>
        </>
    
    )

    function renderForms() {

        let pathname = window.location.pathname;
        if (pathname === "/login") {
            return <LoginForm />
        }
        else if (pathname === "/signup") {
            return <SignupForm />
        }
        else if (pathname === "/forgot") {
            return <ForgotForm />
        }
        else if (pathname === "/reset") {
            return <ResetForm />
        }
    }
}