import { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

// components
import BreadCrumb from "../../../common/components/BreadCrumb";
import Features from "../../../common/components/Features";
import Button from "../../../common/components/Button";
import TextInput from "../../../common/components/TextInput";

// constants
import Helper from "../../../common/consts/helper";
import { API_ROUTES, COMPONENT_DATA } from "../../../common/consts/config";
import { snakeCase, startCase } from "lodash";
import messages from "../../../common/consts/messages";
import {
  refreshToken,
  storageToken,
} from "../../../common/consts/browserVariables";

// redux
import { handleAuthState } from "../../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";

const initialErrors = COMPONENT_DATA?.login?.initialErrors || {};

export default function LoginForm(props) {
  let history = useHistory();

  const {
    isCustomerDetails,
    onLoginSuccess,
    setAuthSection,
    setActiveStep,
    generateOrderId,
    handleDB,
  } = props;

  const { addToast } = useToasts();

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    ...initialErrors,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [apiError, setApiError] = useState("");

  const [validationFired, setValidationFired] = useState(false);

  const dispatch = useDispatch();

  const [price, setPrice] = useState({});

  const shipment = useSelector((state) => state?.shipment);
  const from_address = useSelector(
    (state) => state?.shipment?.schedule_shipment?.from_address
  );
  const shipment_details = useSelector(
    (state) => state?.shipment?.shipment_details
  );

  useEffect(() => {
    if (validationFired) {
      validate();
    }
  }, [validationFired, data]);

  useEffect(() => {
    fetchPrice();
  }, []);

  const fetchPrice = async () => {
    let seaMoveTypeTitle = snakeCase(
      shipment_details?.moving_type?.toLowerCase()
    );
    seaMoveTypeTitle =
      seaMoveTypeTitle === "boxes" ? "moversly_boxes" : seaMoveTypeTitle;

    const mode = shipment_details?.shipment_mode;
    const selectedBoxes = shipment_details?.moversly_boxes.filter(
      (box) =>
        box.number_of_such_boxes !== "" && box.number_of_such_boxes !== "0"
    );
    const sea_move_type = {
      moving_type: shipment?.shipment_details?.moving_type,
      [seaMoveTypeTitle]: {
        description: shipment?.shipment_details?.item_description,
      },
    };
    const payload = {
      schedule_shipment: {
        from_address: shipment?.schedule_shipment?.from_address,
        to_address: shipment?.schedule_shipment?.to_address,
        moving_date: shipment?.schedule_shipment?.moving_date,
      },
      shipment_details: {
        shipment_mode: mode,
        [mode?.toLowerCase()]: {
          need_packing: shipment?.shipment_details?.need_packing,
          move_type:
            mode === "SEA"
              ? sea_move_type
              : {
                  moving_type: shipment_details?.moving_type,
                  [shipment_details?.moving_type?.toLowerCase()]: {
                    moversly_boxes:
                      shipment_details?.moving_type === "LUGGAGE"
                        ? shipment_details?.moversly_boxes_luggage
                        : selectedBoxes,
                  },
                },
          survey: {
            pre_move_survey_type: "SELF",
            self_survey: {},
          },
        },
      },
    };
    // console.log(payload)
    const response = await Helper(
      `order/pricing-estimate`,
      "POST",
      payload,
      false,
      false,
      false
    );
    if (response) {
      setPrice(response.price);
      console.log(response);
    }
  };

  return (
    <>
      {/* BreadCrubs */}
      <BreadCrumb
        list={[
          {
            title: "Home",
            link: "/",
          },
          {
            title: "Login",
            link: "/",
          },
        ]}
      />
      {/* form */}
      <div className="row d-flex justify-content-between px-2">
        <div
          className={`col-12 col-lg-${
            isCustomerDetails ? "12" : "5"
          } px-5 mt-4 mb-5 pb-4`}
        >
          <h1 className="col-12">Login</h1>

          {price?.currency ? (
            <div className="price">
              <p>
                {`Total Amount: ${price?.currency} ${
                  price?.amount ? `${parseFloat(price?.amount).toFixed(2)}` : ""
                }`}
              </p>
            </div>
          ) : null}

          <TextInput
            label="Username:"
            placeholder="e.g. john@gmail.com"
            id="email"
            value={data?.email}
            onChange={handleChange}
            error={error?.email}
            type="email"
            onEnterPress={submit}
          />
          <TextInput
            label="Password:"
            placeholder="*********"
            id="password"
            type="password"
            value={data?.password}
            onChange={handleChange}
            error={error?.password}
            onEnterPress={submit}
          />
          {apiError ? <div className="error-feedback">{apiError}</div> : null}
          <div className="col-12 mt-4 p-0 text-center gap-4">
            <Button
              title="Login"
              type="dark"
              isLoading={isLoading}
              onClick={submit}
            />
          </div>
          <div className="col-12 mt-3 text-center d-flex flex-column">
            {/* <Link to="/pricepacks">Don't have account? Signup Now!</Link> */}
            <div className="link" onClick={handleSignup}>
              Don't have account? Signup Now!
            </div>
            OR
            <div className="link" onClick={handleForgot}>
              Forgot Password!
            </div>
          </div>
        </div>
        {!isCustomerDetails ? <Features /> : null}
      </div>
    </>
  );

  function handleSignup() {
    if (isCustomerDetails) {
      setAuthSection("signup");
    } else {
      history.push("/signup");
    }
  }

  function handleForgot() {
    if (isCustomerDetails) {
      setAuthSection("forgot");
    } else {
      history.push("/forgot");
    }
  }

  function validate() {
    setValidationFired(true);
    let validated = true;
    const errors = {
      ...initialErrors,
    };
    Object.keys(data)?.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${startCase(field)} is required!`;
        validated = false;
      }
    });
    setError(errors);

    return validated;
  }

  async function submit() {
    const validated = validate();
    if (validated) {
      try {
        setIsLoading(true);
        setApiError("");
        const body = {
          user_name: data.email,
          password: data.password,
        };
        const route = API_ROUTES?.login?.route || "";
        const method = API_ROUTES?.login?.method || "";

        const response = await Helper(route, method, body, false, false);
        if (response?.error_code) {
          if (response?.message === "Email is not confirmed Yet.") {
            history.push("/signup", {
              confirmation: true,
              email: data.email,
              password: data?.password,
            });
          }
          setApiError(response?.message);
        } else if (response?.access_token) {
          localStorage.setItem(storageToken, response?.access_token);
          localStorage.setItem(refreshToken, response?.refresh_token || "");
          // toast here you are logged in
          addToast(messages.loginSuccess, { appearance: "success" });
          history.push("/orders");
          if (onLoginSuccess) {
            console.log("login success", onLoginSuccess);
            onLoginSuccess();
          }
          if (isCustomerDetails) {
            console.log("isCustomerDetails", isCustomerDetails);
            await manageDetailsAfterLogin();
            setActiveStep(4);
          }
          dispatch(handleAuthState("isLoggedIn", true));
        } else {
          // toast here with error
          addToast(messages.loginError, { appearance: "error" });
        }
      } catch (error) {
        console.log(error);
        addToast(messages.loginError, { appearance: "error" });
        // toast here with error
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function manageDetailsAfterLogin() {
    const orderId = await generateOrderId();
    if (orderId) {
      await handleDB(orderId);
    }
  }

  function handleChange(text, type) {
    setData({
      ...data,
      [type]: text,
    });
  }
}
