import Spinner from "../components/Spinner";

export default function Button(props) {
  const {
    title,
    type = "dark",
    onClick,
    disabled,
    isLoading,
    style,
    rightIcon,
    containerClassName,
  } = props;

  return (
    <button
      className={`button-custom btn fw-os-sb button-border fs-10 shadow font-color ${
        type === "dark" ? "button-bg-3 color-wht" : ""
      } ${containerClassName}`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      disabled={disabled || isLoading}
      style={style}
    >
      {isLoading ? (
        <Spinner active />
      ) : (
        <div className="d-flex justify-content-around align-items-center">
          <span>{title || ""}</span>
          {rightIcon ? <i className={`bi bi-${rightIcon}`}></i> : null}
        </div>
      )}
    </button>
  );
}
