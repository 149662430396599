import React from 'react'
import "../../css/bootstrap.css"

import "../../css/responsive.css"
 import "../../css/style.css"
 import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { startCase } from "lodash";
import messages from '../../common/consts/messages';

const LandingPage = ({setShowHeaderFooter}) => {
  const [error, setError] = useState({});
  const [validationFired, setValidationFired] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
   const dispatch = useDispatch()
   const { addToast } = useToasts();

   const [data, setData] = useState({
    email: "",
    phone: "",
    lastName: "",
    firstName:"",
    // country_code: DEFAULT_COUNTRY_CODE,
    message: "",
   
  });


  useEffect(() => {
    if (validationFired) {
      validate();
    }
  }, [validationFired, data]);

  function validate() {
    setValidationFired(true);
    let validated = true;

    let errors = {};
    Object.keys(data)?.forEach((field) => {
      if (data[field] === "") {
        errors[field] = `${startCase(field)} is required!`;
        validated = false;
      }
    });
    console.log(error)
    setError(errors);
    return validated;
  }

    React.useEffect(()=>{
        setShowHeaderFooter(false)
    },[])



    const handleSubmit = async (e) => {
      e.preventDefault()
      const validated = validate(data);
      if(validated){
        setIsLoading(true)
  
        let url = "https://api.moversly.com/api/v1/partner/signup-intent"
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: data.email,
            phone: data.phone,
            name: `${data.lastName} ${data?.firstName}`,
            message: data.message,
            signup_as: "Mover",
          }),
        };
        await fetch(url, requestOptions).then((res) => {
          if(res.status === 200){
            addToast(messages.partnerSuccess, {
              appearance: 'success'
          });
          }
        }).catch((error) => {
          console.log(error)
        });
        setIsLoading(false)
      }
  
    }


    const handleMessageChange = (e) => {
      const { name, value } = e.target;
      setData({
        ...data,
        [name]: value,
      });
    };





  return (
    <div>
    <div className="hero_area">
      <div className="hero_bg_box">
        <img src="images/hero-bg.jpg" alt=""/>
      </div>
      {/* <!-- header section strats --> */}
      <header className="header_section">
        <div className="header_top">
          <div className="container header_top_container">
            <div className="row">
            <div className="col-md-4">
              <div className="full">
                <div className="social_box">
                  <a href="">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i className="fa fa-youtube" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="full">
                <div className="contact_nav">
                  {/* <a href="">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <span>
                      Lorem Ipsum is simply
                    </span>
                  </a> */}
                  <a href="">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <span>
                    info@moversly.com
                    </span>
                  </a>
                  <a href="" className="call-link">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    <span>
                      Call :+61 426 732 468
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="header_bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="full">
                  <nav className="navbar navbar-expand-lg custom_nav-container ">
              <a className="navbar-brand " href="/"><img width="200"
         
              src="./assets/logo/moversly-logo-w.svg"
                alt="#" /></a>
  
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className=""> </span>
              </button>
  
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <a className="nav-link" href="">Home <span className="sr-only">(current)</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="">About</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="">Services</a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link" href="news.html">News</a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link" href="">Contact Us</a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="/login">Login</a>
                  </li>
                  <form className="form-inline justify-content-center">
                    <button className="btn  my-2 my-sm-0 nav_search-btn" type="submit">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </form>
                </ul>
              </div>
            </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- end header section -->
      <!-- slider section --> */}
      <section className="slider_section ">
        <div id="customCarousel1" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          {
            Array(3)?.fill({})?.map((item,idx)=>{
             return (
              <div key={idx} className="carousel-item active">
              <div className="container ">
                <div className="row">
                  <div className="col-md-9 col-lg-6">
                    <div className="detail-box">
                      <h1>
                      Grow Your Moving Business with Moversly
                      </h1>
                      <p>
                      Moversly is a powerful moving company software that manages a moving company's entire operations. Moversly is a SAAS platform and works on any computer, mobile phones, tablet.  
Increase your Profitability with Moversly.
                      </p>
                      <div className="btn-box">
                        <a href="" className="btn1">
                        Get Free Trial
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             ) 


             
            })
          }
          
         
          </div>
          <div className="carousel_btn-box">
            <a className="carousel-control-prev" href="#customCarousel1" role="button" data-slide="prev">
              <i className="fa fa-angle-left" aria-hidden="true"></i>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#customCarousel1" role="button" data-slide="next">
              <i className="fa fa-angle-right" aria-hidden="true"></i>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </section>
      {/* <!-- end slider section --> */}
    </div>
  
    {/* <!-- about section --> */}
  
    <section className="about_section layout_padding">
      <div className="container  ">
        <div className="row">
          <div className="col-md-6 ">
            <div className="img-box">
              <img src="images/about-img.jpg" alt=""/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="detail-box">
              <div className="heading_container">
                <h2>
               
                Pre-Move  <span>Survey</span>
                </h2>
              </div>
              <p>
              Help your customer save crucial time with the virtual/self-survey options.
              </p>
              <a href="">
                Get Started Today
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    {/* <!-- end about section -->
  
    <!-- service section --> */}
  
    <section className="service_section layout_padding-bottom">
      <div className="service_container">
        <div className="container ">
          <div className="heading_container heading_center">
            <h2>
              How it works
               {/* <span>Services</span> */}
            </h2>
            <p>
            Moversly CRM Software helps Moving Companies Build & Grow Their Business
            </p>
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-4 mx-auto ">
              <div className="box ">
                <div className="img-box">
                  <img src="images/s1.png" alt=""/>
                </div>
                <div className="detail-box">
                  <h5>
                  CRM Module
                  </h5>
                  <p>
                  Our CRM tool supports lead and booking management, sales operations, quote management, and more for your moving company.
                  </p>
                  {/* <a href="">
                    Read More
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 mx-auto ">
              <div className="box ">
                <div className="img-box">
                  <img src="images/s2.png" alt=""/>
                </div>
                <div className="detail-box">
                  <h5>
                  Quote Management
                  </h5>
                  <p>
                  Automated quote generation for your moving company, saving you precious time.
                  </p>
                  {/* <a href="">
                    Read More
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mx-auto ">
              <div className="box ">
                <div className="img-box">
                  <img src="images/s3.png" alt=""/>
                </div>
                <div className="detail-box">
                  <h5>
                  Import/Export Module 
                  </h5>
                  <p>
                  Get simple, intuitive, and easy management of all import/export jobs.
                  </p>
                  {/* <a href="">
                    Read More
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 mx-auto ">
              <div className="box ">
                <div className="img-box">
                  <img src="images/s3.png" alt=""/>
                </div>
                <div className="detail-box">
                  <h5>
                  Lead Management
                  </h5>
                  <p>
                  Super-Efficient lead management module with single dashboard showing all lead details.
                  </p>
                  {/* <a href="">
                    Read More
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>


            <div className="col-md-6 col-lg-4 mx-auto ">
              <div className="box ">
                <div className="img-box">
                  <img src="images/s3.png" alt=""/>
                </div>
                <div className="detail-box">
                  <h5>
                  Sales Solution
                  </h5>
                  <p>
                  Increase your productivity with smooth operations resulting in higher revenues.
                  </p>
                  {/* <a href="">
                    Read More
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 mx-auto ">
              <div className="box ">
                <div className="img-box">
                  <img src="images/s3.png" alt=""/>
                </div>
                <div className="detail-box">
                  <h5>
                  Real-Time Tracking 
                  </h5>
                  <p>
                  Customers can track their shipment online and know real-time location.
                  </p>
                  {/* <a href="">
                    Read More
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

          </div>
          {/* <div className="btn-box">
            <a href="">
              View More
            </a>
          </div> */}
        </div>
      </div>
    </section>
  
    {/* <!-- end service section -->
  
  
    <!-- call section --> */}
  
    <section className="call_section ">
      <div className="container  ">
        <div className="row">
          <div className="col-md-6 ">
            <div className="img-box">
              <img src="images/call-img.jpg" alt=""/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="detail-box">
              <h5>
              Get your FREE Trial Today!
              </h5>
              <h3>
              {/* Get your FREE Trial Today! */}
              </h3>
              <p>
              The best Moving software for managing your moving business. Moversly is one-stop platform shop for your moving company's operations
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    {/* <!-- end call section -->
  
    <!-- faq section --> */}
  
    <section className="faq_section layout_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="detail-box">
              <div className="heading_container">
                <h2>
                <span>Why Partner with Moversly?</span>
                </h2>
              </div>
              {/* <p>
                Dicta doloribus. Nulla eaque voluptatibus praesentium et deleniti exercitationem incidunt illo, pariatur suscipit fugit architecto debitis? Laudantium, sunt dolorem eaque consequatur placeat dolor quod iure aliquid praesentium velit voluptatem recusandae.
              </p> */}
              {/* <a href="">
                Read More
              </a> */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="faq_container">
              <div className="accordion" id="faqAccordion">
                <div className="card">
                  <div className="card-header" id="faqItem1Header">
                    <h5 className="mb-0">
                      <button className="  collapsed" type="button" data-toggle="collapse" data-target="#faqItem1" aria-expanded="false" aria-controls="faqItem1">
                      Greater Sales 
                      </button>
                    </h5>
                  </div>
                  <div id="faqItem1" className="collapse" aria-labelledby="faqItem1Header" data-parent="#faqAccordion">
                    <div className="card-body">
                      <p>
                      Our wide network and management systems assures higher sales.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqItem2Header">
                    <h5 className="mb-0">
                      <button className=" " type="button" data-toggle="collapse" data-target="#faqItem2" aria-expanded="false" aria-controls="faqItem2">
                      Quote Management 
                      </button>
                    </h5>
                  </div>
                  <div id="faqItem2" className="collapse " aria-labelledby="faqItem2Header" data-parent="#faqAccordion">
                    <div className="card-body">
                      <p>
  
                      Generate quotes, send quotes online, smooth quote management
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqItem3Header">
                    <h5 className="mb-0">
                      <button className="  collapsed" type="button" data-toggle="collapse" data-target="#faqItem3" aria-expanded="false" aria-controls="faqItem3">
                      Reduced Operation Costs 
                      </button>
                    </h5>
                  </div>
                  <div id="faqItem3" className="collapse" aria-labelledby="faqItem3Header" data-parent="#faqAccordion">
                    <div className="card-body">
                      <p>
  
                      Save costs with increased bookings and benefit from our intelligent logistics
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqItem3Header">
                    <h5 className="mb-0">
                      <button className="  collapsed" type="button" data-toggle="collapse" data-target="#faqItem4" aria-expanded="false" aria-controls="faqItem4">
                      Seamless Operations 
                      </button>
                    </h5>
                  </div>
                  <div id="faqItem4" className="collapse" aria-labelledby="faqItem3Header" data-parent="#faqAccordion">
                    <div className="card-body">
                      <p>
  
                      Manage leads, bookings, quotes, customer service, notifications, etc. seamlessly with Moversly
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
{/*   
    <!-- end faq section -->
  
    <!-- news section --> */}
  
    {/* <section className="news_section ">
      <div className="container">
        <div className="heading_container">
          <h2>
            Latest <span>News</span>
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-6 ">
            <div className="box">
              <div className="img-box">
                <img src="images/b1.jpg" alt=""/>
              </div>
              <div className="detail-box">
                <h5>
                  Velit tempora molestias quae
                </h5>
                <p>
                  Recusandae dolor sit amet consectetu entes adipisicing elit velit error repellat assumenda suscipit illum
                </p>
                <a href="">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="box">
              <div className="img-box">
                <img src="images/b2.jpg" alt=""/>
              </div>
              <div className="detail-box">
                <h5>
                  Repudiandae voluptatum quaerat
                </h5>
                <p>
                  Recusandae dolor sit amet consectetu entes adipisicing elit velit error repellat assumenda suscipit illum
                </p>
                <a href="">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}
  
    {/* <!-- end news section -->
  
    <!-- contact section --> */}
    <section className="contact_section layout_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-11 col-lg-9 mx-auto">
            <div className="map_form_container">
              <div className="form_container">
                <div className="heading_container heading_center">
                  <h2 className="contact_heading">
                    Contact <span>Us</span>
                  </h2>
                </div>
                <form >
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input type="text" className="form-control" placeholder="First Name" name='firstName' onChange={handleMessageChange} />
                    </div>
                    <div className="form-group col-md-6">
                      <input type="text" className="form-control" placeholder="Last Name" name='lastName' onChange={handleMessageChange} />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input type="email" className="form-control" placeholder="Email" name='email' onChange={handleMessageChange} />
                    </div>
                    <div className="form-group col-md-6">
                      <input type="text" className="form-control" placeholder="Phone Number" name='phone' onChange={handleMessageChange} />
                    </div>
                  </div>
                  <div className="form-group ">
                    <input type="text" className="message-box" placeholder="Message" name='message' onChange={handleMessageChange} />
                  </div>
                  <div className="btn-box">
                    <button onClick={handleSubmit} className="submit_btn">
                      {isLoading ? "Sending ....":"Send"}
                    </button>
                    {/* <button id="showMap" className="map_btn">
                      Map
                    </button> */}
                  </div>
                </form>
              </div>
              <div className="map_container">
                <div className="map">
                  <div id="googleMap"></div>
                </div>
                <div className="btn-box">
                  <button id="showForm" className="map_btn">
                    Form
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!-- end contact section -->
  
    <!-- footer section --> */}
    <footer className="footer_section">
      <div className="container">
       <a href="" className="footer-brand">
          <img 
          width="300"
           src="./assets/logo/moversly-logo-w.svg"
          // src="images/logo_main.png" 
          alt="#" />
        </a>
        <div className="col-lg-10 mx-auto px-0">
          <div className="footer_items">
            <div className="row">
              <div className="col-md-4">
                <a href="">
                  <div className="item ">
                    <div className="img-box ">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                    </div>
                    <p>
                    126 Pacific Highway
                      {/* Lorem Ipsum is simply */}
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <a href="">
                  <div className="item ">
                    <div className="img-box ">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                    </div>
                    <p>
                    +61 426 732 468
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-md-4">
                <a href="">
                  <div className="item ">
                    <div className="img-box">
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </div>
                    <p>
                    info@moversly.com
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="social-box">
          <h4>
            Follow Us
          </h4>
          <div className="box">
            <a href="">
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a href="">
              <i className="fa fa-twitter" aria-hidden="true"></i>
            </a>
            <a href="">
              <i className="fa fa-youtube" aria-hidden="true"></i>
            </a>
            <a href="">
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
            <a href="">
              <i className="fa fa-google-plus" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div className="col-lg-10 mx-auto px-0">
          <div className="footer_form">
            <h4>
              NEWSLETTER
            </h4>
            <form action="">
              <input type="text" placeholder="Enter Your Email" />
              <button type="submit">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="footer-info">
        <div className="container">
          <p>
            &copy; <span id="displayYear"></span> All Rights Reserved By Moversly
          
          </p>
        </div>
      </div>
    </footer>

  </div>
  )
}

export default LandingPage