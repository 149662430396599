const Types = {
  RESET_SHIPMENT_REDUCER: "RESET_SHIPMENT_REDUCER",
  HANDLE_SHIPMENT: "HANDLE_SHIPMENT",
  LOAD_PREVIOUS_SHIPMENT: "LOAD_PREVIOUS_SHIPMENT",
  SET_CLIENT_SECRET: "SET_CLIENT_SECRET",

  HANDLE_AUTH: "HANDLE_AUTH",

  HANDLE_USER: "HANDLE_USER",
  HANDLE_USER_MULTI_STATE: "HANDLE_USER_MULTI_STATE",

  SET_CONFIG: "SET_CONFIG",

  GET_PACKER_LEADS: "GET_PACKER_LEADS",
  LOADING: "LOADING",
};

export default Types;
