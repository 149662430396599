import React, { useState, useEffect } from "react";
// import Button from "../../common/components/Button";
import TextInput from "../../common/components/TextInput";
import { useHistory } from "react-router";
import {
  makeStyles,
  Typography,
  Grid,
  Divider,
  Input,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import PhoneInput from "../../common/components/PhoneInput";
import Checkbox from "../../common/components/Checkbox";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Helper, { HelperWithUrl } from "../../common/consts/helper";
import LocationModal from "../home/components/LocationModal";
import { useToasts } from "react-toast-notifications";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import Spinner from "../../common/components/Spinner";
import { API_ROUTES, BASE_URL } from "../../common/consts/config";
import { Close, Fullscreen } from "@mui/icons-material";
import produce from "immer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { handleShipmentState } from "../../redux/actions/shipmentActions";
import QuoteAccepted from "./QuoteAccepted";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 960,
    height: 700,
  },
  header: {
    padding: theme.spacing(2),
    // maxWidth: 320,
    margin: "0 auto",
    alignItems: "center",
    fontSize: "large",
  },
  details: {
    maxWidth: 350,
    marginright: "0 auto",
    marginLeft: 250,

    padding: theme.spacing(0, 2),
    paddingBottom: 15,
    fontsize: 25,
    fontFamily: "'Montserrat', sans-serif",
  },
  divider1: {
    // height: 10,
    backgroundColor: "#4E3883",
    marginTop: 10,
    marginBottom: 20,
    // borderWidth: 2,
  },
  checkbox: {
    paddingTop: -10,
  },
  checkDisplay: {
    width: 350,
    marginBottom: "10px",
  },
  checkDiv: {
    textAlign: "center",
    width: 350,
    margin: "0px auto",
  },
  checkLink: {
    textDecoration: "none",
    marginRight: 10,
  },
}));

// //const BASE_URL = "https://api.moversly.com/api/v1/";
// const BASE_URL = "http://api-uat.moversly.com/api/v1/";

const QuoteResponse = (props) => {
  const { setShowHeaderFooter } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  console.log(fullScreen);
  const [addressType, setAddressType] = useState(0);
  const [locationModal, setLocationModal] = useState(0);
  const { schedule_shipment } = useSelector((state) => state?.shipment);
  // const {to_address} = schedule_shipment
  const [insuranceRequired, setInsuranceRequired] = useState(false);
  const [storage, setStorage] = useState(false);
  const [leadsById, setLeadsById] = useState(0);
  const [agree, setAgree] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiError, setApiError] = useState("");
  const [quote, setQuote] = useState({});
  const [price, setPrice] = useState({});
  const [insuranceAmount, setInsuranceAmount] = useState({
    currency: "SGD",
    amount: "",
  });
  const [priceIdx, setPriceIdx] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [movingTo, setMovingTo] = useState();
  const [modifiedMovingTo, setmodifiedMovingTo] = useState({
    street_lines: "",
    city: "",
    postal_code: "",
    country_code: "",
    coordinates: {
      latitude: "",
      longitude: "",
    },
    country_name: "",
  });
  const [removalDate, setRemovalDate] = useState();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [url, setUrl] = useState();
  const classes = useStyles();
  const history = useHistory();

  const pathname = history?.location?.pathname;
  // const Id = pathname.split("/").join().slice(20, 53).split(",quote,");

  const Id = pathname.split("/").join().split(",");

  // const orderId = Id[0];
  const orderId = Id[4];
  const quoteId = Id[6].replaceAll(",", "");
  const requestId = Id[8];
  const logoLink = Id[12];
  const homePage = Id[16];

  console.log(Id);

  // const orderId = Id[0];
  // const quoteId = Id[1].replaceAll(",", "");

  // const requestId = pathname.split("/").join().slice(42, 200).split(",id,")[1];

  const epochToDateConverter = (epochDate) => {
    const valueConvert = 60000;
    const milliseconds = 1000;
    const zone = new Date().getTimezoneOffset() * -1 * valueConvert;
    const newEpoch = epochDate * milliseconds;
    const dateConvert = new Date(newEpoch + zone);
    return moment.utc(dateConvert).format("YYYY-MM-DD");
  };

  const formatDate = (date) => {
    const isDate = moment(date).format("DD-MM-YYYY");
    console.log("Date format : ", isDate);
    console.log("Just Date :", date);
    if (!date) return "";
    return isDate;
    // const day = String(date.getDate()).padStart(2, "0");
    // const month = String(date.getMonth() + 1).padStart(2, "0");
    // const year = date.getFullYear();
    // return `${day}-${month}-${year}`;
  };

  // const epochToDateConverter2 = (epochDate) => {
  //   const valueConvert = 60000;
  //   const milliseconds = 1000;
  //   const zone = new Date().getTimezoneOffset() * -1 * valueConvert;
  //   const newEpoch = epochDate * milliseconds;
  //   const dateConvert = new Date(newEpoch + zone);
  //   return moment.utc(dateConvert).format("DD-MM-YYYY");
  // };

  const getOrdersById = async () => {
    const response = await HelperWithUrl(
      `${BASE_URL}order/for-quote/${orderId}`
    );
    if (response) {
      setLeadsById(response);
      // console.log(leadsById);
    }
  };

  const handleCheckBox = () => {
    setAgree(!agree);
  };

  async function getQuoteById() {
    try {
      const response = await HelperWithUrl(
        `${BASE_URL}quote/order/${orderId}/quote/${quoteId}`
      );
      if (response?.error_code) {
        setApiError(response?.messsage);
      } else {
        setQuote(response);
      }
    } catch (error) {}
  }

  useEffect(() => {
    !insuranceRequired &&
      setInsuranceAmount({
        currency: "SGD",
        amount: "",
      });
  }, [insuranceRequired]);

  useEffect(() => {
    let modified_movingfrom = {
      street_lines:
        leadsById?.payload?.schedule_shipment?.from_address?.street_lines || "",
      city: leadsById?.payload?.schedule_shipment?.from_address?.city || "",
      postal_code:
        leadsById?.payload?.schedule_shipment?.from_address?.postal_code || "",
      country_code:
        leadsById?.payload?.schedule_shipment?.from_address?.country_code || "",
      coordinates: {
        latitude:
          leadsById?.payload?.schedule_shipment?.from_address?.coordinates
            ?.latitude || "",
        longitude:
          leadsById?.payload?.schedule_shipment?.from_address?.coordinates
            ?.longitude || "",
      },
      country_name:
        leadsById?.payload?.schedule_shipment?.from_address?.country_name || "",
    };

    let modified_movingto = {
      street_lines:
        leadsById?.payload?.schedule_shipment?.to_address?.street_lines || "",
      city: leadsById?.payload?.schedule_shipment?.to_address?.city || "",
      postal_code:
        leadsById?.payload?.schedule_shipment?.to_address?.postal_code || "",
      country_code:
        leadsById?.payload?.schedule_shipment?.to_address?.country_code || "",
      coordinates: {
        latitude:
          leadsById?.payload?.schedule_shipment?.to_address?.coordinates
            ?.latitude || "",
        longitude:
          leadsById?.payload?.schedule_shipment?.to_address?.coordinates
            ?.longitude || "",
      },
      country_name:
        leadsById?.payload?.schedule_shipment?.to_address?.country_name || "",
    };
    const dataToSave = {
      from_address: modified_movingfrom,
      to_address: modified_movingto,
      moving_date: leadsById?.payload?.schedule_shipment?.moving_date,
    };
    dispatch(handleShipmentState("schedule_shipment", dataToSave));
  }, [leadsById]);

  useEffect(() => {
    getOrdersById();
    getQuoteById();
    setShowHeaderFooter(false);
  }, []);
  useEffect(() => {
    if (quote.quote_status === "ACCEPTED") {
      addToast("Quote Has Been Accepted", { appearance: "success" });
      history.push("/quote-accepted", {
        orderId: orderId,
        quoteId: quoteId,
        logo: logoLink,
        homepage: homePage,
      });
    }
    setRemovalDate(quote?.removal_packing_date);
  }, [quote, leadsById]);

  const onDownload = () => {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.download = `${url}`;
    link.href = `${url}`;
    link.click();
  };

  async function Submit(option) {
    if (option === "Accepting") {
      if (
        (insuranceRequired && insuranceAmount?.amount !== "") ||
        (!insuranceRequired && insuranceAmount?.amount === "")
      ) {
        try {
          setIsloading(true);
          let payload = {
            order_id: orderId,
            quote_id: quoteId,
            code: requestId,
            accepted_rate: {
              amount: {
                currency: price?.amount?.currency,
                amount: price?.amount?.amount,
              },
              description: price?.description,
              accepted: true,
            },
            storage_required: storage,
            insurance_info: {
              amount: {
                ...insuranceAmount,
              },
              insurance_required: insuranceRequired,
            },
            quote_status: "ACCEPTED",
            packing_date: removalDate,
            modified_movingto: schedule_shipment?.to_address,
          };
          const { route, method } = API_ROUTES?.quoteAccept;

          const response = await Helper(
            route,
            method,
            payload,
            false,
            false,
            false
          );

          if (response?.error_code) {
            if (response?.error_code === "PRO_ERR_043") {
              setApiError(response?.message);
              setIsloading(false);
              setTimeout(() => {
                addToast(response?.message, { appearance: "warning" });
                history.push("/");
              }, 5000);
            } else {
              setApiError(response?.message);
            }
          } else {
            // addToast("Thanks for Accepting", { appearance: "success" });
            setOpen(true);
            setUrl(response?.url);
            setIsloading(false);
          }
        } catch (error) {}
      } else {
        setApiError("Insurance Amount is Required !! ");
      }
    } else {
      try {
        setIsloading(true);
        let payload = {
          order_id: orderId,
          quote_id: quoteId,
          code: requestId,
          accepted_rate: {
            amount: {
              currency: price?.amount?.currency,
              amount: price?.amount?.amount,
            },
            description: price?.description,
            accepted: true,
          },
          storage_required: storage,
          insurance_info: {
            amount: {
              ...insuranceAmount,
            },
            insurance_required: insuranceRequired,
          },
          quote_status: "REJECTED",
          packing_dat: removalDate,
        };
        const { route, method } = API_ROUTES?.quoteAccept;

        const response = await Helper(
          route,
          method,
          payload,
          false,
          false,
          false
        );
        if (response?.error_code) {
          if (response?.error_code === "PRO_ERR_043") {
            setApiError(response?.message);
            setIsloading(false);
            setTimeout(() => {
              addToast(response?.message, { appearance: "warning" });
              history.push("/");
            }, 5000);
          } else {
            setApiError(response?.message);
          }
        } else {
          // setOpen(true);
          addToast("Quote have been rejected successfully", {
            appearance: "success",
          });

          setIsloading(false);
        }
      } catch (error) {}
    }
  }

  const handleSelect = (item, index) => {
    if (index === priceIdx) {
      setPrice({});
      setPriceIdx(null);
    } else {
      setPrice(item);
      setPriceIdx(index);
    }
  };

  useEffect(() => {
    setApiError("");
  }, [agree, price]);

  const handleInsuranceChange = (e) => {
    const { name, value } = e.target;

    // const values = [...insurance]
    // values[index][event.target.name] = event.target.value;
    setInsuranceAmount({ ...insuranceAmount, [name]: value });
  };

  const handleOpenLocationModal = (e, item) => {
    setLocationModal(1);
    setAddressType(item);
  };
  const handleCloseLocationModal = () => {
    setLocationModal(0);
  };

  const dateToEpoch = (date) => {
    return (date.getTime() / 1000.0).toFixed(0);
  };

  function handleDate(date) {
    let newDate = dateToEpoch(date);
    setRemovalDate(newDate);
    console.log("new value date", date);
  }

  return (
    <>
      <Container
        component="main"
        maxWidth={fullScreen ? "sm" : "lg"}
        sx={{ mb: 4 }}
      >
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography
            component="h1"
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              marginBottom: 15,
            }}
          >
            Quote Acceptance Form
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="CustomerName"
                label="Customer Name"
                fullWidth
                autoComplete="cc-name"
                variant="standard"
                focused
                value={leadsById?.payload?.user_info?.given_name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="cardNumber"
                label="Email"
                fullWidth
                autoComplete="cc-number"
                variant="standard"
                focused
                value={leadsById?.payload?.user_info?.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="phone"
                label="Phone"
                fullWidth
                autoComplete="cc-exp"
                variant="standard"
                focused
                value={leadsById?.payload?.user_info?.phone}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Quote Number"
                focused
                fullWidth
                autoComplete="cc-csc"
                variant="standard"
                id="quoteNumber"
                value={quoteId}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <TextField
                required
                label="Packing Dating"
                focused
                fullWidth
                variant="standard"
                id="packing_date"
                type="date"
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                }}
                onChange={(newValue) =>
                  handleDate(new Date(newValue))
                }
                value={epochToDateConverter(removalDate)}
               
              /> */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  required
                  label="Packing Date"
                  focused
                  fullWidth
                  variant="standard"
                  id="packing_date"
                  type="date"
                  className={classes.input}
                  inputFormat="dd/MM/yyyy"
                  inputStyle={{
                    width: 350,
                  }}
                  inputProps={{
                    min: new Date().toISOString().split("T")[0],
                  }}
                  onChange={(newValue) => handleDate(new Date(newValue))}
                  value={epochToDateConverter(removalDate)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      variant="standard"
                      className={classes.input}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Billing Address"
                focused
                fullWidth
                autoComplete="cc-csc"
                variant="standard"
                id="billing_address"
                value={
                  leadsById?.payload?.schedule_shipment?.from_address
                    ?.street_lines
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Moving From"
                focused
                fullWidth
                autoComplete="cc-csc"
                variant="standard"
                id="moving_from"
                value={
                  leadsById?.payload?.schedule_shipment?.from_address
                    ?.street_lines
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.input}
                //  focused
                //  fullWidth
                //  variant="standard"
                //  id="moving_to"
                //  placeholder=" e.g. 84 Princess street"
                //  value={movingTo}
                //  onClick={() => {
                //       setLocationModalOpen(1);
                //       setAddressType(1);
                //   }}
                required
                label="Moving To"
                focused
                fullWidth
                variant="standard"
                id="moving_to"
                placeholder=" e.g. 84 Princess street"
                onClick={() => {
                  handleOpenLocationModal(1);
                  setAddressType(1);
                }}
                value={schedule_shipment?.to_address?.street_lines}
                inputProps={{
                  disabled: true,
                }}
              />
              {/* <TextField
                  inputProps={{ tabIndex: "6" }}
                  className={classes.input}
                  // disableUnderline
                  placeholder=" e.g. 84 Princess street"
                  value={movingTo}
                  onClick={() => {
                    setLocationModal(1);
                    setAddressType(0);
                }}
                /> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <Checkbox
                className={classes.checkbox}
                placeholder={<span>Insurance Required</span>}
                onChange={() => setInsuranceRequired(!insuranceRequired)}
                checked={insuranceRequired}
              />
              {insuranceRequired && (
                <Box>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    onChange={(e) => handleInsuranceChange(e)}
                    label="Currency"
                    name="currency"
                    value={insuranceAmount?.currency}
                  >
                    <MenuItem value={"USD"}>USD</MenuItem>
                    <MenuItem value={"SGD"}>SGD</MenuItem>
                  </Select>
                  <TextField
                    variant="standard"
                    onChange={(e) => handleInsuranceChange(e)}
                    name="amount"
                    style={{
                      width: 380,
                    }}
                  />
                </Box>
              )}
              <Checkbox
                onChange={() => setStorage(!storage)}
                checked={storage}
                placeholder={<span>Storage Required</span>}
              />
              {/* <FormControlLabel
                control={
                  // <Check color="secondary" name="saveCard" value="yes" />
                }
                label="Remember credit card details for next time"
              /> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Description"
                focused
                fullWidth
                autoComplete="cc-csc"
                variant="standard"
                id="description"
                value={leadsById?.payload?.item_description}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider1}></Divider>
          {quote?.billing_infos?.length > 0 && (
            <Typography
              component="h1"
              variant="h4"
              align="center"
              gutterBottom
              sx={{
                marginBottom: 15,
              }}
            >
              Please Do Select A Price You Agree With
            </Typography>
          )}
          {quote?.billing_infos?.map((bill, index) => (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  variant="standard"
                  label="Description"
                  fullWidth
                  focused
                  name="description"
                  value={bill?.description}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  // fullWidth
                  variant="standard"
                  label="Price"
                  focused
                  name="description"
                  style={{
                    width: fullScreen ? 160 : 460,
                  }}
                  value={`${bill?.amount?.currency} ${bill?.amount?.amount}`}
                />

                <IconButton
                  sx={{
                    color: "green",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => handleSelect(bill, index)}
                >
                  <Checkbox
                    placeholder=""
                    onChange={() => handleSelect(bill, index)}
                    checked={priceIdx === index}
                    value={priceIdx}
                  />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Divider className={classes.divider1}></Divider>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              marginBottom: 15,
            }}
          >
            Corporate Relocation (If applicable)
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                variant="standard"
                label="Company Name"
                fullWidth
                focused
                placeholder="Company Name"
                name="authorze"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                variant="standard"
                label="Authorize Person"
                fullWidth
                focused
                name="authorize"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                variant="standard"
                label="Authorize Person Designation"
                fullWidth
                focused
                placeholder="authorize"
                name="authorize"
              />
            </Grid>
          </Grid>
          <div
            className={classes.checkDiv}
            style={{
              marginTop: 30,
            }}
          >
            <Typography>Date:{moment().format("DD-MM-YYYY")}</Typography>
            <Checkbox
              containerClassName={classes.checkDisplay}
              onChange={handleCheckBox}
              checked={agree}
              // value={agree}
              // checked={true}
              placeholder={
                <span>
                  I agree to
                  <Link className={classes.checkLink} to="/terms-conditions">
                    Terms & Conditions
                  </Link>
                </span>
              }
              id="termsAccepted"
            />
            {apiError && (
              <Typography
                variant="body1"
                style={{
                  color: "red",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {apiError}
              </Typography>
            )}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ marginTop: 1, marginBottom: 2 }}
              alignItems="center"
              justifyContent="center"
            >
              {agree ? (
                <Button
                  onClick={() => {
                    if (Object.keys(price).length < 1) {
                      setApiError("Kindly select Price You are Accepting");
                    } else {
                      Submit("Accepting");
                    }
                  }}
                  sx={{
                    background: "#2d234c",
                    color: "white",
                    width: 400,
                    cursor: "pointer",
                    "&:hover ": {
                      backgroundColor: "#9E15BF",
                    },
                  }}
                >
                  {isLoading ? <Spinner active /> : "Accept"}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    if (!agree) {
                      setApiError("Kindly Accept to the moversly Terms");
                    }
                  }}
                  sx={{
                    background: "#b3cf99",
                    color: "white",
                    width: "50%",
                    cursor: "not-allowed",
                    "&:hover ": {
                      backgroundColor: "lightgray",
                    },
                  }}
                >
                  {isLoading ? (
                    <CircularProgress fontSize="medium" />
                  ) : (
                    "Accept"
                  )}
                </Button>
              )}

              <Button
                sx={{
                  background: "red",
                  color: "white",
                  width: "50%",
                  cursor: "not-allowed",
                  "&:hover ": {
                    backgroundColor: "lightgray",
                  },
                }}
                onClick={() => Submit("Rejecting")}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress fontSize="medium" /> : "Reject"}
              </Button>
            </Stack>
          </div>
        </Paper>
      </Container>

      <LocationModal
        onSubmit={handleCloseLocationModal}
        onClose={handleCloseLocationModal}
        open={locationModal}
        locationModalOpen={locationModal}
        setLocationModalOpen={setLocationModal}
        addressType={addressType}
      />
      <Dialog open={open} maxWidth={false}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            onClick={() =>
              history.push("/quote-accepted", {
                orderId: orderId,
                quoteId: quoteId,
                logo: logoLink,
                homepage: homePage,
              })
            }
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className="mt-1 text-center my-5"
            style={{
              width: 400,
              height: 400,
            }}
          >
            <img
              src="/assets/images/success.gif"
              alt="success message"
              width={180}
              height={180}
            />
            <Typography>Thanks for accepting</Typography>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QuoteResponse;
