import ActionTypes from '../actions/types';

const initialState = {
    isLoggedIn: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.HANDLE_AUTH:
            return {
                ...state,
                [payload.name]: payload.value
            }
        default:
            return state;
    }
}