import { useHistory } from "react-router-dom";
import Button from "../../common/components/Button";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Container,
  Pagination,
  Skeleton,
} from "@mui/material";
import Banner from "../home/components/Banner";
import { BookerCards } from "./components/BookerCards";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBookerLeads } from "../../redux/actions/orderAction";
import BookerView from "./components/BookerView";

export default function Orders(props) {
  const {setShowHeaderFooter} =props
  const [bookerModal, setBookerModal] = useState(false);
  const [itemId, setItemId] = useState("");

  const user = useSelector((state) => state?.user);
  const { matching_orders, isLoading } = useSelector((state) => state?.order);
  const dispatch = useDispatch();


  useEffect(()=>{
    setShowHeaderFooter(true)
  },[])
  useEffect(() => {
    user?.email && dispatch(fetchBookerLeads(user?.email));
  }, [user]);
  const history = useHistory();

  const handleOpenAddLeads = (id) => {
  
    setBookerModal(true);
    setItemId(id);
  };
  const handleCloseBookerLeads = () => {
    // console.log(id)
    setBookerModal(false);
    setItemId("");
  };
  return (
    <>
      {/* <Banner /> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          {/* <ProductListToolbar /> */}
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={3}>
              {isLoading ? (
                <>
                  {Array(6)
                    ?.fill()
                    ?.map((order) => (
                      <Grid item key={order} lg={4} md={6} xs={12}>
                        <Skeleton
                          variant="rectangular"
                          width={400}
                          height={400}
                          sx={{ borderRadius: 3 }}
                        />
                      </Grid>
                    ))}
                </>
              ) : (
                <>
                  {" "}
                  {matching_orders?.map((order) => (
                    <Grid item key={order?.id} lg={4} md={6} xs={12}>
                      <BookerCards
                        leads={order}
                        handleOpenAddLeads={handleOpenAddLeads}
                      />
                    </Grid>
                  ))}
                </>
              )}
             
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 3,
            }}
          >
            {/* <Pagination color="primary" count={3} size="small" /> */}
          </Box>
        </Container>
      </Box>

      <BookerView
        onClose={handleCloseBookerLeads}
        open={bookerModal}
        itemId={itemId}
        // pathname={pathname}
      />
      {/* <div className="d-flex flex-column align-items-center my-5">
                <div className="d-flex justify-content-center fs-large my-2">Orders will be updated soon ...</div>
                <Button title={"Go to Homepage"} style={{ width: "200px" }} onClick={() => history.push("/")} />
            </div> */}
    </>
  );
}
