import { Close } from "@mui/icons-material";
import {
  DialogTitle,
  Dialog,
  IconButton,
  DialogContent,
  Card,
  CardMedia,
  CardActions,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Helper from "../../../common/consts/helper";

const ImageModal = (props) => {
  const {
    onClose,
    open,
    images,
    currentImageIndex,
    selectedRoom,
    setCurrentImageIndex,
  } = props;
  const { item, image_id, item_id } = images;
  const { order_id } = useSelector((state) => state?.shipment);
  const [image_url, setImage_url] = useState("");
  const length = image_id?.length;
  console.log(images, ":: images", order_id, image_id);

  async function getPicture() {
    try {
      const response = await Helper(
        `order/get-image-url/${order_id}/${selectedRoom}/${item_id}/${image_id[currentImageIndex]}`,
        "GET"
      );

      if (response.error_code) {
        //   Toast.show({
        //     text1:
        //       "Error occured while getting image please try again in few seconds",
        //     type: "error",
        //   });
      } else {
        setImage_url(response?.url);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const nextSlide = () => {
    setCurrentImageIndex(
      currentImageIndex === length - 1 ? 0 : currentImageIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentImageIndex(
      currentImageIndex === 0 ? length - 1 : currentImageIndex - 1
    );
  };
  useEffect(() => {
    if (typeof currentImageIndex === "number") {
      getPicture();
    }
  }, [currentImageIndex]);
  return (
    <Dialog
      maxWidth="md"
      onClose={() => {
        onClose();
      }}
      open={open}
      onBackdropClick="false"
    >
      <DialogTitle
        sx={{
          background: "#e4deee",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: 20,
          fontWeight: 500,
          padding: 1,
          margin: 0,
          width: "100%",
        }}
      >
        {item}
        <IconButton>
          <Close onClick={() => onClose()} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          width: 600,
        }}
      >
        <Card>
          <CardMedia
            component="img"
            height="600"
            image={image_url}
            // image={`https://cdn.pixabay.com/photo/2022/04/18/13/27/yoga-7140566_960_720.jpg`}
            alt={item}
          />
          {image_id.length > 1 && (
            <CardActions>
              <Button size="small" onClick={prevSlide}>
                Previous
              </Button>
              <Button size="small" onClick={nextSlide}>
                Next
              </Button>
            </CardActions>
          )}
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default ImageModal;
