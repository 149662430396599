const BASE_URL = "https://api.moversly.com/api/v1/";
//const BASE_URL = "http://api-uat.moversly.com/api/v1/";

const MAP_API_KEY = "AIzaSyCweY1fc0Ea81gRZyfrzSDWvf19PMSofN4";
const STRIPE_TEST_KEY = "pk_test_VwCYecm0gFt6fOjOpBXLvRM100wWrxmsQ0";
const STRIPE_PROD_KEY = "pk_live_A4pqzjSx03dGMpIPIMrGzaIi00myhVoZVz";

const API_ROUTES = {
  login: {
    route: "auth/login",
    method: "POST",
  },
  bookerInfo: {
    route: "auth/booker-info",
    method: "GET",
  },
  newOrder: {
    route: "order/new",
    method: "POST",
  },
  signup: {
    route: "auth/signup/moversly-user",
    method: "POST",
  },
  forgot: {
    route: "auth/forgot-password",
    method: "POST",
  },
  reset: {
    route: "auth/reset-forgotten-password",
    method: "POST",
  },
  orderAppend: {
    route: "order/amend",
    method: "POST",
  },
  orderComplete: {
    route: "order/completed-self-survey",
    method: "POST",
  },
  calculateRates: {
    route: "order/calculate-rates",
    method: "POST",
  },
  pricingEstimate: {
    route: "pricing/estimate",
    method: "POST",
  },
  refreshToken: {
    route: "auth/refresh-token",
    method: "POST",
  },
  places: {
    route: "places/autocomplete",
    method: "POST",
  },
  confirmation: {
    route: "auth/confirm-registration",
    method: "POST",
  },
  resendConfirmation: {
    route: "auth/resend-confirmation-code",
    method: "POST",
  },
  authPayment: {
    route: "order/payment-intent",
    method: "POST",
  },
  quoteAccept: {
    route: "quote/accept-quote",
    method: "POST",
  },
  customerOrder: {
    route: "order/get-customer-orders",
    method: "POST",
  },
  trackOrder: {
    route: "Customer/get-order-tracking",
    method: "GET",
  },
  getDocumentsList: {
    route: "documents/required-documents",
    method: "GET",
  },
};

const COMPONENT_DATA = {
  login: {
    initialErrors: {
      email: "",
      password: "",
    },
  },
  signUp: {
    initialErrors: {
      email: "",
      phone: "",
      password: "",
      address: "",
      given_name: "",
      family_name: "",
      termsAccepted: "",
    },
  },
  confirmation: {
    initialErrors: {
      email: "",
      code: "",
    },
  },
  resend: {
    initialErrors: {
      email: "",
    },
  },
  bookShipment: {
    stepper: [
      {
        number: 1,
        text: "Schedule Shipment",
      },
      {
        number: 2,
        text: "Shipment Details",
      },
      {
        number: 3,
        text: "Customer Details",
      },
      {
        number: 4,
        text: `View ${"\n"}& Pay`,
      },
    ],
    formOne: {
      shipmentMode: [
        { label: "SEA", value: "SEA" },
        { label: "AIR", value: "AIR" },
      ],
      initialErrors: {
        from_address_street_lines: "",
        to_address_street_lines: "",
        moving_date: "",
        shipment_mode: "",
      },
    },
    formTwo: {
      initialErrors: {
        moving_type: "",
        item_description: "",
      },
      moverslyBox: {
        bodyItems: [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "20",
          "30",
          "40",
          "50",
        ],
        bodyTitle: "Choose No. of Boxes",
      },
      premoveSurveyOptions: ["Self Survey", "Virtual Survey", "Company Survey"],
      seaMovingType: [
        {
          title: "Full Household",
          value: "FULL_HOUSEHOLD",
        },
        {
          title: "Partial Household",
          value: "PARTIAL_HOUSEHOLD",
        },
        {
          title: "Few Boxes",
          value: "BOXES",
        },
        // "{
        //     title: "Documents",
        //     value: "DOCUMENTS"
        // }"
      ],
      roadMovingType: [
        {
          title: "Full Household",
          value: "FULL_HOUSEHOLD",
        },
        {
          title: "Partial Household",
          value: "PARTIAL_HOUSEHOLD",
        },
        {
          title: "Boxes",
          value: "BOXES",
        },
      ],
      airMovingType: [
        {
          title: "Luggage",
          value: "LUGGAGE",
        },
        {
          title: "Boxes",
          value: "BOXES",
        },
      ],
    },
  },
  coordinates: {
    latitudeDelta: 0.002,
    longitudeDelta: 0.0001,
  },
};

const PREMOVE_SURVEY_OPTIONS = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const CONTAINER_TYPE_OPTIONS = [
  {
    label: "20Ft",
    value: 0,
  },
  {
    label: "40Ft",
    value: 1,
  },
  {
    label: "40Hc",
    value: 2,
  },
];

const YES_NO_OPTIONS = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const SCREEN_DETAILS = [
  {
    screenNumber: 0,
    screen: "login",
    heading: "Login",
    nextHeading: "New User?",
    nextButton: "Create Account",
    nextScreen: 1,
    lastScreen: 0,
  },
  {
    screenNumber: 1,
    screen: "signup",
    heading: "Signup",
    nextHeading: "Registered User?",
    nextButton: "Login",
    nextScreen: 0,
    lastScreen: 0,
  },
  {
    screenNumber: 2,
    screen: "confirmation",
    heading: "Confirmation Registration",
    nextHeading: "Registered User?",
    nextButton: "Login",
    nextScreen: 0,
    lastScreen: 0,
  },
  {
    screenNumber: 3,
    screen: "resend",
    heading: "Resend Confirmation Code",
    nextHeading: "Registered User?",
    nextButton: "Login",
    nextScreen: 0,
    lastScreen: 0,
  },
];

const MESSAGES = {
  success: {
    paymentSuccessful:
      "Your Payment is successful! Your order has been confirmed and the Transaction ID is #. You can track your order here – $Dashboard$  Thank you for choosing Moversly!",
    paymentVariable: "orderId",
  },
};

const CUSTOMER_STATE = [
  {
    label: "LEAD_CREATED",
    value: "LEAD_CREATED",
  },
  {
    label: "PREMOVE_SURVEY_DONE",
    value: "PREMOVE_SURVEY_DONE",
  },
  {
    label: "QUOTE_SEND",
    value: "QUOTE_SEND",
  },
  {
    label: "QUOTE_ACCEPTED",
    value: "QUOTE_ACCEPTED",
  },
  {
    label: "VESSEL_STARTED",
    value: "VESSEL_STATED",
  },
  {
    label: "VESSEL_STARTED",
    value: "VESSEL_STATED",
  },
  {
    label: "VESSEL_REACHED",
    value: "VESSEL_REACHED",
  },
  {
    label: "CUSTOM_CLEARANCE_STARTED",
    value: "CUSTOM_CLEARANCE_STARTED",
  },
  {
    label: "DELIVERY_PLANNED",
    value: "DELIVERY_PLANNED",
  },
  {
    label: "DELIVERY_COMPLETED",
    value: "DELIVERY_COMPLETED",
  },
];

const SHIPMENT_TYPE = [
  {
    label: "FCL",
    value: "FCL",
  },
  {
    label: "LCL",
    value: "LCL",
  },
  {
    label: "CONSOLE",
    value: "CONSOLE",
  },
];

export {
  BASE_URL,
  MAP_API_KEY,
  API_ROUTES,
  COMPONENT_DATA,
  PREMOVE_SURVEY_OPTIONS,
  CONTAINER_TYPE_OPTIONS,
  YES_NO_OPTIONS,
  SCREEN_DETAILS,
  STRIPE_TEST_KEY,
  STRIPE_PROD_KEY,
  MESSAGES,
  CUSTOMER_STATE,
  SHIPMENT_TYPE,
};
