import { SET_PARTNER_DATA } from "../actions/partnerAction";

const partner_details = [
  {
    email: "",
    phone: "",
    name: "",
    message: "",
    signup_as: "",
  },
];

function partnerReducer(state = partner_details, action) {
  const { type, payload } = action;
  switch (type) {
    case "SET_PARTNER_DATA":
      return [
        ...state,
        {
          email: payload.email,
          phone: payload.phone,
          name: payload.name,
          message: payload.message,
          signup_as: payload.signup_as,
        },
      ];
    case "RESET_PARTNER":
      return [...partner_details];

    default:
      return state;
  }
}

export default partnerReducer;
