import React from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, CardElement } from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
    hidePostalCode: true
};

function CardSection() {
    return (
        <div>
            <div className="d-flex mb-2">
                Card Information
            </div>
            {/* <div className="box p-0 mb-4">
                <CardElement options={CARD_ELEMENT_OPTIONS}/>
            </div> */}
            <div className="box p-0 mb-4">
                <CardNumberElement />
            </div>
            <div className="box p-0 mb-4">
                <CardExpiryElement />
            </div>
            <div className="box p-0 mb-4">
                <CardCvcElement />
            </div>
        </div>
    );
};

export default CardSection;